import React, { useState, useMemo, useCallback } from "react";
import imageExtensions from "image-extensions";
import { Dialog } from "@headlessui/react";
import validator from "validator";
import DefaultButton from "../components/Button";
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
  Range,
} from "slate";

import {
  Slate,
  Editable,
  useSlate,
  useSlateStatic,
  useSelected,
  useFocused,
  withReact,
  ReactEditor,
} from "slate-react";

import { withHistory } from "slate-history";
import { css } from "@emotion/css";
import { ImageCropper } from "./ImageCropper";

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

const ModoyoEditor = ({
  text,
  update,
  published,
  hidePublishButton = false,
}) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(
    () => withLinks(withImages(withHistory(withReact(createEditor())))),
    []
  );

  const initialValue = useMemo(
    () =>
      JSON.parse(text) || [
        {
          type: "paragraph",
          children: [
            {
              text: "",
            },
          ],
        },
      ],
    [text]
  );
  return (
    <Slate
      editor={editor}
      value={initialValue}
      onChange={(value) => {
        const isAstChange = editor.operations.some(
          (op) => "set_selection" !== op.type
        );
        if (isAstChange) {
          update(null, value);
        }
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", marginTop: 30, marginBottom: 8 }}>
          <MarkButton format="bold" icon="fas fa-bold" />
          <MarkButton format="italic" icon="fas fa-italic" />
          <MarkButton format="underline" icon="fas fa-underline" />
          <BlockButton format="headline-three" icon="fas fa-heading" />
          <BlockButton format="headline-four" icon="fas fa-heading" smaller />
          <BlockButton format="bulleted-list" icon="fas fa-list-ul" />
          <BlockButton format="numbered-list" icon="fas fa-list-ol" />
          <BlockButton format="ingress" icon="fas fa-align-center" />
          <LinkButton />
          <InsertImageButton />
        </div>
        {!hidePublishButton ? (
          <div style={{ display: "flex", marginTop: 30, marginBottom: 8 }}>
            <Toggle update={update} published={published} />
          </div>
        ) : null}
      </div>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder="Enter text here..."
        style={{
          width: "100%",
          border: "1px black solid",
          borderRadius: 12,
          textAlign: "left",
        }}
      />
    </Slate>
  );
};

const withLinks = (editor) => {
  const { isInline } = editor;

  editor.isInline = (element) => {
    return element.type === "link" ? true : isInline(element);
  };

  return editor;
};

const withImages = (editor) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split("/");

        if (mime === "image") {
          reader.addEventListener("load", () => {
            const url = reader.result;
            insertImage(editor, url);
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const Element = (props) => {
  const { attributes, children, element } = props;
  const style = { textAlign: element.align };

  switch (element.type) {
    case "headline-three":
      return (
        <h3
          style={{
            ...style,
            marginInline: 10,
            marginTop: 56,
            marginBottom: 0,
            fontWeight: 700,
            fontFamily: "Figtree, sans-serif",
          }}
          {...attributes}
        >
          {children}
        </h3>
      );
    case "headline-four":
      return (
        <h4
          style={{
            ...style,
            marginInline: 10,
            marginTop: 31,
            marginBottom: 0,
            fontWeight: 700,
            fontFamily: "Figtree, sans-serif",
          }}
          {...attributes}
        >
          {children}
        </h4>
      );
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;
    case "link":
      return (
        <a
          {...attributes}
          href={element.url}
          style={{ color: "#000000", textDecoration: "underline" }}
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
      );
    case "image":
      return <Image {...props} />;
    case "ingress":
      return (
        <p {...attributes} style={{ textAlign: "center", marginBottom: 25 }}>
          {children}
        </p>
      );
    default:
      return (
        <p {...attributes} style={{ fontFamily: "Roboto Flex, sans-serif" }}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const Image = ({ attributes, children, element }) => {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const selected = useSelected();
  const focused = useFocused();

  return (
    <div {...attributes}>
      {children}
      <div
        contentEditable={false}
        className={css`
          position: relative;
          display: flex;
          justify-content: center;
          max-width: 100%;
        `}
      >
        <div
          contentEditable={false}
          className={css`
            position: relative;
          `}
        >
          <img
            alt="img"
            src={element.url}
            className={css`
              box-shadow: ${selected && focused ? "0 0 0 3px #B4D5FF" : "none"};
              margin: 0 auto;
              z-index: 100;
              border-radius: 12px;
            `}
          />
          <div
            onClick={() => Transforms.removeNodes(editor, { at: path })}
            className={css`
              display: ${selected && focused ? "inline" : "none"};
              position: absolute;
              top: 10px;
              right: 10px;
              background-color: white;
              border-radius: 8px;
              padding: 5px 10px;
              z-index: 1;
            `}
          >
            <i className="fas fa-trash" />
          </div>
        </div>
      </div>
    </div>
  );
};

const InsertImageButton = () => {
  const editor = useSlate();

  return <ImageCropper editor={editor} />;
};

const isImageUrl = (url) => {
  if (!url) return false;
  if (!validator.isURL(url)) return false;
  const ext = new URL(url).pathname.split(".").pop();
  return imageExtensions.includes(ext);
};

const insertLink = (editor, url) => {
  if (!url) return;

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);

  // Create the link element
  const link = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : [], // If no selection, insert the URL as text
  };

  if (isCollapsed) {
    // If no text is selected, insert the URL as a link
    Transforms.insertNodes(editor, link);
  } else {
    // Wrap the selected text in a link
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};

const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, { match: (n) => n.type === "link" });
  return !!link;
};

const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, { match: (n) => n.type === "link" });
};

const LinkButton = () => {
  const editor = useSlate();
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState("");

  const handleInsertLink = () => {
    if (url) {
      if (isLinkActive(editor)) {
        unwrapLink(editor);
      }
      insertLink(editor, url);
      setUrl("");
      setIsOpen(false);
    }
  };

  return (
    <>
      <Button
        active={isLinkActive(editor)}
        onMouseDown={(event) => {
          // event.preventDefault();
          setIsOpen(true);
        }}
        image="fas fa-link"
      />

      <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="dialog">
        <Dialog.Panel>
          <div style={{ display: "flex", flexDirection: "column", width: 450 }}>
            <Dialog.Title>
              <div style={{ fontFamily: "Figtree, sans-serif" }}>
                Insert URL
              </div>
            </Dialog.Title>
            <input
              type="url"
              style={{
                paddingBlock: "15px",
                paddingLeft: "15px",
                borderRadius: 8,
                marginBottom: 20,
                fontSize: 15,
                fontFamily: "Roboto Flex, sans-serif",
              }}
              placeholder="Enter the URL"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
            <div
              style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
            >
              <DefaultButton onClick={() => setIsOpen(false)} text="Cancel" />
              <DefaultButton onClick={handleInsertLink} text="Insert" />
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();

  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={() => {
        toggleMark(editor, format);
      }}
      image={icon}
    />
  );
};

const BlockButton = ({ format, icon, smaller = false }) => {
  const editor = useSlate();
  const active = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );

  return (
    <Button
      active={active}
      onMouseDown={() => {
        toggleBlock(editor, format);
      }}
      image={icon}
      smaller={smaller}
    />
  );
};

const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties = null;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const insertImage = (editor, url) => {
  const text = { text: "" };
  const image = { type: "image", url, children: [text] };
  const extraline = { type: "paragraph", url, children: [text] };
  Transforms.insertNodes(editor, image);
  Transforms.insertNodes(editor, extraline);
};

const Button = (props) => {
  const { active, onMouseDown, image, smaller = false } = props;

  return (
    <div
      onMouseDown={(event) => {
        event.preventDefault();
        if (onMouseDown) onMouseDown();
      }}
      style={{
        height: 20,
        width: 20,
        background: active ? "black" : "transparent",
        padding: "4px 10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 4,
        border: "1px solid black",
        cursor: "pointer",
        color: active ? "white" : "black",
        marginRight: 8,
      }}
    >
      <div
        className={image}
        style={{
          fontSize: smaller ? 12 : "inherit",
        }}
      />
    </div>
  );
};

const Toggle = (props) => {
  const [published, setPublished] = useState(props.published);
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          fontSize: 12,
          marginRight: 8,
          fontWeight: published ? 700 : 500,
        }}
      >
        {published ? "PUBLISHED" : "unpublished"}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: published ? "flex-end" : "flex-start",
          border: "1px solid black",
          width: 60,
          height: 30,
          borderRadius: 30,
          cursor: "pointer",
        }}
        onClick={() => {
          if (props.update) {
            setPublished((val) => !val);
            props.update(null, null, published ? "off" : "on");
          }
        }}
      >
        <div
          style={{
            border: "1px solid black",
            borderRadius: "50%",
            width: 22,
            height: 22,
            margin: "0px 4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className={published ? "fas fa-check" : "fas fa-unlink"}
            style={{ fontSize: 10 }}
          />
        </div>
      </div>
    </div>
  );
};

export default ModoyoEditor;
