import { Link } from "react-router-dom";
import styled from "styled-components";

export const LogoLink = styled(Link)`
  display: flex;
  text-decoration: none;

  @media (max-width: 900px) {
    align-self: center;
    width: 100%;
    z-index: 120;
    justify-content: center;
    align-items: center;
    height: 88px;
  }
`;

export const LogoImage = styled.img`
  width: 157px;
  height: 24px;
  object-fit: contain;
`;
