import styled, {css} from "styled-components";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-block: 4.5rem;
  padding-inline: 2rem;
  ${(props) =>
          props.$innerWidth &&
          css`
            padding-top: ${(props) => (props.$innerWidth < 900 ? "0" : "350px")};
          `}
  text-align: left;

  ${(props) =>
          props.$sm &&
          css`
            @media (min-width: 640px) {
              max-width: 640px;
            }
          `}

  ${(props) =>
          props.$md &&
          css`
            @media (min-width: 768px) {
              max-width: 768px;
            }
          `}

  ${(props) =>
          props.$lg &&
          css`
            @media (min-width: 1024px) {
              max-width: 1024px;
            }
          `}

  ${(props) =>
          props.$xl &&
          css`
            @media (min-width: 1280px) {
              max-width: 1280px;
            }
          `}

  ${(props) =>
          props["2xl"] &&
          css`
            @media (min-width: 1536px) {
              max-width: 1536px;
            }
          `}
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  z-index: 1;

  @media (max-width: 900px) {
    gap: 4px;
`;

export const ArticleHeading = styled.h1`
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  font-family: Figtree, sans-serif;
  color: #250759;

  @media (max-width: 900px) {
    font-size: 32px !important;
    line-height: 40px;
  }
`;

export const RoleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
`;

export const RoleText = styled.div`
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  font-family: "Roboto Mono", sans-serif;
  color: #250759;

  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 16px;
  }
`;

export const ReadingTimeText = styled.span`
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  font-family: "Roboto Flex", sans-serif;
  color: #171717;
`;

export const FullName = styled.div`
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  font-family: "Roboto Flex", sans-serif;
  color: #171717;

  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 18px;
  }
`;

export const ContentText = styled.div`
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  font-family: "Roboto Flex", sans-serif;
  color: #000000;

  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const OtherArticlesText = styled.div`
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  font-family: "Roboto Flex", sans-serif;
  color: #171717;
`;

export const StyledSvg = styled.svg`
  position: relative;
  width: 100%;
  margin-bottom: 60px;
  z-index: 1;

  @media (max-width: 900px) {
    margin-bottom: 0;
  }
`;
