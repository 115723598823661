import styled from "styled-components";

export const TermsConditionsContainer = styled.div`
  width: 100%;
  background: #f2eded;
  padding-block: 150px;
`;

export const StyledSvg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
