import { useState, useEffect, useRef } from "react";
import * as S from "./style";

export default function AttachButton({ file, handleFileChange }) {
  const [hover, setHover] = useState(false);
  const [hasFile, setHasFile] = useState(false);

  const fileRef = useRef(null);

  useEffect(() => {
    setHasFile(file ? true : false);
  }, [file]);

  return (
    <S.AttachButtonContainer>
      <S.Label
        htmlFor="file-upload"
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        onClick={(e) => {
          if (hasFile) {
            e.preventDefault();
            handleFileChange(null);
            fileRef.current.value = "";
          }
        }}
      >
        {hasFile ? (
          hover ? (
            <i className="far fa-trash-alt" />
          ) : (
            <i className="fas fa-check" />
          )
        ) : (
          "ATTACH CV (.PDF)"
        )}
      </S.Label>
      <input
        ref={fileRef}
        id="file-upload"
        type="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </S.AttachButtonContainer>
  );
}