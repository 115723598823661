import { useRef } from "react";
import * as S from "./style";
import * as GS from "../../../styles/global-style";
import { Popover } from "@headlessui/react";
import linkedinBlack from "../../../imgs/social-icons/Icon_LinkedIn_black.png";
import linkedinBlackOver from "../../../imgs/social-icons/Icon_LinkedIn_black_Glitch.png";
import SocialMediaIcons from "./SocialMediaIcons";

const timeoutDuration = 120;

export default function PopoverComponent({ blogDetails }) {
  const triggerRef = useRef();
  const timeOutRef = useRef();

  const handleEnter = (isOpen) => {
    if (window.innerWidth > 900) {
      clearTimeout(timeOutRef.current);
      !isOpen && triggerRef.current?.click();
    }
  };

  const handleLeave = (isOpen) => {
    if (window.innerWidth > 900) {
      timeOutRef.current = setTimeout(() => {
        isOpen && triggerRef.current?.click();
      }, timeoutDuration);
    }
  };
  return (
    <S.PopoverContainer>
      {({ open }) => (
        <div
          onMouseEnter={() => handleEnter(open)}
          onMouseLeave={() => handleLeave(open)}
        >
          <Popover.Button as="div" ref={triggerRef}>
            {blogDetails.author.profile_url ? (
              <S.ProfileImage src={blogDetails.author.profile_url} />
            ) : (
              <S.ProfilePlaceholder>
                A{blogDetails.author.full_name.substring(0, 1)}
              </S.ProfilePlaceholder>
            )}
          </Popover.Button>

          <S.PopoverPanel width="30rem">
            <S.PopoverContent>
              <GS.FlexCenter>
                {blogDetails.author.profile_url ? (
                  <div>
                    <S.ProfileImage src={blogDetails.author.profile_url} />
                  </div>
                ) : (
                  <div>
                    <S.ProfilePlaceholder>
                      {blogDetails.author.full_name.substring(0, 1)}
                    </S.ProfilePlaceholder>
                  </div>
                )}
                <S.NameContainer>
                  <S.FullName className="popover">
                    {blogDetails.author.full_name}
                  </S.FullName>
                  <S.Role className="popover">
                    <span>{blogDetails.author.job_title}</span>
                    <SocialMediaIcons
                      src={linkedinBlack}
                      over={linkedinBlackOver}
                      size={24}
                      url={blogDetails.author.linkedin_url}
                    />
                  </S.Role>
                </S.NameContainer>
                {blogDetails && (
                  <GS.Published>
                    {/*Posted on {dateFormat(blogDetails.created_at)} ·*/}
                  </GS.Published>
                )}
              </GS.FlexCenter>
            </S.PopoverContent>
          </S.PopoverPanel>
        </div>
      )}
    </S.PopoverContainer>
  );
}
