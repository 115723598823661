import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-inline: 2rem;

  @media (max-width: 900px) {
    padding-top: 50px;
  }

  ${(props) =>
    props.$innerWidth &&
    css`
      padding-top: ${(props) => (props.$innerWidth < 900 ? "0" : "350px")};
    `}
  text-align : left;

  ${(props) =>
    props.$sm &&
    css`
      @media (min-width: 640px) {
        max-width: 640px;
      }
    `}

  ${(props) =>
    props.$md &&
    css`
      @media (min-width: 768px) {
        max-width: 768px;
      }
    `}

  ${(props) =>
    props.$lg &&
    css`
      @media (min-width: 1024px) {
        max-width: 1024px;
      }
    `}

  ${(props) =>
    props.$xl &&
    css`
      @media (min-width: 1280px) {
        max-width: 1280px;
      }
    `}

  ${(props) =>
    props["2xl"] &&
    css`
      @media (min-width: 1536px) {
        max-width: 1536px;
      }
    `}
`;

export const DetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 416px;
  gap: 32px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const ContentSectionContainer = styled.div`
  background-color: #f2eded;
  padding-bottom: 66px;
`;

export const ContentSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 56px;
`;

export const RoleTitle = styled.h1`
  text-align: left;
  font-size: 48px;
  line-height: 60px;
  font-weight: 700;
  color: #250759;
  font-family: "Figtree", sans-serif;
`;

export const AboutModoyoParagraph = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 32px;
`;

export const SubTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-block: 36px;
`;

export const SubTitle = styled.h2`
  font-family: "Figtree", sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  color: ${(props) => props.$color || "#e545cb"};
  margin: 0;
`;

export const Description = styled.p`
  font-family: "Roboto Flex", sans-serif;
  color: ${(props) => props.$color || "#1c122e"};
  font-size: 18px;
  line-height: 31.5px;
  font-weight: 500;
  margin: 0;
  padding: 0;
`;

export const StyledSvg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  //   width: 100%;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const ContentSectionInnerContainer = styled.div`
  display: flex;
  height: 1000px;
  gap: 32px;
`;

export const BlockParagraph = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const VacanciesSectionContainer = styled.div`
  background: linear-gradient(180deg, #340b7c 0%, #1f1433 100%);
`;


export const BenefitsContainer = styled.div`
  width: 356px;
  height: 1100px;
  background: linear-gradient(180deg, #5B28B5 0%, #250759 100%);
  box-shadow: 8px 8px 0px 0px #00000026;
  padding: 32px;
  border-radius: 30px;
  margin-bottom: 0;
  
  @media (max-width: 900px) {
    width: auto;
    height: auto;
    padding: 24px;
  }
`
