import ContentSection from "../../components/BlogArticle/ContentSection";
import withTracker from "../../hoc/withTracker";
import * as S from "./style";
import * as GS from "../../styles/global-style";

function BlogArticle() {
  return (
    <GS.HeaderFooterSpace $disabledHeaderSpace>
      <S.BlogArticleContainer>
        <S.StyledSvg
          width="709"
          height="1296"
          viewBox="0 0 709 1296"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M454.156 -280.841C368.355 -369.859 260.455 -439.414 133.378 -488.55C22.2268 -531.304 -90.2248 -553 -200.401 -553C-213.726 -553 -227.376 -553 -240.701 -552.043C-364.203 -546.3 -481.854 -517.903 -590.731 -467.173C-698.632 -417.08 -796.133 -348.482 -880.309 -262.655C-963.835 -177.146 -1028.84 -76.9613 -1073.69 35.6672C-1117.56 146.062 -1139.99 261.243 -1139.99 378.339C-1140.64 495.753 -1118.86 611.891 -1075.64 723.243C-1032.09 834.595 -966.76 935.738 -882.259 1023.8C-797.108 1112.5 -690.181 1182.05 -563.755 1230.55C-452.604 1273.3 -341.128 1295 -232.251 1295C-217.626 1295 -203.001 1294.68 -188.376 1293.72C-64.8741 1286.7 52.7776 1257.67 161.979 1207.58C271.18 1157.8 368.681 1088.89 451.557 1003.06C534.108 917.551 598.784 817.047 643.634 704.419C685.885 598.171 707.66 484.586 707.985 366.533C708.635 249.119 687.509 132.662 645.259 20.9904C602.683 -90.6809 538.333 -192.142 453.507 -280.841H454.156ZM126.878 714.31C-28.4735 863.311 -228.351 952.01 -408.078 952.01C-528.329 952.01 -630.705 913.403 -703.831 840.019C-794.832 748.449 -830.258 613.805 -802.958 460.975C-775.658 306.869 -688.231 153.082 -557.579 27.3716C-402.553 -121.63 -202.676 -210.329 -22.9486 -210.329C97.6278 -210.329 199.679 -171.403 272.805 -98.0193C461.632 91.5028 395.982 455.87 126.553 714.31H126.878Z"
            stroke="#FCFAFF"
            strokeWidth="2"
          />
        </S.StyledSvg>
        <ContentSection />
      </S.BlogArticleContainer>
    </GS.HeaderFooterSpace>
  );
}

export default withTracker(BlogArticle);
