import { useState, useEffect } from "react";
import useTeamResults from "../../hook/use-team-results";
import withTracker from "../../hoc/withTracker";
import * as S from "./style";
import * as GS from "../../styles/global-style";
import HeadSection from "../../components/TeamPage/HeadSection";
import ImagesSection from "../../components/TeamPage/ImagesSection";
import DescriptionSection from "../../components/TeamPage/DescriptionSection";

function TeamPage() {
  const [nav1, setNav1] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slider1, setSlider1] = useState(null);

  useEffect(() => {
    if (slider1) {
      setNav1(slider1);
    }
  }, [slider1]);

  const [team] = useTeamResults();

  return (
    <GS.HeaderFooterSpace $disabledFooterSpace>
      <S.TeamContainer>
        <S.Svg
          width="867"
          height="830"
          viewBox="0 0 867 830"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M612.156-746.841c-85.801-89.018-193.701-158.573-320.778-207.709C180.227-997.304 67.775-1019-42.402-1019c-13.324 0-26.974 0-40.3.96-123.501 5.74-241.152 34.137-350.029 84.867-107.901 50.093-205.402 118.691-289.578 204.518-83.526 85.509-148.527 185.694-193.377 298.322-43.876 110.395-66.3 225.576-66.3 342.672-.65 117.414 21.125 233.552 64.35 344.904 43.551 111.352 108.876 212.495 193.377 300.555 85.151 88.699 192.078 158.255 318.504 206.752C-294.604 807.304-183.128 829-74.251 829c14.625 0 29.25-.319 43.875-1.276 123.502-7.019 241.154-36.054 350.355-86.147 109.201-49.773 206.702-118.69 289.578-204.517 82.551-85.509 147.227-186.013 192.077-298.641 42.251-106.248 64.026-219.833 64.351-337.886.65-117.414-20.476-233.871-62.726-345.543-42.576-111.671-106.926-213.132-191.752-301.831h.649ZM284.878 248.31c-155.351 149.001-355.229 237.7-534.956 237.7-120.251 0-222.627-38.607-295.753-111.991-91.001-91.57-126.427-226.214-99.127-379.044 27.3-154.106 114.727-307.893 245.379-433.603 155.026-149.002 354.903-237.701 534.63-237.701 120.577 0 222.628 38.926 295.754 112.31C619.632-374.497 553.982-10.129 284.553 248.31h.325Z"
            stroke="#5B28B5"
            strokeWidth={2}
          />
        </S.Svg>
        <GS.Container>
          <HeadSection />
        </GS.Container>
        <ImagesSection
          teamMembers={team}
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
          nav1={nav1}
          setSlider1={setSlider1}
        />
        <DescriptionSection
          teamMembers={team}
          slider1={slider1}
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
        />
      </S.TeamContainer>
    </GS.HeaderFooterSpace>
  );
}

export default withTracker(TeamPage);
