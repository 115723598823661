import * as S from "./style";

export default function HeadSection() {
  return (
    <S.HeadContainer>
      <S.HeadTitle>Blog</S.HeadTitle>
      <S.HeadParagraphContainer>
        <S.HeadParagraph>
          Come along on our journey and explore game-building
          <S.HeadParagraph $green> insights, lessons, </S.HeadParagraph>and the fun
          <S.HeadParagraph $green> life </S.HeadParagraph> we have here at Modoyo!
        </S.HeadParagraph>
      </S.HeadParagraphContainer>
    </S.HeadContainer>
  );
}
