import styled from "styled-components";

export const BlogContainer = styled.div`
  padding-top: 250px;
  padding-bottom: 114px;
  background: linear-gradient(180deg, #340b7c 0%, #1f1433 100%);
  overflow: hidden;

  @media (max-width: 900px) {
    padding-top: 140px;
    padding-bottom: 360px;
  }
`;

export const BlogWrapper = styled.div`
  max-width: 80rem;
  margin-inline: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledSvg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
