import * as S from "./style";
import HeroImage from "../../../imgs/careers/hero.png";

export default function HeadSection() {
  return (
    <>
      <S.HeroImage loading="lazy" src={HeroImage} alt="HeroImage" />
      <S.StyledSvg
        width="1850"
        height="1105"
        viewBox="0 0 1850 1105"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1720.82 -292.231C1656.82 -397.135 1576.28 -486.449 1481.4 -557.901C1386.52 -629.353 1280.82 -681.642 1166.52 -712.821C1090.43 -733.607 1011.79 -744 933.154 -744C894.312 -744 854.515 -741.402 815.036 -736.53C702.33 -722.24 592.49 -685.864 488.699 -629.028C385.227 -572.191 293.216 -497.167 215.214 -405.579C137.211 -314.64 78.3117 -208.762 40.4249 -90.8668C2.53799 27.678 -7.96863 156.291 8.58697 291.724C24.8242 424.56 64.9396 545.378 127.66 650.932C190.699 757.135 270.93 846.774 365.806 916.927C460.046 987.404 566.065 1039.69 680.363 1071.85C757.091 1093.28 836.367 1104 916.28 1104C954.485 1104 993.646 1101.4 1032.49 1096.53C1149.65 1081.59 1261.4 1045.21 1363.92 988.378C1467.39 931.866 1558.76 856.193 1636.13 764.28C1713.5 671.717 1771.76 565.514 1809.33 448.269C1847.21 330.698 1858.04 202.735 1841.48 67.9508C1824.93 -66.1834 1784.49 -187.327 1721.14 -292.555L1720.82 -292.231ZM1564.5 380.714C1508.14 567.788 1305.02 688.931 1047.45 688.931C959.898 688.931 871.389 674.965 783.517 647.359C612.23 593.445 466.095 494.063 371.855 367.398C278.252 241.383 247.37 103.677 284.938 -20.7142C341.609 -207.788 544.415 -328.606 801.982 -328.606C889.218 -328.606 978.045 -314.64 1065.6 -287.034C1418.04 -176.284 1641.86 123.488 1564.5 381.039V380.714Z"
          stroke="#5B28B5"
          strokeWidth="2"
        />
      </S.StyledSvg>
      <S.Container $lg>
        <S.HeadSectionInnerContainer>
          <S.HeadSectionTitle>
            Join our team of game makers
          </S.HeadSectionTitle>
          <S.HeadSectionText>
            We're <S.HeadSectionText $green> growing our team  </S.HeadSectionText>
            of 35 creators! Check out our open positions below to see if any might be the right fit for you
          </S.HeadSectionText>
        </S.HeadSectionInnerContainer>
      </S.Container>
    </>
  );
}
