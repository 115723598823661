import styled from 'styled-components';

export const Input = styled.input`
  border: 1px solid #000000;
  border-radius: 12px;
  background: transparent;
  font-size: 18px;
  outline: none;
  padding: 15px 20px;
  color: #000000;
  text-align: left;
  height: auto;
  margin-inline: 0;
  font-family: inherit;
  font-weight: 400;
  margin: 0;
`

export const TextArea = styled.textarea`
  border: 1px solid #000000;
  border-radius: 12px;
  background: transparent;
  font-size: 18px;
  outline: none;
  padding: 15px 20px;
  color: #000000;
  text-align: left;
  height: auto;
  margin-inline: 0;
  font-family: inherit;
  font-weight: 400;
  margin: 0;
`
