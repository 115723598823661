import React, {
  useLayoutEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { Theme } from "../../../utils/Theme";
import { useParams, useNavigate, Link } from "react-router-dom";
import LoadSpinner from "../../../components/LoadSpinner";
import ModoyoEditor from "../../../components/ModoyoEditor";
import { Input } from "../../../components/Admin/User/Account/style";
import * as GS from "../../../styles/global-style";

export default function Edit() {
  const { setColor } = useContext(Theme);
  const navigate = useNavigate();
  const { idx } = useParams();

  const [data, setData] = useState();

  const headerRef = useRef(null);

  const getData = useCallback(() => {
    fetch(`/.netlify/functions/get-presskit?id=${idx}&published=1`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      });
  }, [idx]);

  useLayoutEffect(() => {
    setColor("red");
    getData();
  }, [setColor, idx, navigate, getData]);

  function editPressKit(title, content, published) {
    const _data = {
      id: data.id,
      title: title ? title : null,
      content: content ? mysqlEscape(JSON.stringify(content)) : null,
      published: published === "on" ? 1 : published === "off" ? 0 : -1,
    };

    fetch("/.netlify/functions/edit-presskit", {
      method: "POST",
      body: JSON.stringify(_data),
    }).then(() => {
      if (published) getData();
    });
  }

  function mysqlEscape(stringToEscape) {
    if (stringToEscape === "") {
      return stringToEscape;
    }
    return stringToEscape.replace(/'/g, "'").replace(/"/g, '"');
  }

  return !data ? (
    <LoadSpinner />
  ) : (
    <GS.Container>
      <div style={{ marginBottom: "1rem", color: "black" }}>
        <Link
          to="/narnia/presskit"
          style={{
            color: "black",
            textDecoration: "none",
            alignItems: "center",
            display: "flex",
            marginBottom: "2rem",
            marginTop: "1rem",
          }}
        >
          <i
            className="fas fa-arrow-left"
            style={{ marginRight: ".5rem", fontSize: "18px" }}
          />
          Back
        </Link>
        <GS.Title>Article Details</GS.Title>
      </div>
      <GS.SpaceY number="1.5rem">
        <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <label>Title</label>
          <Input
            ref={headerRef}
            className="input"
            defaultValue={
              data && data.title && data.title !== "null" ? data.title : ""
            }
            placeholder="Title here..."
            onChange={(e) => {
              editPressKit(e.target.value);
            }}
          />
        </div>
        <div
          style={{
            display: "inline-block",
            width: "100%",
            marginBottom: "5rem",
          }}
        >
          {data && (
            <ModoyoEditor
              text={data.newbody}
              update={editPressKit}
              published={data.published}
            />
          )}
        </div>
      </GS.SpaceY>
    </GS.Container>
  );
}
