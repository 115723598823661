import * as S from "./style";
import { useLocation } from "react-router-dom";
import violet from "../../../imgs/blog/mob-violet-outline.svg";
import green from "../../../imgs/blog/mob-green-outline.svg";
import mask from "../../../imgs/blog/mob-mask.svg";
import Link from "../../Link";

export default function MobileBlogItem({ article, content }) {
  const pathname = useLocation()?.pathname;
  let outline = violet;
  if (pathname.match(/^\/(blog)\/\d+$/)) {
    outline = green;
  }

  return (
    <S.CardLink to={"/blog/" + article.id}>
      <S.MobileBlogItemContainer>
        <S.MobileBlogItemHeader>
          <S.MobileBlogItemOutline src={outline} alt="blogItemOutline" />
          <S.MobileBlogItemImageContainer
            $mask={mask}
            src={article.thumb}
            alt="blogItemImage"
          />
          <S.MobileBlogItemTitle>{article.header}</S.MobileBlogItemTitle>
        </S.MobileBlogItemHeader>
        <S.MobileBlogItemDescription>
          <S.MobileBlogItemText>{content}</S.MobileBlogItemText>
          <Link href={"/blog/" + article.id}>READ ARTICLE</Link>
        </S.MobileBlogItemDescription>
      </S.MobileBlogItemContainer>
    </S.CardLink>
  );
}
