import { useState } from "react";
import * as S from "./style";
import { benefitsData } from "./BenefitsData";

export default function BenefitsSection() {
  const [active, setActive] = useState(null);

  const toggleActive = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  return (
    <S.BenefitsContainer>
      <S.Container $lg>
        <S.BenefitsInnerContainer>
          <S.BenefitsTitle>Our Benefits</S.BenefitsTitle>
          <S.BenefitsList type="single" collapsible>
            {benefitsData.map((benefit, index) => (
              <S.BenefitItem
                key={benefit.title}
                value={"item-" + index}
                $active={active === index}
              >
                <S.BenefitsSubtitleContainer
                  onClick={() => toggleActive(index)}
                >
                  <S.BenefitsSubtitle>
                    {benefit.icon}
                    {benefit.title}
                  </S.BenefitsSubtitle>
                  <S.SvgContainer>
                    <S.MinusSvg
                      $active={active === index}
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_837_5171"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="32"
                        height="32"
                      >
                        <path
                          d="M28.5 16C28.5 16.3978 28.342 16.7794 28.0607 17.0607C27.7794 17.342 27.3978 17.5 27 17.5H5C4.60218 17.5 4.22064 17.342 3.93934 17.0607C3.65804 16.7794 3.5 16.3978 3.5 16C3.5 15.6022 3.65804 15.2206 3.93934 14.9393C4.22064 14.658 4.60218 14.5 5 14.5H27C27.3978 14.5 27.7794 14.658 28.0607 14.9393C28.342 15.2206 28.5 15.6022 28.5 16Z"
                          fill="#F5F5F5"
                        />
                      </mask>
                      <g mask="url(#mask0_837_5171)">
                        <path
                          d="M31.9992 0H11.5941C10.86 1.70074 10.4531 3.57593 10.4531 5.54613C10.4531 13.2809 16.7233 19.5511 24.4581 19.5511C27.2346 19.5511 29.8224 18.7432 31.9992 17.3495V0Z"
                          fill="#FCFAFF"
                        />
                      </g>
                      <mask
                        id="mask1_837_5171"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="32"
                        height="32"
                      >
                        <path
                          d="M28.5 16C28.5 16.3978 28.342 16.7794 28.0607 17.0607C27.7794 17.342 27.3978 17.5 27 17.5H5C4.60218 17.5 4.22064 17.342 3.93934 17.0607C3.65804 16.7794 3.5 16.3978 3.5 16C3.5 15.6022 3.65804 15.2206 3.93934 14.9393C4.22064 14.658 4.60218 14.5 5 14.5H27C27.3978 14.5 27.7794 14.658 28.0607 14.9393C28.342 15.2206 28.5 15.6022 28.5 16Z"
                          fill="#F5F5F5"
                        />
                      </mask>
                      <g mask="url(#mask1_837_5171)">
                        <path
                          d="M32 17.3495C29.8232 18.7432 27.2354 19.5511 24.4589 19.5511C16.7241 19.5511 10.4539 13.2809 10.4539 5.54613C10.4539 3.57593 10.8607 1.70074 11.5949 0L0 0V32H32V17.3495Z"
                          fill="#FCFAFF"
                        />
                      </g>
                    </S.MinusSvg>
                    <S.PlusSvg
                      $active={active === index}
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_837_5228"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="32"
                        height="32"
                      >
                        <path
                          d="M28.5 16C28.5 16.3978 28.342 16.7794 28.0607 17.0607C27.7794 17.342 27.3978 17.5 27 17.5H17.5V27C17.5 27.3978 17.342 27.7794 17.0607 28.0607C16.7794 28.342 16.3978 28.5 16 28.5C15.6022 28.5 15.2206 28.342 14.9393 28.0607C14.658 27.7794 14.5 27.3978 14.5 27V17.5H5C4.60218 17.5 4.22064 17.342 3.93934 17.0607C3.65804 16.7794 3.5 16.3978 3.5 16C3.5 15.6022 3.65804 15.2206 3.93934 14.9393C4.22064 14.658 4.60218 14.5 5 14.5H14.5V5C14.5 4.60218 14.658 4.22064 14.9393 3.93934C15.2206 3.65804 15.6022 3.5 16 3.5C16.3978 3.5 16.7794 3.65804 17.0607 3.93934C17.342 4.22064 17.5 4.60218 17.5 5V14.5H27C27.3978 14.5 27.7794 14.658 28.0607 14.9393C28.342 15.2206 28.5 15.6022 28.5 16Z"
                          fill="#F5F5F5"
                        />
                      </mask>
                      <g mask="url(#mask0_837_5228)">
                        <path
                          d="M31.9992 0H11.5941C10.86 1.70074 10.4531 3.57593 10.4531 5.54613C10.4531 13.2809 16.7233 19.5511 24.4581 19.5511C27.2346 19.5511 29.8224 18.7432 31.9992 17.3495V0Z"
                          fill="#FCFAFF"
                        />
                      </g>
                      <mask
                        id="mask1_837_5228"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="32"
                        height="32"
                      >
                        <path
                          d="M28.5 16C28.5 16.3978 28.342 16.7794 28.0607 17.0607C27.7794 17.342 27.3978 17.5 27 17.5H17.5V27C17.5 27.3978 17.342 27.7794 17.0607 28.0607C16.7794 28.342 16.3978 28.5 16 28.5C15.6022 28.5 15.2206 28.342 14.9393 28.0607C14.658 27.7794 14.5 27.3978 14.5 27V17.5H5C4.60218 17.5 4.22064 17.342 3.93934 17.0607C3.65804 16.7794 3.5 16.3978 3.5 16C3.5 15.6022 3.65804 15.2206 3.93934 14.9393C4.22064 14.658 4.60218 14.5 5 14.5H14.5V5C14.5 4.60218 14.658 4.22064 14.9393 3.93934C15.2206 3.65804 15.6022 3.5 16 3.5C16.3978 3.5 16.7794 3.65804 17.0607 3.93934C17.342 4.22064 17.5 4.60218 17.5 5V14.5H27C27.3978 14.5 27.7794 14.658 28.0607 14.9393C28.342 15.2206 28.5 15.6022 28.5 16Z"
                          fill="#F5F5F5"
                        />
                      </mask>
                      <g mask="url(#mask1_837_5228)">
                        <path
                          d="M32 17.3495C29.8232 18.7432 27.2354 19.5511 24.4589 19.5511C16.7241 19.5511 10.4539 13.2809 10.4539 5.54613C10.4539 3.57593 10.8607 1.70074 11.5949 0L0 0V32H32V17.3495Z"
                          fill="#FCFAFF"
                        />
                      </g>
                    </S.PlusSvg>
                  </S.SvgContainer>
                </S.BenefitsSubtitleContainer>
                <S.BenefitsContentContainer>
                  <S.BenefitsListContainer>
                    {benefit.text.map((line) => (
                      <S.BenefitsListItem key={line}>{line}</S.BenefitsListItem>
                    ))}
                  </S.BenefitsListContainer>
                </S.BenefitsContentContainer>
              </S.BenefitItem>
            ))}
          </S.BenefitsList>
          <S.BenefitsWorkExperience>
            <S.BenefitsTitle>Work Experience</S.BenefitsTitle>
            <S.WorkExperienceText>
              Thanks so much for your interest! While we can’t offer work
              experience right now, it's something we’re always reviewing and
              have had great success with in the past.
            </S.WorkExperienceText>
          </S.BenefitsWorkExperience>
        </S.BenefitsInnerContainer>
      </S.Container>
    </S.BenefitsContainer>
  );
}
