import * as S from "./style";
import NextArrow from "../Arrows/NextArrow";
import * as GS from "../../../styles/global-style";

export default function DescriptionSection({
  slider1,
  currentSlide,
  setCurrentSlide,
  teamMembers,
}) {
  if (!teamMembers) return null;

  const member = teamMembers[currentSlide];
  const totalSlides = teamMembers.length;
  return (
    <GS.FlexJustifyCenter>
      <S.DescriptionContainer key={member.name}>
        <S.Name>{member.full_name}</S.Name>
        <S.Role>{member.job_title}</S.Role>
        <S.TextContainer>
          <S.SliderNav
            onClick={() => {
              slider1?.slickPrev();
              setCurrentSlide((prev) =>
                prev === 0 ? totalSlides - 1 : prev - 1
              );
            }}
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_806_6268"
                // style="mask-type:alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="40"
                height="40"
              >
                <path
                  opacity="0.2"
                  d="M35 20C35 22.9667 34.1203 25.8668 32.472 28.3336C30.8238 30.8003 28.4811 32.7229 25.7403 33.8582C22.9994 34.9935 19.9834 35.2906 17.0737 34.7118C14.1639 34.133 11.4912 32.7044 9.3934 30.6066C7.29562 28.5088 5.86701 25.8361 5.28823 22.9264C4.70945 20.0166 5.0065 17.0006 6.14181 14.2597C7.27713 11.5189 9.19972 9.17618 11.6665 7.52796C14.1332 5.87973 17.0333 5 20 5C23.9783 5 27.7936 6.58035 30.6066 9.3934C33.4197 12.2064 35 16.0218 35 20Z"
                  fill="#FCFAFF"
                />
                <path
                  d="M20 3.75C16.7861 3.75 13.6443 4.70305 10.972 6.48862C8.29969 8.27419 6.21689 10.8121 4.98696 13.7814C3.75704 16.7507 3.43524 20.018 4.06225 23.1702C4.68926 26.3224 6.23692 29.2179 8.50952 31.4905C10.7821 33.7631 13.6776 35.3107 16.8298 35.9378C19.982 36.5648 23.2493 36.243 26.2186 35.013C29.1879 33.7831 31.7258 31.7003 33.5114 29.028C35.297 26.3557 36.25 23.2139 36.25 20C36.2455 15.6916 34.5319 11.561 31.4855 8.51454C28.439 5.46806 24.3084 3.75455 20 3.75ZM20 33.75C17.2805 33.75 14.6221 32.9436 12.3609 31.4327C10.0997 29.9218 8.33737 27.7744 7.29666 25.2619C6.25596 22.7494 5.98366 19.9847 6.51421 17.3175C7.04476 14.6503 8.35432 12.2003 10.2773 10.2773C12.2003 8.35431 14.6503 7.04475 17.3175 6.5142C19.9848 5.98366 22.7494 6.25595 25.2619 7.29666C27.7744 8.33736 29.9218 10.0997 31.4327 12.3609C32.9436 14.6221 33.75 17.2805 33.75 20C33.7459 23.6455 32.2959 27.1404 29.7182 29.7182C27.1404 32.2959 23.6455 33.7459 20 33.75ZM27.5 20C27.5 20.3315 27.3683 20.6495 27.1339 20.8839C26.8995 21.1183 26.5815 21.25 26.25 21.25H16.7672L19.6344 24.1156C19.7505 24.2318 19.8426 24.3696 19.9055 24.5214C19.9684 24.6731 20.0007 24.8358 20.0007 25C20.0007 25.1642 19.9684 25.3269 19.9055 25.4786C19.8426 25.6304 19.7505 25.7682 19.6344 25.8844C19.5182 26.0005 19.3804 26.0926 19.2286 26.1555C19.0769 26.2183 18.9143 26.2507 18.75 26.2507C18.5858 26.2507 18.4231 26.2183 18.2714 26.1555C18.1196 26.0926 17.9818 26.0005 17.8656 25.8844L12.8656 20.8844C12.7494 20.7683 12.6572 20.6304 12.5943 20.4787C12.5314 20.3269 12.499 20.1643 12.499 20C12.499 19.8357 12.5314 19.6731 12.5943 19.5213C12.6572 19.3696 12.7494 19.2317 12.8656 19.1156L17.8656 14.1156C18.1002 13.8811 18.4183 13.7493 18.75 13.7493C19.0817 13.7493 19.3998 13.8811 19.6344 14.1156C19.8689 14.3502 20.0007 14.6683 20.0007 15C20.0007 15.3317 19.8689 15.6498 19.6344 15.8844L16.7672 18.75H26.25C26.5815 18.75 26.8995 18.8817 27.1339 19.1161C27.3683 19.3505 27.5 19.6685 27.5 20Z"
                  fill="#FCFAFF"
                />
              </mask>
              <g mask="url(#mask0_806_6268)">
                <path
                  d="M40 0H14.4937C13.5759 2.12593 13.0674 4.46991 13.0674 6.93266C13.0674 16.6011 20.9052 24.4389 30.5736 24.4389C34.0442 24.4389 37.2789 23.4289 40 21.6869V0Z"
                  fill="#FCFAFF"
                />
              </g>
              <mask
                id="mask1_806_6268"
                // style="mask-type:alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="40"
                height="40"
              >
                <path
                  opacity="0.2"
                  d="M35 20C35 22.9667 34.1203 25.8668 32.472 28.3336C30.8238 30.8003 28.4811 32.7229 25.7403 33.8582C22.9994 34.9935 19.9834 35.2906 17.0737 34.7118C14.1639 34.133 11.4912 32.7044 9.3934 30.6066C7.29562 28.5088 5.86701 25.8361 5.28823 22.9264C4.70945 20.0166 5.0065 17.0006 6.14181 14.2597C7.27713 11.5189 9.19972 9.17618 11.6665 7.52796C14.1332 5.87973 17.0333 5 20 5C23.9783 5 27.7936 6.58035 30.6066 9.3934C33.4197 12.2064 35 16.0218 35 20Z"
                  fill="#FCFAFF"
                />
                <path
                  d="M20 3.75C16.7861 3.75 13.6443 4.70305 10.972 6.48862C8.29969 8.27419 6.21689 10.8121 4.98696 13.7814C3.75704 16.7507 3.43524 20.018 4.06225 23.1702C4.68926 26.3224 6.23692 29.2179 8.50952 31.4905C10.7821 33.7631 13.6776 35.3107 16.8298 35.9378C19.982 36.5648 23.2493 36.243 26.2186 35.013C29.1879 33.7831 31.7258 31.7003 33.5114 29.028C35.297 26.3557 36.25 23.2139 36.25 20C36.2455 15.6916 34.5319 11.561 31.4855 8.51454C28.439 5.46806 24.3084 3.75455 20 3.75ZM20 33.75C17.2805 33.75 14.6221 32.9436 12.3609 31.4327C10.0997 29.9218 8.33737 27.7744 7.29666 25.2619C6.25596 22.7494 5.98366 19.9847 6.51421 17.3175C7.04476 14.6503 8.35432 12.2003 10.2773 10.2773C12.2003 8.35431 14.6503 7.04475 17.3175 6.5142C19.9848 5.98366 22.7494 6.25595 25.2619 7.29666C27.7744 8.33736 29.9218 10.0997 31.4327 12.3609C32.9436 14.6221 33.75 17.2805 33.75 20C33.7459 23.6455 32.2959 27.1404 29.7182 29.7182C27.1404 32.2959 23.6455 33.7459 20 33.75ZM27.5 20C27.5 20.3315 27.3683 20.6495 27.1339 20.8839C26.8995 21.1183 26.5815 21.25 26.25 21.25H16.7672L19.6344 24.1156C19.7505 24.2318 19.8426 24.3696 19.9055 24.5214C19.9684 24.6731 20.0007 24.8358 20.0007 25C20.0007 25.1642 19.9684 25.3269 19.9055 25.4786C19.8426 25.6304 19.7505 25.7682 19.6344 25.8844C19.5182 26.0005 19.3804 26.0926 19.2286 26.1555C19.0769 26.2183 18.9143 26.2507 18.75 26.2507C18.5858 26.2507 18.4231 26.2183 18.2714 26.1555C18.1196 26.0926 17.9818 26.0005 17.8656 25.8844L12.8656 20.8844C12.7494 20.7683 12.6572 20.6304 12.5943 20.4787C12.5314 20.3269 12.499 20.1643 12.499 20C12.499 19.8357 12.5314 19.6731 12.5943 19.5213C12.6572 19.3696 12.7494 19.2317 12.8656 19.1156L17.8656 14.1156C18.1002 13.8811 18.4183 13.7493 18.75 13.7493C19.0817 13.7493 19.3998 13.8811 19.6344 14.1156C19.8689 14.3502 20.0007 14.6683 20.0007 15C20.0007 15.3317 19.8689 15.6498 19.6344 15.8844L16.7672 18.75H26.25C26.5815 18.75 26.8995 18.8817 27.1339 19.1161C27.3683 19.3505 27.5 19.6685 27.5 20Z"
                  fill="#FCFAFF"
                />
              </mask>
              <g mask="url(#mask1_806_6268)">
                <path
                  d="M40 21.6869C37.279 23.4289 34.0442 24.4389 30.5736 24.4389C20.9051 24.4389 13.0673 16.6011 13.0673 6.93267C13.0673 4.46991 13.5759 2.12592 14.4937 0L0 0V40H40V21.6869Z"
                  fill="#FCFAFF"
                />
              </g>
            </svg>
          </S.SliderNav>
          <S.Text>{member.bio}</S.Text>
          <S.SliderNav
            onClick={() => {
              slider1?.slickNext();
              setCurrentSlide((prev) =>
                prev === totalSlides - 1 ? 0 : prev + 1
              );
            }}
          >
            <NextArrow />
          </S.SliderNav>
        </S.TextContainer>
      </S.DescriptionContainer>
    </GS.FlexJustifyCenter>
  );
}
