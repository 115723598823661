import styled from "styled-components";

export const XIconContainer = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background-color: #e545cb;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  right: 15px;
`;

export const XIcon = styled.svg`
  width: 32px;
  height: 32px;
`;
