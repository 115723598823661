import * as S from "./style";
import TeamPlayBg from "../../../imgs/heartelectric/TeamPlayBg.png";
import MapVideo from "../../../imgs/heartelectric/Map-video.webm";
import CTA from "../../CTA";

function TeamPlaySection() {
  return (
    <>
      <S.TeamPlayContainer $image={TeamPlayBg}>
        <S.TeamPlayHead>TEAM PLAY AT THE CORE</S.TeamPlayHead>
        <S.TeamPlayTitle>OUTWIT OVERCOME</S.TeamPlayTitle>
        <S.TeamPlayParagraphContainer>
          <S.TeamPlayParagraph>
            Heart Electric emphasises team play like no other FPS. With 12 other
            players as opponents, you will need to use communication, abilities,
            perks and innovative movement to make sure your team stays in the
            game and emerges as the final victor.
          </S.TeamPlayParagraph>
          <S.TeamPlayParagraph>
            Planning your moves, striking at the right time and attempting to
            make another team work in your favor is all part of the tactical
            dynamic of Heart Electric. The gameplay uniquely twists tactics,
            team play and movement, creating a uniquely competitive FPS
            experience.
          </S.TeamPlayParagraph>
        </S.TeamPlayParagraphContainer>
        <S.ButtonContainer>
          <CTA
            blue
            callback={() =>
              window.open("https://bit.ly/HEWebsiteSteam", "_blank")
            }
          >
            GET PLAYTEST ACCESS
          </CTA>
        </S.ButtonContainer>
      </S.TeamPlayContainer>
      <S.MobileTeamPlayVideo
        loading="lazy"
        src={MapVideo}
        alt="MapVideo"
        autoPlay
        playsInline
        muted
        loop
      />
    </>
  );
}

export default TeamPlaySection;
