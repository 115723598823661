import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-block: 2rem;
  padding-inline: 2rem;

  @media (max-width: 900px) {
    padding-top: 50px;
    padding-bottom: 100px;
  }

  ${(props) =>
    props.$innerWidth &&
    css`
      padding-top: ${(props) => (props.$innerWidth < 900 ? "0" : "350px")};
    `}
  text-align : left;

  ${(props) =>
    props.$sm &&
    css`
      @media (min-width: 640px) {
        max-width: 640px;
      }
    `}

  ${(props) =>
    props.$md &&
    css`
      @media (min-width: 768px) {
        max-width: 768px;
      }
    `}

  ${(props) =>
    props.$lg &&
    css`
      @media (min-width: 1024px) {
        max-width: 1024px;
      }
    `}

  ${(props) =>
    props.$xl &&
    css`
      @media (min-width: 1280px) {
        max-width: 1280px;
      }
    `}

  ${(props) =>
    props["2xl"] &&
    css`
      @media (min-width: 1536px) {
        max-width: 1536px;
      }
    `}
`;

export const SocialsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 476px;
  background-color: #0b5f72;

  @media (max-width: 900px) {
    height: auto;
  }
`;

export const SocialsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 230px;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 40px;
    width: 100%;
    padding-bottom: 40px;
  }
`;

export const HeartElectricLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 18px;
  width: 300px;

  @media (max-width: 900px) {
    width: 100%;
    text-align: center;
    align-items: center;
  }
`;

export const HeartElectricLogo = styled.img`
  width: 232px;
  height: 96px;
`;

export const HeartElectricSlogan = styled.p`
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #f5f5f5;
  margin: 0;

  @media (max-width: 900px) {
    font-size: 16px !important;
    line-height: 24px;
  }
`;

export const SocialsInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media (max-width: 900px) {
    gap: 16px;
  }
`;

export const SocialIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @media (max-width: 900px) {
    gap: 13px;
`;

export const SocialIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  border-radius: 8px;
  border: 2px solid #ef5294;
  margin: 0;
  padding: 0;

  @media (max-width: 900px) {
    width: 60px;
    height: 60px;
  }
`;

export const SocialRowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const SocialRow = styled.a`
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  font-family: "Sofia Sans Extra Condensed", sans-serif;
  border: 2px solid #ef5294;
  border-radius: 8px;
  color: #f5f5f5;
  width: 100%;
  height: 48px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;
