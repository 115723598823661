import React, {
    useLayoutEffect,
    useState,
    useContext,
    useRef,
    useCallback,
} from "react";
import {Theme} from "../../../utils/Theme";
import {useParams, useNavigate, Link} from "react-router-dom";
import LoadSpinner from "../../../components/LoadSpinner";
import ModoyoEditor from "../../../components/ModoyoEditor";
import {Container, SpaceY, Title} from "../../../styles/global-style";
import {Input} from "../../../components/Admin/User/Account/style";

export default function Edit() {
    const {setColor} = useContext(Theme);
    const navigate = useNavigate();
    const {idx} = useParams();

    const [data, setData] = useState();

    const headerRef = useRef(null);

    const getData = useCallback(() => {
        fetch(`/.netlify/functions/getgamedetails`, {
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data);
            });
    }, []);

    useLayoutEffect(() => {
        setColor("red");
        getData();
    }, [setColor, idx, navigate, getData]);

    function fetchRequestData(_data) {
        return fetch("/.netlify/functions/editgamedetails", {
            method: "POST",
            body: JSON.stringify(_data),
        }).then(() => {
            getData();
        });
    }

    function editHeader(header) {
        fetchRequestData({
            header: header ? header : null,
        })
    }

    function editFirstParagraph(header,content) {
        fetchRequestData({
            content1: content ? mysqlEscape(JSON.stringify(content)) : null,
        })
    }

    function editSecondParagraph(header, content) {
        fetchRequestData({
            content2: content ? mysqlEscape(JSON.stringify(content)) : null,
        })
    }

    function mysqlEscape(stringToEscape) {
        if (stringToEscape === "") {
            return stringToEscape;
        }
        return stringToEscape.replace(/'/g, "'").replace(/"/g, '"');
    }


    return !data ? (
        <LoadSpinner/>
    ) : (
        <Container>
            <div style={{marginBottom: "1rem", color: "black"}}>
                <Link to="/narnia/news" style={{
                    color: "black",
                    textDecoration: "none",
                    alignItems: "center",
                    display: "flex",
                    marginBottom: "2rem",
                    marginTop: "1rem"
                }}><i
                    className="fas fa-arrow-left" style={{marginRight: ".5rem", fontSize: "18px"}}/>Back</Link>
                <Title>Game Details</Title>
            </div>
            <SpaceY number="1.5rem">
                <div
                    style={{width: "100%", display: "flex", flexDirection: "column"}}>
                    <label>Title</label>
                    <Input
                        ref={headerRef}
                        className="input"
                        defaultValue={
                            data && data.header && data.header !== "null"
                                ? data.header
                                : ""
                        }
                        placeholder="Title here..."
                        onChange={(e) => {
                            editHeader(e.target.value);
                        }}
                    />
                </div>
                <div style={{display: "inline-block", width: "100%", marginBottom: "5rem"}}>
                    {data && (
                        <ModoyoEditor
                            text={data.first_paragraph}
                            update={editFirstParagraph}
                            hidePublishButton
                        />
                    )}
                </div>
                <div style={{display: "inline-block", width: "100%", marginBottom: "5rem"}}>
                    {data && (
                        <ModoyoEditor
                            text={data.second_paragraph}
                            update={editSecondParagraph}
                            hidePublishButton
                        />
                    )}
                </div>
            </SpaceY>
        </Container>
    );
}
