import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Node } from "slate";
import parse from "html-react-parser";
import withTracker from "../../../hoc/withTracker";
import { Helmet } from "react-helmet-async";
import * as S from "./style";
import * as GS from "../../../styles/global-style";
import calculateReadingTime from "../../../utils/calculateReadingTime";
import serialize from "../../../utils/serializeItem";
import ContentSection from "../../BlogPage/ContentSection";
import PopoverComponent from "../Popover";
import LoadSpinner from "../../LoadSpinner";
import Breadcrumb from "../../Breadcrumb";

function NewsArticle() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [blogDetails, setBlogDetails] = useState();

  useEffect(() => {
    const getBlogDetails = () => {
      fetch(`/.netlify/functions/getpost?id=${id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.error) navigate("/blogs");
          setBlogDetails(data);
        })
        .catch(() => {
          navigate("/blogs");
        });
    };

    getBlogDetails();
  }, [navigate, id]);

  return (
    <>
      <Breadcrumb href="/blog" title="Blog" fixedWidth />
      {blogDetails ? (
        <S.Container $md>
          <GS.SpaceY number="4rem">
            <GS.SpaceY number="2rem">
              {blogDetails.header && (
                <Helmet>
                  <title>{blogDetails.header}</title>
                </Helmet>
              )}
              <S.ArticleHeading>
                {blogDetails && blogDetails.header}
              </S.ArticleHeading>
              {blogDetails.author && (
                <GS.FlexCenter>
                  <PopoverComponent blogDetails={blogDetails} />
                  <S.NameContainer>
                    <S.FullName>{blogDetails.author.full_name}</S.FullName>
                    <S.RoleContainer>
                      <S.RoleText>{blogDetails.author.job_title}</S.RoleText>
                      <S.ReadingTimeText>
                        {blogDetails &&
                          calculateReadingTime(
                            serializeNews(JSON.parse(blogDetails.newbody))
                          )}
                      </S.ReadingTimeText>
                    </S.RoleContainer>
                  </S.NameContainer>
                </GS.FlexCenter>
              )}
            </GS.SpaceY>
            <S.ContentText>
              {blogDetails &&
                parse(serialize({ children: JSON.parse(blogDetails.body) }))}
            </S.ContentText>
          </GS.SpaceY>
        </S.Container>
      ) : (
        <LoadSpinner />
      )}
      <S.StyledSvg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="20" fill="#70D13F" />
        <path
          d="M20.1881 33.9984C19.7804 33.9984 19.3743 33.9446 18.9796 33.8369C18.3925 33.6755 17.8511 33.4129 17.3684 33.059C16.8824 32.7067 16.4714 32.2566 16.1485 31.7233C15.8272 31.1933 15.6217 30.5866 15.5385 29.9196C15.4537 29.2396 15.5092 28.5937 15.7016 27.9985C15.8973 27.4065 16.1974 26.8748 16.597 26.4182C16.9965 25.9583 17.4678 25.5816 17.9979 25.2962C18.5295 25.0091 19.0922 24.8281 19.6695 24.7564C19.8717 24.7303 20.0756 24.7189 20.2745 24.7189C20.6773 24.7189 21.0802 24.771 21.4699 24.8754C22.0538 25.032 22.5969 25.2945 23.0828 25.6533C23.5705 26.0121 23.9831 26.4606 24.3092 26.9874C24.6338 27.5158 24.8409 28.1241 24.9257 28.7976C25.0105 29.4728 24.9551 30.1153 24.761 30.7073C24.5685 31.2961 24.2701 31.8293 23.8738 32.2941C23.4775 32.7557 23.0095 33.1357 22.4794 33.4194C21.9543 33.7048 21.3819 33.8875 20.7817 33.9625C20.5828 33.987 20.3822 34 20.1865 34L20.1881 33.9984ZM19.6059 26.8014C18.2865 26.8014 17.246 27.4081 16.9574 28.3475C16.7649 28.9721 16.9231 29.6636 17.4026 30.2963C17.8853 30.9324 18.6339 31.4314 19.5113 31.7021C19.9598 31.8408 20.4148 31.9109 20.8633 31.9109C22.1826 31.9109 23.2231 31.3042 23.5118 30.3632C23.9081 29.07 22.7632 27.5647 20.9562 27.0086C20.5077 26.8699 20.0544 26.7998 19.6059 26.7998V26.8014Z"
          fill="white"
        />
        <path
          d="M20.2566 15.273C19.7739 15.273 19.2911 15.198 18.8214 15.0512C18.2311 14.8669 17.6978 14.5766 17.233 14.1901C16.7666 13.7987 16.3817 13.3355 16.0898 12.8137C15.793 12.2869 15.607 11.708 15.5353 11.0915C15.4619 10.475 15.5271 9.83738 15.7277 9.19646C15.9316 8.54249 16.2512 7.97659 16.6736 7.51669C17.096 7.06005 17.5902 6.70126 18.1446 6.44848C18.7008 6.19733 19.2846 6.05056 19.8848 6.01142C19.9973 6.00326 20.1098 6 20.2207 6C20.7034 6 21.178 6.07176 21.6298 6.21364C22.2185 6.39793 22.7534 6.68659 23.2198 7.0682C23.6863 7.45308 24.0711 7.91624 24.3647 8.44464C24.6582 8.97303 24.8474 9.55361 24.9273 10.1684C25.004 10.7833 24.9388 11.4226 24.7365 12.07C24.5327 12.7207 24.2163 13.2817 23.7939 13.7384C23.3748 14.1934 22.8823 14.5554 22.3294 14.8147C21.7765 15.0708 21.1927 15.2208 20.5942 15.2599C20.4833 15.2681 20.3691 15.2714 20.2582 15.2714L20.2566 15.273ZM19.2601 7.70913C18.6062 7.70913 18.0419 7.93908 17.6701 8.35657C17.2363 8.8442 17.0928 9.54057 17.2656 10.3136C17.4401 11.0931 17.9147 11.858 18.6013 12.4679C19.3808 13.161 20.33 13.5573 21.2074 13.5573C21.8613 13.5573 22.4256 13.3274 22.7975 12.9099C23.6944 11.8988 23.2769 10.0527 21.8646 8.7969C21.0867 8.10542 20.1375 7.7075 19.2618 7.7075L19.2601 7.70913Z"
          fill="white"
        />
        <path
          d="M20.1506 24.7156C19.6042 24.7156 19.0449 24.6047 18.4871 24.3862C17.8527 24.1383 17.3145 23.7827 16.8889 23.3294C16.4632 22.8793 16.1371 22.3623 15.9185 21.7931C15.7016 21.2239 15.5924 20.632 15.5956 20.0302C15.5956 19.4316 15.7082 18.8429 15.9283 18.2786C16.1534 17.703 16.4795 17.1909 16.8987 16.7538C17.3211 16.3151 17.8103 15.9645 18.3518 15.7084C18.8981 15.4491 19.4885 15.304 20.1082 15.2746C20.175 15.2714 20.2435 15.2697 20.3104 15.2697C20.8633 15.2697 21.4275 15.3806 21.9853 15.5992C22.6229 15.8487 23.1644 16.2058 23.5949 16.6608C24.0206 17.1142 24.3435 17.6328 24.5571 18.2036C24.7691 18.7744 24.8751 19.368 24.8719 19.9698C24.8703 20.5732 24.761 21.1538 24.549 21.6969C24.3239 22.2726 23.9994 22.7863 23.5851 23.2234C23.1676 23.6621 22.6784 24.0127 22.1321 24.2687C21.5841 24.5248 20.9921 24.6732 20.374 24.7091C20.3006 24.7123 20.2272 24.7156 20.1538 24.7156H20.1506ZM21.2008 17.0229C20.3006 17.0229 19.2976 17.4779 18.5181 18.2379C17.8609 18.8804 17.4238 19.6665 17.2868 20.4542C17.1498 21.237 17.3276 21.9252 17.7842 22.3916C18.1512 22.7667 18.6649 22.9641 19.2683 22.9641C20.1702 22.9641 21.1731 22.5091 21.9527 21.7491C23.3046 20.4281 23.6341 18.5657 22.6865 17.5969C22.3196 17.2219 21.8059 17.0229 21.2025 17.0229H21.2008Z"
          fill="white"
        />
      </S.StyledSvg>
      <S.Container $lg>
        <S.OtherArticlesText>
          Other articles you might like...
        </S.OtherArticlesText>
        <ContentSection />
      </S.Container>
    </>
  );
}

const serializeNews = (nodes) => {
  return nodes.map((n) => Node.string(n)).join("\n");
};

export default withTracker(NewsArticle);
