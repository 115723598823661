import withTracker from "../../hoc/withTracker";
import * as S from "./style";
import ContentSection from "../../components/SingleCareerPage/ContentSection";

function SingleCareerPage() {
  return (
    <S.SingleCareerPageContainer>
      <ContentSection />
    </S.SingleCareerPageContainer>
  );
}

export default withTracker(SingleCareerPage);
