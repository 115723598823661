import React, {
  useLayoutEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import {Theme} from "../../../utils/Theme";
import {useParams, useNavigate, Link} from "react-router-dom";
import LoadSpinner from "../../../components/LoadSpinner";
import ModoyoEditor from "../../../components/ModoyoEditor";
import {Container, SpaceY, Title, BenefitsItem, SpaceX} from "../../../styles/global-style";
import {Input} from "../../../components/Admin/User/Account/style";
import Button from "../../../components/Button";
import {ButtonContainer, FixedOverlay, FlexContainer, Modal, ModalPanel, ModalTitle} from "../../../styles/modal";
import {Icon} from "../../../components/Admin/News/style";

export default function Edit() {
  const {setColor} = useContext(Theme)
  const navigate = useNavigate()
  const {idx} = useParams()

  const [data, setData] = useState()
  const [benefits, setBenefits] = useState([])
  const [benefitId, setBenefitId] = useState(null)
  const [isOpenDeleteBenefit, setIsOpenDeleteBenefit] = useState(false)
  const [inputValues, setInputValues] = useState([{
    title: '',
    imageUrl: '',
  }])

  const headerRef = useRef(null)

  const getData = useCallback(() => {
    fetch(`/.netlify/functions/getjobdetails`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data)
      })
  }, [])

  useLayoutEffect(() => {
    setColor("red")
    getData()
    getBenefits()
  }, [setColor, idx, navigate, getData])

  function fetchRequestData(_data) {
    return fetch("/.netlify/functions/editjobdetails", {
      method: "POST",
      body: JSON.stringify(_data),
    }).then(() => {
      getData()
    })
  }

  function editHeader(header) {
    fetchRequestData({
      header: header ? header : null,
    })
  }

  function editParagraph(header, content) {
    fetchRequestData({
      content: content ? mysqlEscape(JSON.stringify(content)) : null,
    })
  }

  function mysqlEscape(stringToEscape) {
    if (stringToEscape === "") {
      return stringToEscape;
    }
    return stringToEscape.replace(/'/g, "'").replace(/"/g, '"')
  }


  const addRow = () => {
    setInputValues((prevInputValues) => [
      ...prevInputValues,
      {
        title: '',
        imageUrl: '',
      },
    ])
  }

  const getBenefits = () => {
    fetch("/.netlify/functions/get-benefits", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((_data) => {
        setBenefits(_data.filter((item) => item))
      })
  }

  const addBenefits = () => {
    fetch("/.netlify/functions/add-benefits", {
      method: "POST",
      body: JSON.stringify(inputValues),
    })
      .then(() => {
        setInputValues([{
          title: '',
          imageUrl: '',
        }])
        getBenefits()
      })
      .catch((error) => {
        console.error("Error adding benefits:", error)
        // Handle the error as needed, e.g., show an error message to the user.
      })
  }

  const editBenefits = () => {
    fetch("/.netlify/functions/edit-benefits", {
      method: "POST",
      body: JSON.stringify(benefits),
    })
      .then(() => {
        getBenefits()
      })
      .catch((error) => {
        console.error("Error adding benefits:", error)
      })
  }

  const confirmDeleteBenefit = (benefitId) => {
    fetch("/.netlify/functions/delete-benefit", {
      method: "DELETE",
      body: JSON.stringify({id: benefitId}),
    }).then(() => {
      getBenefits()
      setIsOpenDeleteBenefit(false)
    })
  }

  const deleteBenefit = (benefitId) => {
    setIsOpenDeleteBenefit(true)
    setBenefitId(benefitId)
  }

  const handleInputChange = (index, field, value, isInputRow = true) => {
    if (isInputRow) {
      // Update the input values for dynamically generated rows
      setInputValues((prevInputValues) => {
        const updatedInputValues = [...prevInputValues];
        updatedInputValues[index][field] = value;
        return updatedInputValues;
      })
    } else {
      // Update the benefits data for existing benefits
      setBenefits((prevBenefits) => {
        const updatedBenefits = [...prevBenefits];
        updatedBenefits[index][field] = value;
        return updatedBenefits;
      })
    }
  }


  return !data ? (
    <LoadSpinner/>
  ) : (
    <Container>
      <div style={{marginBottom: "1rem", color: "black"}}>
        <Link to="/narnia/news" style={{
          color: "black",
          textDecoration: "none",
          alignItems: "center",
          display: "flex",
          marginBottom: "2rem",
          marginTop: "1rem"
        }}><i
          className="fas fa-arrow-left" style={{marginRight: ".5rem", fontSize: "18px"}}/>Back</Link>
        <Title>Game Details</Title>
      </div>
      <SpaceY number="1.5rem">
        <div
          style={{width: "100%", display: "flex", flexDirection: "column"}}>
          <label>Title</label>
          <Input
            ref={headerRef}
            className="input"
            defaultValue={
              data && data.header && data.header !== "null"
                ? data.header
                : ""
            }
            placeholder="Title here..."
            onChange={(e) => {
              editHeader(e.target.value)
            }}
          />
        </div>
        <div style={{display: "inline-block", width: "100%", marginBottom: "2rem"}}>
          {data && (
            <ModoyoEditor
              text={data.paragraph}
              update={editParagraph}
              hidePublishButton
            />
          )}
        </div>
        <div style={{display: "inline-block", width: "100%", marginBottom: "2rem"}}>
          <label>Benefits</label>
          <SpaceY number="1rem">
            <div>
              {benefits.map((benefit, index) => (
                <BenefitsItem key={index}>
                  <Input
                    className="title-input"
                    placeholder="Title"
                    value={benefit.title}
                    onChange={(e) => handleInputChange(index, 'title', e.target.value, false)}
                  />
                  <Input
                    className="image-url-input"
                    placeholder="Image URL"
                    value={benefit.imageUrl}
                    onChange={(e) => handleInputChange(index, 'imageUrl', e.target.value, false)}
                  />
                  <div>
                    <Icon
                      className="fas fa-trash"
                      $delete
                      onClick={() => deleteBenefit(benefit.id)}
                    />
                  </div>
                </BenefitsItem>
              ))}
            </div>
            <Button
              text="Edit Benefits"
              callback={editBenefits}
              black
            />
            <div>
              {inputValues.map((value, index) => (
                <BenefitsItem key={index}>
                  <Input
                    className="title-input"
                    placeholder="Title"
                    value={value.title}
                    onChange={(e) => handleInputChange(index, 'title', e.target.value, true)}
                  />
                  <Input
                    className="image-url-input"
                    placeholder="Image URL"
                    value={value.imageUrl}
                    onChange={(e) => handleInputChange(index, 'imageUrl', e.target.value, true)}
                  />
                </BenefitsItem>
              ))}
            </div>
            <SpaceX number="1rem">
              <Button
                text="Add Row"
                callback={addRow}
                whiteBg
              />
              <Button
                text="Add Benefits"
                callback={addBenefits}
                black
              />
            </SpaceX>
          </SpaceY>
        </div>
      </SpaceY>

      <Modal open={isOpenDeleteBenefit}
             onClose={() => setIsOpenDeleteBenefit(false)}>
        <FixedOverlay>
          <FlexContainer>
            <ModalPanel>
              <ModalTitle>
                Delete benefit
              </ModalTitle>
              <div>
                Are you sure you want to delete this benefit?
              </div>
              <ButtonContainer>
                <Button text="Delete" callback={() => confirmDeleteBenefit(benefitId)} black/>
                <Button text="Cancel" callback={() => setIsOpenDeleteBenefit(false)}/>
              </ButtonContainer>
            </ModalPanel>
          </FlexContainer>
        </FixedOverlay>
      </Modal>
    </Container>
  )
}
