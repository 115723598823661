import { useState } from "react";
import {
  CharacterImageContainer,
  CharacterSmallImage,
  CharacterSmallBg,
} from "./style";

function CharacterImage({ src, onHover }) {
  const [isHovered, setIsHovered] = useState(false);

  const hoverHandler = () => {
    setIsHovered(true);
    onHover();
  };

  return (
    <CharacterImageContainer
      onMouseEnter={hoverHandler}
      onMouseLeave={() => setIsHovered(false)}
      $isHovered={isHovered}
    >
      <CharacterSmallImage loading="lazy" src={src} alt="CharacterImage" />
      <CharacterSmallBg $isHovered={isHovered} />
    </CharacterImageContainer>
  );
}

export default CharacterImage;
