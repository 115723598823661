import styled from "styled-components";

export const FooterContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 100;
  background-color: ${(props) => (props.$hasBg ? "#250759" : "transparent")};
`;

export const Footer = styled.footer`
  max-width: 80rem;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  box-sizing: border-box;

  @media (max-width: 820px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    padding: 56px 40px 96px 40px;
  }
`;

export const FooterCopyright = styled.p`
  color: #fcfaff;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  text-align: center;
  font-family: "Roboto Flex", sans-serif;
  margin: 0;
`;

export const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const SocialMediaLink = styled.a`
  display: flex;
  text-decoration: none;
`;

export const SocialMediaIcon = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
`;
