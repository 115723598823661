import styled from "styled-components";

export const ImagesContainer = styled.div`
  margin-top: 100px;
  align-items: center;
  justify-content: center;
  z-index: 1;

  .slick-center {
    transform: scale(1.2);
    transition: all 0.3s;

    img {
      scale: 1.1;
      opacity: 1;
      border: 8px solid #fcfaff;
      &:hover {
        box-shadow: none;
      }
      @media (max-width: 900px) {
        border: 4px solid #fcfaff;
        scale: 1.3;
      }
    }

  }

  @media (max-width: 900px) {
    margin-top: 55px;
  }
`;

export const SliderItemContainer = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-block: 20px;
`;
export const SliderItem = styled.div`
  padding-block: 40px;

  @media (max-width: 900px) {
    width: 172px;
    height: 172px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 20px;
  }
`;

export const SliderItemImage = styled.img`
  width: 220px;
  height: 220px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  opacity: 0.6;
  &:hover {
    box-shadow: 4px 8px 0 0 #70D13F;
  }

  @media (max-width: 900px) {
    width: 112px;
    height: 112px;
  }
`;
