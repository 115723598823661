import styled from "styled-components";
import * as Accordion from "@radix-ui/react-accordion";

export const MobileContainer = styled.div`
  display: none;

  @media (max-width: 900px) {
    display: block;
    margin-top: 35px;
    padding-bottom: 300px;
  }
`;

export const AccordionRoot = styled(Accordion.Root)`
  display: none;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const AccordionItem = styled(Accordion.Item)`
  background-color: transparent;
`;

export const AccordionTrigger = styled(Accordion.Trigger)`
  display: ${({ $isSubCategory }) => ($isSubCategory ? "none" : "flex")};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  margin: 0;
`;

export const AccordionTriggerTitle = styled.span`
  width: 85%;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  font-family: "Roboto Mono", sans-serif;
  color: #1c122e;
  text-align: left;
  text-transform: uppercase;
  visibility: ${({ $isSubCategory }) =>
    $isSubCategory ? "hidden" : "visible"};
  height: ${({ $isSubCategory }) => ($isSubCategory ? "0" : "auto")};
`;

export const AccordionContent = styled(Accordion.Content)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
`;

export const AccordionContentItem = styled.div``;

export const AccordionContentSubTitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  font-family: "Roboto Mono", sans-serif;
  text-align: left;
  margin-top: 0;
`;

export const HorizontalDivider = styled.div`
  height: 2px;
  background-color: #e545cb;
`;

export const SvgContainer = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
`;

export const PlusSvg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ $active }) => ($active ? 0 : 1)};
  pointer-events: ${({ $active }) =>
    $active ? "none" : "auto"}; /* Disable interaction when hidden */
  transition: opacity 0.2s ease;
  width: 32px;
  height: 32px;
`;

export const MinusSvg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ $active }) => ($active ? 1 : 0)};
  pointer-events: ${({ $active }) =>
    $active ? "auto" : "none"}; /* Disable interaction when hidden */
  transition: opacity 0.2s ease;
  width: 32px;
  height: 32px;
`;

export const DescriptionSubTitle = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  color: #000000;
  font-family: "Roboto Flex", sans-serif;
  margin-bottom: 20px;
  margin-top: ${({ $isGrid }) => ($isGrid ? "20px" : "6px")};
`;
