import styled from "styled-components";

export const HamburgerIconContainer = styled.div`
  display: none;

  @media (max-width: 900px) {
    width: 56px;
    height: 56px;
    border-radius: 100%;
    background-color: #fa754a;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 200;
  }
`;

export const HamburgerIcon = styled.svg`
  width: 32px;
  height: 32px;
`;
