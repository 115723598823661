import React, { useState, useEffect } from "react";
import { compareByDate } from "../../../utils/common";
import { useLocation } from "react-router-dom";
import { Node } from "slate";
import * as S from "./style";
import BlogItem from "../BlogItem";
import MobileBlogItem from "../MobileBlogItem";
import LoadSpinner from "../../../components/LoadSpinner";

export default function ContentSection({ blogs }) {
  let pathname = useLocation()?.pathname;
  let id = pathname.match(/^\/blog\/(\d+)$/)?.[1];

  const envDev = process.env.NODE_ENV === "development";

  const [data, setData] = useState([]);

  useEffect(() => {
    function getData() {
      fetch("/.netlify/functions/getposts", {
        method: "GET",
      })
        .then((res) => res.json())
        .then((_data) => {
          if (id) {
            const _id = parseInt(id, 10);
            setData(
              _data.filter((item) =>
                envDev ? item.id !== _id : item.published && item.id !== _id
              )
            );
          } else {
            setData(_data.filter((item) => (envDev ? true : item.published)));
          }
        });
    }

    getData();
  }, [id, envDev]);

  const serialize = (nodes) => {
    return nodes.map((n) => Node.string(n)).join("\n");
  };

  if (!data.length) return <LoadSpinner white={!id} />;

  return (
    <S.ContentContainer>
      {data.sort(compareByDate).map((article) => {
        if (!article.newbody) return <></>;

        const content = JSON.parse(article.newbody);

        return (
          <React.Fragment key={article.id}>
            <BlogItem article={article} content={serialize(content)} />
            <MobileBlogItem article={article} content={serialize(content)} />
          </React.Fragment>
        );
      })}
    </S.ContentContainer>
  );
}
