import React, { useState } from "react";
import * as S from "./style";
import { contentData } from "../ContentData";

export default function MobileContainer({ images }) {
  const [active, setActive] = useState(null);

  const toggleActive = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const renderContentWithSubItems = (item, sliceStart, sliceEnd) => (
    <>
      {item.content}
      {contentData.slice(sliceStart, sliceEnd).map((subItem, subIndex) => (
        <React.Fragment key={subIndex}>
          <S.DescriptionSubTitle $isGrid={subItem.isGrid}>
            {subItem.title}
          </S.DescriptionSubTitle>
          <div>{subItem.content}</div>
        </React.Fragment>
      ))}
    </>
  );

  return (
    <S.MobileContainer>
      <S.AccordionRoot type="single" collapsible>
        {contentData.map((item, index) => (
          <S.AccordionItem key={index} value={"item-" + index}>
            <S.AccordionTrigger
              onClick={() => toggleActive(index)}
              $isSubCategory={item.isSubCategory}
            >
              <S.AccordionTriggerTitle $isSubCategory={item.isSubCategory}>
                {item.title}
              </S.AccordionTriggerTitle>
              <S.SvgContainer>
                <S.MinusSvg
                  $active={active === index}
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_1268_888"
                    mask-type="alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="32"
                    height="32"
                  >
                    <path
                      d="M28.5 16C28.5 16.3978 28.342 16.7794 28.0607 17.0607C27.7794 17.342 27.3978 17.5 27 17.5H5C4.60218 17.5 4.22064 17.342 3.93934 17.0607C3.65804 16.7794 3.5 16.3978 3.5 16C3.5 15.6022 3.65804 15.2206 3.93934 14.9393C4.22064 14.658 4.60218 14.5 5 14.5H27C27.3978 14.5 27.7794 14.658 28.0607 14.9393C28.342 15.2206 28.5 15.6022 28.5 16Z"
                      fill="#F5F5F5"
                    />
                  </mask>
                  <g mask="url(#mask0_1268_888)">
                    <path
                      d="M32.0002 0H11.5951C10.8609 1.70074 10.4541 3.57593 10.4541 5.54613C10.4541 13.2809 16.7243 19.5511 24.4591 19.5511C27.2355 19.5511 29.8233 18.7432 32.0002 17.3495V0Z"
                      fill="#B2249B"
                    />
                  </g>
                  <mask
                    id="mask1_1268_888"
                    mask-type="alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="32"
                    height="32"
                  >
                    <path
                      d="M28.5 16C28.5 16.3978 28.342 16.7794 28.0607 17.0607C27.7794 17.342 27.3978 17.5 27 17.5H5C4.60218 17.5 4.22064 17.342 3.93934 17.0607C3.65804 16.7794 3.5 16.3978 3.5 16C3.5 15.6022 3.65804 15.2206 3.93934 14.9393C4.22064 14.658 4.60218 14.5 5 14.5H27C27.3978 14.5 27.7794 14.658 28.0607 14.9393C28.342 15.2206 28.5 15.6022 28.5 16Z"
                      fill="#F5F5F5"
                    />
                  </mask>
                  <g mask="url(#mask1_1268_888)">
                    <path
                      d="M32 17.3495C29.8232 18.7432 27.2354 19.5511 24.4589 19.5511C16.7241 19.5511 10.4539 13.2809 10.4539 5.54613C10.4539 3.57593 10.8607 1.70074 11.5949 0L0 0V32H32V17.3495Z"
                      fill="#B2249B"
                    />
                  </g>
                </S.MinusSvg>
                <S.PlusSvg
                  $active={active === index}
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_1268_841"
                    mask-type="alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="32"
                    height="32"
                  >
                    <path
                      d="M28.5 16C28.5 16.3978 28.342 16.7794 28.0607 17.0607C27.7794 17.342 27.3978 17.5 27 17.5H17.5V27C17.5 27.3978 17.342 27.7794 17.0607 28.0607C16.7794 28.342 16.3978 28.5 16 28.5C15.6022 28.5 15.2206 28.342 14.9393 28.0607C14.658 27.7794 14.5 27.3978 14.5 27V17.5H5C4.60218 17.5 4.22064 17.342 3.93934 17.0607C3.65804 16.7794 3.5 16.3978 3.5 16C3.5 15.6022 3.65804 15.2206 3.93934 14.9393C4.22064 14.658 4.60218 14.5 5 14.5H14.5V5C14.5 4.60218 14.658 4.22064 14.9393 3.93934C15.2206 3.65804 15.6022 3.5 16 3.5C16.3978 3.5 16.7794 3.65804 17.0607 3.93934C17.342 4.22064 17.5 4.60218 17.5 5V14.5H27C27.3978 14.5 27.7794 14.658 28.0607 14.9393C28.342 15.2206 28.5 15.6022 28.5 16Z"
                      fill="#F5F5F5"
                    />
                  </mask>
                  <g mask="url(#mask0_1268_841)">
                    <path
                      d="M32.0002 0H11.5951C10.8609 1.70074 10.4541 3.57593 10.4541 5.54613C10.4541 13.2809 16.7243 19.5511 24.4591 19.5511C27.2355 19.5511 29.8233 18.7432 32.0002 17.3495V0Z"
                      fill="#B2249B"
                    />
                  </g>
                  <mask
                    id="mask1_1268_841"
                    mask-type="alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="32"
                    height="32"
                  >
                    <path
                      d="M28.5 16C28.5 16.3978 28.342 16.7794 28.0607 17.0607C27.7794 17.342 27.3978 17.5 27 17.5H17.5V27C17.5 27.3978 17.342 27.7794 17.0607 28.0607C16.7794 28.342 16.3978 28.5 16 28.5C15.6022 28.5 15.2206 28.342 14.9393 28.0607C14.658 27.7794 14.5 27.3978 14.5 27V17.5H5C4.60218 17.5 4.22064 17.342 3.93934 17.0607C3.65804 16.7794 3.5 16.3978 3.5 16C3.5 15.6022 3.65804 15.2206 3.93934 14.9393C4.22064 14.658 4.60218 14.5 5 14.5H14.5V5C14.5 4.60218 14.658 4.22064 14.9393 3.93934C15.2206 3.65804 15.6022 3.5 16 3.5C16.3978 3.5 16.7794 3.65804 17.0607 3.93934C17.342 4.22064 17.5 4.60218 17.5 5V14.5H27C27.3978 14.5 27.7794 14.658 28.0607 14.9393C28.342 15.2206 28.5 15.6022 28.5 16Z"
                      fill="#F5F5F5"
                    />
                  </mask>
                  <g mask="url(#mask1_1268_841)">
                    <path
                      d="M32 17.3495C29.8232 18.7432 27.2354 19.5511 24.4589 19.5511C16.7241 19.5511 10.4539 13.2809 10.4539 5.54613C10.4539 3.57593 10.8607 1.70074 11.5949 0L0 0V32H32V17.3495Z"
                      fill="#B2249B"
                    />
                  </g>
                </S.PlusSvg>
              </S.SvgContainer>
            </S.AccordionTrigger>
            {active !== index && !item.isSubCategory && <S.HorizontalDivider />}
            <S.AccordionContent>
              {index === 2 ? (
                renderContentWithSubItems(item, 3, 9)
              ) : index === 9 ? (
                renderContentWithSubItems(item, 10, 15)
              ) : (
                <div>{item.content}</div>
              )}
              <S.HorizontalDivider />
            </S.AccordionContent>
          </S.AccordionItem>
        ))}
      </S.AccordionRoot>
    </S.MobileContainer>
  );
}
