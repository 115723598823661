import withTracker from "../../hoc/withTracker";
import * as S from "./style";
import * as GS from "../../styles/global-style";
import ContentSection from "../../components/PrivacyPolicy/ContentSection";

function PrivacyPolicy() {
  return (
    <GS.HeaderFooterSpace $disabledFooterSpace>
      <S.StyledSvg
        width="621"
        height="1256"
        viewBox="0 0 621 1256"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M366.156 -216.841C280.355 -305.859 172.455 -375.414 45.3781 -424.55C-65.7732 -467.304 -178.225 -489 -288.401 -489C-301.726 -489 -315.376 -489 -328.701 -488.043C-452.203 -482.3 -569.854 -453.903 -678.731 -403.173C-786.632 -353.08 -884.133 -284.482 -968.309 -198.655C-1051.84 -113.146 -1116.84 -12.9613 -1161.69 99.6672C-1205.56 210.062 -1227.99 325.243 -1227.99 442.339C-1228.64 559.753 -1206.86 675.891 -1163.64 787.243C-1120.09 898.595 -1054.76 999.738 -970.259 1087.8C-885.108 1176.5 -778.181 1246.05 -651.755 1294.55C-540.604 1337.3 -429.128 1359 -320.251 1359C-305.626 1359 -291.001 1358.68 -276.376 1357.72C-152.874 1350.7 -35.2224 1321.67 73.9789 1271.58C183.18 1221.8 280.681 1152.89 363.557 1067.06C446.108 981.551 510.784 881.047 555.634 768.419C597.885 662.171 619.66 548.586 619.985 430.533C620.635 313.119 599.509 196.662 557.259 84.9904C514.683 -26.6809 450.333 -128.142 365.507 -216.841H366.156ZM38.8784 778.31C-116.473 927.311 -316.351 1016.01 -496.078 1016.01C-616.329 1016.01 -718.705 977.403 -791.831 904.019C-882.832 812.449 -918.258 677.805 -890.958 524.975C-863.658 370.869 -776.231 217.082 -645.579 91.3716C-490.553 -57.6298 -290.676 -146.329 -110.949 -146.329C9.62778 -146.329 111.679 -107.403 184.805 -34.0193C373.632 155.503 307.982 519.87 38.5535 778.31H38.8784Z"
          stroke="#FCFAFF"
          strokeWidth="2"
        />
      </S.StyledSvg>
      <S.PrivacyPolicyContainer>
        <ContentSection />
      </S.PrivacyPolicyContainer>
    </GS.HeaderFooterSpace>
  );
}

export default withTracker(PrivacyPolicy);
