import * as S from "./style";

export default function ContentSection() {
  return (
    <S.ContentContainer>
      <S.Svg
        width="1850"
        height="1440"
        viewBox="0 0 1850 1440"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1595.16 93.1588C1509.36 4.14088 1401.45 -65.4144 1274.38 -114.55C1163.23 -157.304 1050.78 -179 940.599 -179C927.274 -179 913.624 -179 900.299 -178.043C776.797 -172.3 659.146 -143.903 550.269 -93.1726C442.368 -43.0801 344.867 25.518 260.691 111.345C177.165 196.854 112.164 297.039 67.3137 409.667C23.4382 520.062 1.01413 635.243 1.01413 752.339C0.364126 869.753 22.1389 985.891 65.3644 1097.24C108.915 1208.6 174.24 1309.74 258.741 1397.8C343.892 1486.5 450.819 1556.05 577.245 1604.55C688.396 1647.3 799.872 1669 908.749 1669C923.374 1669 937.999 1668.68 952.624 1667.72C1076.13 1660.7 1193.78 1631.67 1302.98 1581.58C1412.18 1531.8 1509.68 1462.89 1592.56 1377.06C1675.11 1291.55 1739.78 1191.05 1784.63 1078.42C1826.88 972.171 1848.66 858.586 1848.99 740.533C1849.64 623.119 1828.51 506.662 1786.26 394.99C1743.68 283.319 1679.33 181.858 1594.51 93.1588H1595.16ZM1267.88 1088.31C1112.53 1237.31 912.649 1326.01 732.922 1326.01C612.671 1326.01 510.295 1287.4 437.169 1214.02C346.168 1122.45 310.742 987.805 338.042 834.975C365.342 680.869 452.769 527.082 583.421 401.372C738.447 252.37 938.324 163.671 1118.05 163.671C1238.63 163.671 1340.68 202.597 1413.8 275.981C1602.63 465.503 1536.98 829.87 1267.55 1088.31H1267.88Z"
          stroke="#5B28B5"
          strokeWidth="2"
        />
      </S.Svg>
      <S.ContentWrapper>
        <S.ContentInnerContainer>
          <S.ContentTitle>Feel the Pulse. Beat the Odds.</S.ContentTitle>
          <S.ContentTextContainer>
            <S.ContentText>A new studio here to show what</S.ContentText>
            <S.ContentText $green>
              inclusivity, community and a love of games
            </S.ContentText>
            <S.ContentText>can create</S.ContentText>
          </S.ContentTextContainer>
        </S.ContentInnerContainer>
      </S.ContentWrapper>
    </S.ContentContainer>
  );
}
