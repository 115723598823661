import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const styles = css`
  border-radius: 4px;
  padding: ${({ $blue, $red }) => ($blue || $red ? "20px 80px" : "16px 80px")};
  font-family: ${({ $blue, $red }) =>
    $blue || $red
      ? "'Sofia Sans Condensed', sans-serif"
      : "'Roboto Mono', sans-serif"};
  font-size: ${({ $blue, $red }) => ($blue || $red ? "32px" : "20px")};
  font-weight: 700;
  line-height: ${({ $blue, $red }) => ($blue || $red ? "32px" : "20px")};
  text-align: center;
  z-index: 2;
  border: none;
  box-shadow: ${({ disabled, $red, $blue }) =>
    disabled
      ? "none"
      : $red || $blue
      ? "0px 4px 0px 0px rgba(16, 18, 26, 0.1)"
      : "0px 8px 0px 0px rgba(23, 23, 23, 0.1)"};
  color: ${({ disabled }) => (disabled ? "#FCFAFF80" : "#fcfaff")};
  background: ${({ $red, $blue, $pink, disabled }) => {
    if (disabled) return "#7F7E80";
    if ($red)
      return "linear-gradient(180deg, #FF63A4 0%, #D13174 50%, #8F0B42 100%)";
    if ($blue)
      return "linear-gradient(180deg, #29CFBC 15%, #14AECC 50%, #0F8399 100%)";
    if ($pink)
      return "#E545CB";
    return "#53B224";
  }};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  text-decoration: none;

  @media (max-width: 900px) {
    padding: ${({ $blue, $red }) =>
      $blue || $red ? "12px 45px" : "16px 40px"};
  }

  ${({ disabled, $red, $blue, $pink}) =>
    !disabled &&
    `&:hover {
    background: ${
      $red || $blue
        ? "linear-gradient(180deg, #FCFAFF 0%, #F2EDED 100%)"
        : "#fcfaff"
    };
    box-shadow: ${
      $red || $blue
        ? "0px 4px 0px 0px rgba(16, 18, 26, 0.1)"
        : $pink ? "4px 4px 0px 0px rgba(229, 69, 203, 1)" : "4px 4px 0px 0px rgba(112, 209, 63, 1)"
    };
    color: ${$blue ? "#15ADCA" : $red ? "#C92D6E" : "#1c122e"} ;
    font-style: ${$red || $blue ? "normal" : "italic"}

  }`}

  ${({ disabled, $red, $blue}) =>
    !disabled &&
    `&:active {
    background: ${$blue ? "#15ADCA" : $red ? "#C92D6E" : "#5b28b5"};
    box-shadow: ${
      $blue || $red ? "0px 4px 0px 0px rgba(16, 18, 26, 0.1)" : "none"
    };
    color: #fcfaff;
   font-style: ${$red || $blue ? "normal" : "italic"}
  }`}
`;

const StyledLink = styled(({ disabled, ...props }) => (
  <Link {...props} onClick={disabled ? (e) => e.preventDefault() : undefined} />
))`
  ${styles}
`;

const StyledButton = styled.button`
  ${styles}
`;

export const CTA = ({ children, href, onClick, disabled, $red, $blue, $pink }) => {
  return href ? (
    <StyledLink to={href} disabled={disabled} $red={$red} $blue={$blue} $pink={$pink}>
      {children}
    </StyledLink>
  ) : (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      $red={$red}
      $blue={$blue}
      $pink={$pink}
    >
      {children}
    </StyledButton>
  );
};
