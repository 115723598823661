import * as S from "./style";

export default function Button(props) {
  const { text, href, callback, onClick, disabled } = props;

  const onClickHandler = () => {
    if (callback) callback();
    if (onClick) onClick();
  };

  return (
    <S.Button
      text={text}
      href={href}
      onClick={onClickHandler}
      disabled={disabled}
    />
  );
}
