import * as S from "./style";
import NavigationItem from "../NavigationItem";
import { FooterItems, SocialMediaItems } from "./FooterItems";

export default function NewFooter({ hasBg }) {
  return (
    <S.FooterContainer $hasBg={hasBg}>
      <S.Footer>
        <S.FooterCopyright>
          © {new Date().getFullYear()} MODOYO GAMES - ALL RIGHTS RESERVED
        </S.FooterCopyright>
        <S.NavigationContainer>
          {FooterItems.map((item) => (
            <NavigationItem
              key={item.text}
              text={item.text}
              href={item.href}
              secondary
            />
          ))}
        </S.NavigationContainer>
        <S.SocialMediaContainer>
          {SocialMediaItems.map((item) => (
            <S.SocialMediaLink
              key={item.text}
              href={item.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.src}
            </S.SocialMediaLink>
          ))}
        </S.SocialMediaContainer>
      </S.Footer>
    </S.FooterContainer>
  );
}
