import escapeHtml from "escape-html";

const serialize = (item) => {
  if (item.text !== undefined) {
    let string = escapeHtml(item.text);
    if (item.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (item.italic) {
      string = `<em>${string}</em>`;
    }
    if (item.underline) {
      string = `<u>${string}</u>`;
    }
    return string;
  }

  const children = item.children.map((n) => serialize(n)).join("");

  switch (item.type) {
    case "headline-three":
      return `
            <div class="paragraph">
              <h3 class="heading-three font-Figtree">
                ${children}
              </h3>
            </div>
          `;
    case "headline-four":
      return `
            <div class="paragraph">
              <h4 class="heading-four">
                ${children}
              </h4>
            </div>
          `;
    case "bulleted-list":
      return `<div class="paragraph"><ul>${children}</ul></div>`;
    case "list-item":
      return `<div class="paragraph"><li>${children}</li></div>`;
    case "numbered-list":
      return `<div class="paragraph"><ol>${children}</ol></div>`;
    case "heading-one":
      return `<div class="paragraph"><h2 class="heading-one">${children}</h2></div>`;
    case "quote":
      return `<blockquote><p>${children}</p></blockquote>`;
    case "ingress":
      return `<div class="ingress"><p class="newstext">${children}</p></div>`;
    case "paragraph":
      if (children.trim() === "") {
        return `<div class="paragraph"><p class="newstext empty-paragraph">${children}</p></div>`;
      } else {
        return `<div class="paragraph"><p class="newstext">${children}</p></div>`;
      }
    case "link":
      return `<a href="${escapeHtml(
        item.url
      )}" target="_blank" rel="noreferrer" class="font-black">${children}</a>`;
    case "image":
      return `<center><img class="newsimg" alt="img" src="${escapeHtml(
        item.url
      )}"/></center>`;
    default:
      return children;
  }
};

export default serialize;
