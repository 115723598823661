export const compareByDate = (a, b) => {
  return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
}

export const transformTitle = (title) => {
  // Convert the title to lowercase
  let lowercaseTitle = title.toLowerCase();

  // Replace spaces with "-"
  let dashedTitle = lowercaseTitle.replace(/\s+/g, '-');

  // Remove non-alphanumeric characters
  return dashedTitle.replace(/[^a-z0-9-]/g, '');
}