import { useEffect, useState } from "react";
import Slider from "react-slick";
import * as S from "./style";
import CharacterImage from "./Image";
import CharactersData from "./Data";
import CharacterSectionBg from "../../../imgs/heartelectric/CharacterSectionBg.png";
import FirstChar from "../../../imgs/heartelectric/first-character.png";
import FirstBigChar from "../../../imgs/heartelectric/first-big-character.png";
import SecondChar from "../../../imgs/heartelectric/second-character.png";
import SecondBigChar from "../../../imgs/heartelectric/second-big-character.png";
import ThirdChar from "../../../imgs/heartelectric/third-character.png";
import ThirdBigChar from "../../../imgs/heartelectric/third-big-character.png";
import FourthChar from "../../../imgs/heartelectric/fourth-character.png";
import FourthBigChar from "../../../imgs/heartelectric/fourth-big-character.png";
import CTA from "../../CTA";

function CharactersSection() {
  const [activeCharacter, setActiveCharacter] = useState(0);
  const [charSource, setCharSource] = useState(FirstBigChar);

  let settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (i) => setActiveCharacter(i),
  };

  useEffect(() => {
    switch (activeCharacter) {
      case 0:
        setCharSource(FirstBigChar);
        break;
      case 1:
        setCharSource(SecondBigChar);
        break;
      case 2:
        setCharSource(ThirdBigChar);
        break;
      case 3:
        setCharSource(FourthBigChar);
        break;
      default:
        setCharSource(FirstBigChar);
    }
  }, [activeCharacter]);

  return (
    <>
      <S.CharacterHeaderContainer>
        <S.CharacterHeaderTitle>FEEL THE PULSE</S.CharacterHeaderTitle>
        <S.CharacterHeaderText>
          Read and react to the ebbs and flows of Energy to decide the winner in
          this first person shooter that challenges you to outsmart and outplay
          three other teams. Pick your heroes and build your loadouts to form
          the perfect team to master the Energy battle.
        </S.CharacterHeaderText>
        <S.ButtonContainer>
          <CTA
            red
            callback={() =>
              window.open("https://bit.ly/HEWebsiteSteam", "_blank")
            }
          >
            GET PLAYTEST ACCESS
          </CTA>
        </S.ButtonContainer>
      </S.CharacterHeaderContainer>
      <S.CharacterContainer $image={CharacterSectionBg}>
        <S.CharacterInnerContainer>
          <div>
            <S.CharactersImages>
              {[FirstChar, SecondChar, ThirdChar, FourthChar].map(
                (charImage, index) => (
                  <CharacterImage
                    key={index}
                    src={charImage}
                    onHover={() => setActiveCharacter(index)}
                  />
                )
              )}
            </S.CharactersImages>
            <S.CharacterTitle>
              {CharactersData[activeCharacter].name}
            </S.CharacterTitle>
            <S.CharacterText>
              {CharactersData[activeCharacter].description}
            </S.CharacterText>
            <CTA
              red
              callback={() =>
                window.open("https://bit.ly/HEWebsiteSteam", "_blank")
              }
            >
              GET PLAYTEST ACCESS
            </CTA>
          </div>
          <S.CharacterBigImage loading="lazy" src={charSource} alt={charSource} />
        </S.CharacterInnerContainer>
      </S.CharacterContainer>
      <S.MobileCharacterContainer $image={CharacterSectionBg}>
        <Slider {...settings}>
          {[FirstBigChar, SecondBigChar, ThirdBigChar, FourthBigChar].map(
            (bigChar, index) => (
              <S.CharacterBigImageContainer key={index}>
                <S.CharacterBigImage loading="lazy" src={bigChar} alt={bigChar} />
              </S.CharacterBigImageContainer>
            )
          )}
        </Slider>
        <S.CharacterTitle>
          {CharactersData[activeCharacter].name}
        </S.CharacterTitle>
        <S.CharacterText>
          {CharactersData[activeCharacter].description}
        </S.CharacterText>
      </S.MobileCharacterContainer>
    </>
  );
}

export default CharactersSection;
