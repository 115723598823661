import styled from "styled-components";

export const CharacterContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 1400px;
  width: 100vw;
  margin-left: 50%;
  transform: translate(-50%, -30%);
  overflow: hidden;
  background-image: url(${(props) => props.$image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const CharacterInnerContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  position: absolute;
  bottom: 80px;
`;

export const CharactersImages = styled.div`
  display: flex;
  gap: 30px;
  align-items: flex-end;
  margin-bottom: 40px;
`;

export const CharacterImageContainer = styled.div`
  position: relative;
  cursor: pointer;
  padding: ${({ $isHovered }) => ($isHovered ? "2px" : "4px")};
`;

export const MobileCharacterContainer = styled.div`
  height: 1180px;
  width: 100vw;
  margin-left: 50%;
  transform: translate(-50%, -30%);
  overflow: hidden;
  background-image: url(${(props) => props.$image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 900px) {
    display: none;
  }
`;

export const CharacterHeaderContainer = styled.div`
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-inline: auto;

  @media (max-width: 900px) {
    height: 450px;
  }
`;

export const CharacterHeaderTitle = styled.h2`
  font-size: 104px;
  line-height: 88.4px;
  font-weight: 800;
  font-family: Montserrat, sans-serif;
  margin-top: 140px;
  color: #31819a;

  @media (max-width: 900px) {
    font-size: 48px !important;
    line-height: 40.8px;
    margin-top: 0;
    margin-bottom: 15px;
    width: 295px;
  }
`;

export const CharacterHeaderText = styled.h3`
  color: #270000;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  font-family: Montserrat, sans-serif;
  max-width: 490px;
  margin-top: 0;

  @media (max-width: 900px) {
    margin-top: 10px;
    font-size: 18px;
    line-height: 21px;
    width: 295px;
  }
`;

export const CharacterTitle = styled.h2`
  white-space: nowrap;
  font-size: 85px;
  font-weight: 700;
  line-height: 140px;
  font-family: "Sofia Sans Extra Condensed", sans-serif;
  margin-bottom: 12px;
  color: #fff;

  @media (max-width: 900px) {
    font-size: 80px !important;
    line-height: 80px;
    margin-top: 40px;
    text-align: center;
  }
`;

export const CharacterText = styled.h3`
  color: #fff;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  font-family: Montserrat, sans-serif;
  margin-top: 0;
  height: 145px;
  max-width: 550px;

  @media (max-width: 900px) {
    margin: auto;
    max-width: 295px;
    font-size: 18px !important;
    line-height: 21px;
    text-align: center;
  }
`;

export const CharacterBigImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const CharacterBigImage = styled.img`
  height: 765px;
  margin: auto;

  @media (max-width: 900px) {
    margin: auto;
    height: 469px;
    width: auto !important;
    margin-top: 350px;
  }
`;

export const CharacterSmallImage = styled.img`
  width: 100px;
  position: absolute;
  bottom: 8px;
  left: 8px;
`;

export const CharacterSmallBg = styled.div`
  width: 100px;
  height: 100px;
  border: ${({ $isHovered }) =>
    $isHovered
      ? "6px solid rgba(245, 245, 245, 1)"
      : "4px solid rgba(245, 245, 245, 1)"};
  border-radius: 6px;
  background: rgba(16, 18, 26, 0.5);
`;

export const ButtonContainer = styled.div`
  display: none;

  @media (max-width: 900px) {
    display: block;
    position: relative;
    z-index: 1;
  }
`;
