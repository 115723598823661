import * as S from "./style";
import Slider from "react-slick";
import SliderItem from "./SliderItem";
import LoadSpinner from "../../LoadSpinner";

export default function ImagesSection({
  currentSlide,
  setCurrentSlide,
  nav1,
  teamMembers,
  setSlider1,
}) {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 5,
    slidesToScroll: 5,
    speed: 500,
    asNavFor: nav1,
    focusOnSelect: true,
    swipe: false,
    afterChange: (index) => setCurrentSlide(index),
    ref: (slider) => setSlider1(slider),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          centerPadding: "100px",
          focusOnSelect: true,
          swipe: true,
          slidesToShow: 1,
          afterChange: (index) => setCurrentSlide(index),
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerMode: true,
          centerPadding: "100px",
          focusOnSelect: true,
          swipe: true,
          slidesToShow: 3,
          afterChange: (index) => setCurrentSlide(index),
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "60px",
          focusOnSelect: true,
          swipe: true,
          slidesToShow: 3,
          afterChange: (index) => setCurrentSlide(index),
        },
      },
    ],
  };

  if (!teamMembers) return <LoadSpinner white={true} />;

  return (
    <S.ImagesContainer className="slider-container">
      <Slider {...settings}>
        {teamMembers.map((member, index) => (
          <SliderItem
            key={index}
            onClick={() => setCurrentSlide(index)}
            member={member}
            currentSlide={currentSlide === index}
          />
        ))}
      </Slider>
    </S.ImagesContainer>
  );
}
