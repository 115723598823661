import styled from "styled-components";

export const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: ${(props) =>
    props.$scrolled || props.$hasBg ? "#250759" : "transparent"};
  transition: background-color 0.3s ease-in-out;
  z-index: 100;

  @media (max-width: 900px) {
    position: relative;
  }
`;

export const Header = styled.header`
  max-width: 80rem;
  margin-inline: auto;
  padding: 40px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    display: ${(props) => (props.$open ? "none" : "flex")};
    padding: ${(props) => (props.$open ? "48px 200px" : "0")};
  }
`;

export const MobileHeader = styled.header`
  display: none;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 40px;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    z-index: 999;
    background-color: #1c122e;
  }
`;

export const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;

  @media (max-width: 900px) {
    display: ${(props) => (props.$open ? "flex" : "none")};
    flex-direction: column;
    align-items: flex-end;
    padding: 0 24px 0 0;
    margin-top: 40px;
  }
`;

export const StyledSvg = styled.svg`
  position: absolute;
  top: -75px;
  left: 0;
  width: 100%;
  z-index: -1;
`;
