import React, {useEffect} from 'react';
import ReactGA from 'react-ga4';
import {useLocation} from "react-router-dom";
import {Helmet} from 'react-helmet-async';

export default function withTracker(WrappedComponent, options = {}) {
    const trackPage = (page) => {
        ReactGA.set({
            page,
            ...options
        });
        ReactGA.send(page);
    }

    const WithTracker = (props) => {
        const location = useLocation();

        useEffect(() => {
            if (process.env.NODE_ENV === 'production') {
                ReactGA.initialize('G-L7PBG86551')
            }
        }, [])

        useEffect(() => {
            if (process.env.NODE_ENV === 'production') {
                trackPage(location.pathname + location.search);
            }
        }, [location])

        return <>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-7GRX9EX5X0"></script>
            {props.title && (
                <Helmet>
                    <title>{props.title}</title>
                </Helmet>
            )}
            <WrappedComponent {...props} />
        </>
    }

    return WithTracker
}
