import styled, { css } from "styled-components";
import * as Accordion from "@radix-ui/react-accordion";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 180px;
  padding-bottom: 150px;
  padding-inline: 2rem;

  @media (max-width: 900px) {
    padding-top: 50px;
    padding-bottom: 70px;
  }

  ${(props) =>
    props.$innerWidth &&
    css`
      padding-top: ${(props) => (props.$innerWidth < 900 ? "0" : "350px")};
    `}
  text-align : left;

  ${(props) =>
    props.$sm &&
    css`
      @media (min-width: 640px) {
        max-width: 640px;
      }
    `}

  ${(props) =>
    props.$md &&
    css`
      @media (min-width: 768px) {
        max-width: 768px;
      }
    `}

  ${(props) =>
    props.$lg &&
    css`
      @media (min-width: 1024px) {
        max-width: 1024px;
      }
    `}

  ${(props) =>
    props.$xl &&
    css`
      @media (min-width: 1280px) {
        max-width: 1280px;
      }
    `}

  ${(props) =>
    props["2xl"] &&
    css`
      @media (min-width: 1536px) {
        max-width: 1536px;
      }
    `}
`;

export const VacanciesContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 130px;

  @media (max-width: 900px) {
    margin-bottom: 0;
  }
`;

export const VacanciesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 0 88px 0 32px;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const VacanciesHeaderText = styled.h3`
  margin: 0;
  width: ${({ $index }) => ($index === 0 ? "40%" : "30%")};
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  font-family: "Roboto Mono", sans-serif;
  color: #e087d2;
`;

export const VacanciesList = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 900px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

export const AccordionRoot = styled(Accordion.Root)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
`;

export const WelcomeText = styled.h2`
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  font-family: Figtree, sans-serif;
  color: #fcfaff;
  text-align: center;
  max-width: 748px;

  @media (max-width: 900px) {
    font-size: 24px !important;
    line-height: 30px;
    max-width: 295px;
  }
`;

export const CheckOpeningsText = styled.h2`
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  font-family: Figtree, sans-serif;
  color: #fcfaff;
  text-align: center;
  margin-bottom: ${({ $hasJobs }) => ($hasJobs ? "100px" : "30px")};
  margin-top: 0;

  @media (max-width: 900px) {
    margin-bottom: ${({ $hasJobs }) => ($hasJobs ? "40px" : "80px")};
    margin-top: ${({ $hasJobs }) => ($hasJobs ? "0" : "50px")};
  }
`;
