import React, { useRef } from "react";
import { Input } from "../../../components/Admin/User/Account/style";
import ModoyoEditor from "../../../components/ModoyoEditor";

export function SectionEditor({ title, content, editSection }) {
  const headerRef = useRef(null);

  return (
    <div style={{ marginBottom: "3rem" }}>
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <label>Title</label>
        <Input
          ref={headerRef}
          className="input"
          defaultValue={title}
          placeholder={`Enter title...`}
          onChange={(e) => {
            editSection(e.target.value);
          }}
        />
      </div>
      <div
        style={{
          display: "inline-block",
          width: "100%",
          marginBottom: "1.5rem",
        }}
      >
        <ModoyoEditor text={content} update={editSection} hidePublishButton />
      </div>
    </div>
  );
}
