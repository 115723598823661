import * as S from "./style";
import { contentData } from "./ContentData";
import MobileContainer from "./MobileContainer";

export default function ContentSection() {
  return (
    <S.Container $xl>
      <S.Title>Terms & Conditions</S.Title>
      <S.LasUpdatedText $isBold>
        Please read these terms and conditions carefully before using Our
        Service.
      </S.LasUpdatedText>
      <S.LasUpdatedText>Last updated: 18th September 2024</S.LasUpdatedText>
      <S.ContentSectionContainer>
        <S.NavigationList>
          {contentData.map((item) => (
            <S.NavigationItem key={item.id}>
              <S.BulletPoint>•</S.BulletPoint>
              <S.NavigationTitle
                href={`#${item.id}`}
                onClick={(e) => {
                  e.preventDefault();
                  const target = document.getElementById(item.id);
                  if (target) {
                    target.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }
                }}
              >
                {item.title}
              </S.NavigationTitle>
            </S.NavigationItem>
          ))}
        </S.NavigationList>
        <S.DescriptionContainer>
          {contentData.map((item) => (
            <S.DescriptionItem key={item.id}>
              <S.DescriptionTitle id={item.id}>{item.title}</S.DescriptionTitle>
              {item.content}
            </S.DescriptionItem>
          ))}
        </S.DescriptionContainer>
      </S.ContentSectionContainer>
      <MobileContainer />
    </S.Container>
  );
}
