import styled from "styled-components";

export const ContentWrapper = styled.div`
  max-width: 80rem;
  height: 732px;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  height: 100%;
  background: linear-gradient(180deg, #5b28b5 -49.32%, #1c122e 100%);
  z-index: 0;
  position: relative;
  overflow: hidden;

  @media (max-width: 900px) {
    min-height: 900px;
  }
`;

export const ContentInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 80px;
  max-width: 700px;
  z-index: 1;

  @media (max-width: 900px) {
    width: 80%;
    padding-top: 45px;
  }
`;

export const ContentTitle = styled.h1`
  font-size: 56px !important;
  font-weight: 700;
  line-height: 70px;
  font-family: Figtree, sans-serif;
  color: #fcfaff;
  text-align: center;
  max-width: 400px;

  @media (max-width: 900px) {
    line-height: 56px;
    max-width: 235px;
  }
`;

export const ContentTextContainer = styled.div`
  display: inline;
  margin-top: 20px;
  text-align: center;
  max-width: 490px;

  @media (max-width: 900px) {
    margin-top: 15px;
    max-width: 395px;
  }
`;

export const ContentText = styled.span`
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  font-family: "Roboto Mono", sans-serif;
  margin: 0;
  padding-right: 8px;
  color: ${(props) => (props.$green ? "#98D977" : "#fcfaff")};
`;

export const Svg = styled.svg`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 0;

  @media (max-width: 900px) {
    display: none;
  }
`;
