import styled from "styled-components";
import { Link } from "react-router-dom";

export const BlogItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: auto;
  background-color: #fcfaff;
  padding: 0 0 0 24px;
  border-radius: 8px;
  position: relative;
  gap: 16px;
  overflow: hidden;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const BlogItemInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px 0;
`;

export const BlogItemTextContainer = styled.div`
  min-width: 260px;
`;

export const BlogItemTitle = styled.h2`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 235px;
  font-size: 20px;
  font-weight: 700;
  font-family: Figtree, sans-serif;
  color: #1c122e;
  margin: 0;
`;

export const BlogItemDescription = styled.p`
  display: -webkit-box;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 10px 0;
  width: 235px;
  z-index: 10;
  font-weight: 400;
  font-family: "Roboto Flex", sans-serif;
  color: #171717;
`;

export const BlogItemImageContainer = styled.img`
  display: flex;
  align-self: flex-start;
  width: 200px;
  height: 208px;
  -webkit-mask-image: url(${({ $mask }) => $mask});
  mask-image: url(${({ $mask }) => $mask});
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  mask-size: cover;
  object-fit: cover;
  overflow: hidden;
  z-index: 1;
`;

export const BlogItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

export const BlogItemOutline = styled.img`
  position: absolute;
  top: -4px;
  right: 14px;
  height: 100%;
  border-top-right-radius: 8px;
  z-index: 0;
`;

export const CardLink = styled(Link)`
  text-decoration: none;
  border-radius: 8px;

  &:hover {
    box-shadow: 8px 8px 0px 0px rgba(112, 209, 63, 1);
    transition: all 0.2s ease-in-out;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const BlogWrapper = styled.div`
  margin-left: 24px;
  display: flex;
  align-self: center;
  border-radius: 8px;
  border-top: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  box-shadow: 8px 8px 0px 0px #340b7c;
`;
