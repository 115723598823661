import { Link } from "react-router-dom";
import styled from "styled-components";

export const NavigationItem = styled(Link)`
  border-radius: 4px;
  padding: 8px 16px;
  font-family: "Roboto Flex", sans-serif;
  font-size: ${(props) => (props.$secondary ? "14px" : "16px")};
  font-weight: 800;
  line-height: ${(props) => (props.$secondary ? "14px" : "16px")};
  text-align: center;
  color: ${(props) =>
    props.disabled ? "#FCFAFF80" : props.$active ? "#1C122E" : "#FCFAFF"};
  background-color: ${(props) => (props.$active ? "#FCFAFF" : "transparent")};
  box-shadow: ${(props) =>
    !props.$active
      ? "none"
      : !props.$secondary
      ? "4px 4px 0px 0px rgba(112, 209, 63, 1)"
      : "4px 4px 0px 0px rgba(250, 117, 74, 1)"};
  border: none;
  text-decoration: none;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  ${(props) =>
    !props.disabled &&
    `&:hover {
    background-color: ${props.$secondary ? "#FA754A" : "#70d13f"};
    box-shadow: 4px 4px 0px 0px rgba(37, 7, 89, 1);
    color: ${props.$secondary ? "#FCFAFF" : "#1c122e"};
    font-style: italic;
    }`};

  ${(props) =>
    !props.disabled &&
    `&:active {
      background-color: #fcfaff;
      box-shadow: none;
      color: #1c122e;
      font-style: italic;
    }`};

  @media (max-width: 900px) {
    font-weight: 700;
  }
`;
