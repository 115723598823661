import HeroSection from "../../components/LandingPage/HeroSection";
import ContentSection from "../../components/LandingPage/ContentSection";
import withTracker from "../../hoc/withTracker";
import * as GS from "../../styles/global-style";

function LandingPage() {
  return (
    <GS.HeaderFooterSpace $disabledFooterSpace>
      <HeroSection />
      <ContentSection />
    </GS.HeaderFooterSpace>
  );
}

export default withTracker(LandingPage);
