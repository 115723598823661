import styled from "styled-components";

export const AttachButtonContainer = styled.form`
  width: 100%;
  border-radius: 16px;
  background-color: #5b28b5;
  grid-column: span 2;
  cursor: pointer;
  padding: 12px 0;

  &:hover {
    background-color: #fcfaff;
    box-shadow: 4px 4px 0px 0px #e545cb;
  }

  &:hover label {
    color: #1c122e;
  }

  &:active {
    background-color: #e545cb;
    box-shadow: none;
  }

  &:active label {
    color: #fcfaff;
  }

  @media (max-width: 900px) {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-inline: auto;
    width: 70%;
    grid-column: span 6;
  }
`;

export const Label = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fcfaff;
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
  font-family: "Roboto Mono", sans-serif;
  cursor: pointer;
`;
