import styled, { css } from "styled-components";
import * as Accordion from "@radix-ui/react-accordion";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 120px;
  padding-inline: 2rem;

  @media (max-width: 900px) {
    padding-top: 80px;
  }

  ${(props) =>
    props.$innerWidth &&
    css`
      padding-top: ${(props) => (props.$innerWidth < 900 ? "0" : "350px")};
    `}

  ${(props) =>
    props.$sm &&
    css`
      @media (min-width: 640px) {
        max-width: 640px;
      }
    `}

  ${(props) =>
    props.$md &&
    css`
      @media (min-width: 768px) {
        max-width: 768px;
      }
    `}

  ${(props) =>
    props.$lg &&
    css`
      @media (min-width: 1024px) {
        max-width: 1024px;
      }
    `}

  ${(props) =>
    props.$xl &&
    css`
      @media (min-width: 1280px) {
        max-width: 1280px;
      }
    `}

  ${(props) =>
    props["2xl"] &&
    css`
      @media (min-width: 1536px) {
        max-width: 1536px;
      }
    `}
`;

export const BenefitsContainer = styled.div`
  position: relative;
  background: #301660;
  padding-bottom: 150px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(
      127.49% 100% at 50% 100%,
      #b2249b 0%,
      rgba(229, 69, 203, 0.5) 50%,
      rgba(224, 135, 210, 0) 100%
    );
    z-index: 1;
    pointer-events: none;
  }
`;

export const BenefitsInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  gap: 24px;
  z-index: 1;

  @media (max-width: 900px) {
    gap: 8px;
  }
`;

export const BenefitsTitle = styled.h1`
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  font-family: Figtree, sans-serif;
  color: #fcfaff;
  text-align: center;

  @media (max-width: 900px) {
    font-size: 32px !important;
    line-height: 40px;
    text-align: center;
  }
`;

export const BenefitsList = styled(Accordion.Root)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0;
  padding: 0;

  @media (max-width: 900px) {
  gap: 0;
`;

export const BenefitItem = styled(Accordion.Item)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0;
  padding: 24px;
  border-bottom: 2px solid #5b28b5;

  @media (max-width: 900px) {
    padding: 32px 12px;
    gap: ${({ $active }) => ($active ? "24px" : "0")};
  }
`;

export const BenefitsSubtitleContainer = styled(Accordion.Trigger)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
`;

export const BenefitsSubtitle = styled.h3`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  font-family: "Roboto Mono", sans-serif;
  color: #fcfaff;
  margin: 0;

  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 18px;
  }
`;

export const BenefitsContentContainer = styled(Accordion.Content)`
  display: flex;
  flex-direction: column;
`;

export const BenefitsListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;
  list-style-type: disc;
`;

export const BenefitsListItem = styled.li`
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  font-family: "Roboto Flex", sans-serif;
  color: #fcfaff;
  margin: 0;
  text-decoration: dotted;

  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const BenefitsWorkExperience = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 90px;
`;

export const WorkExperienceText = styled.p`
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  font-family: "Roboto Flex", sans-serif;
  color: #fcfaff;
  margin: 0;
  text-align: center;

  @media (max-width: 900px) {
    font-size: 18px !important;
    line-height: 27px;
  }
`;

export const SvgContainer = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
`;

export const PlusSvg = styled.svg`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ $active }) => ($active ? 0 : 1)};
  pointer-events: ${({ $active }) =>
    $active ? "none" : "auto"}; /* Disable interaction when hidden */
  transition: opacity 0.2s ease;
  width: 32px;
  height: 32px;
`;

export const MinusSvg = styled.svg`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ $active }) => ($active ? 1 : 0)};
  pointer-events: ${({ $active }) =>
    $active ? "auto" : "none"}; /* Disable interaction when hidden */
  transition: opacity 0.2s ease;
  width: 32px;
  height: 32px;
`;
