import * as S from "./style";
import * as GS from "../../styles/global-style";
import HeadSection from "../../components/BlogPage/HeadSection";
import ContentSection from "../../components/BlogPage/ContentSection";
import withTracker from "../../hoc/withTracker";

function BlogPage() {
  return (
    <GS.HeaderFooterSpace $disabledFooterSpace>
      <S.BlogContainer>
        <S.BlogWrapper>
          <S.StyledSvg
            width="1123"
            height="1440"
            viewBox="0 0 1123 1440"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M868.156 181.159C782.355 92.1409 674.455 22.5856 547.378 -26.5497C436.227 -69.3039 323.775 -91 213.599 -91C200.274 -91 186.624 -91 173.299 -90.0428C49.7975 -84.2997 -67.8543 -55.9033 -176.731 -5.17264C-284.632 44.9199 -382.133 113.518 -466.309 199.345C-549.835 284.854 -614.836 385.039 -659.686 497.667C-703.562 608.062 -725.986 723.243 -725.986 840.339C-726.636 957.753 -704.861 1073.89 -661.636 1185.24C-618.085 1296.6 -552.76 1397.74 -468.259 1485.8C-383.108 1574.5 -276.181 1644.05 -149.755 1692.55C-38.6036 1735.3 72.8724 1757 181.749 1757C196.374 1757 210.999 1756.68 225.624 1755.72C349.126 1748.7 466.778 1719.67 575.979 1669.58C685.18 1619.8 782.681 1550.89 865.557 1465.06C948.108 1379.55 1012.78 1279.05 1057.63 1166.42C1099.88 1060.17 1121.66 946.586 1121.99 828.533C1122.64 711.119 1101.51 594.662 1059.26 482.99C1016.68 371.319 952.333 269.858 867.507 181.159H868.156ZM540.878 1176.31C385.527 1325.31 185.649 1414.01 5.92208 1414.01C-114.329 1414.01 -216.705 1375.4 -289.831 1302.02C-380.832 1210.45 -416.258 1075.81 -388.958 922.975C-361.658 768.869 -274.231 615.082 -143.579 489.372C11.4475 340.37 211.324 251.671 391.051 251.671C511.628 251.671 613.679 290.597 686.805 363.981C875.632 553.503 809.982 917.87 540.553 1176.31H540.878Z"
              stroke="#5B28B5"
              strokeWidth="2"
            />
          </S.StyledSvg>
          <HeadSection />
          <ContentSection />
        </S.BlogWrapper>
      </S.BlogContainer>
    </GS.HeaderFooterSpace>
  );
}

export default withTracker(BlogPage);
