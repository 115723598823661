import styled from "styled-components";
import * as Accordion from "@radix-ui/react-accordion";

export const AccordionItem = styled(Accordion.Item)`
  border-radius: 16px;
  background-color: ${({ $active, $id }) =>
    $id ? "#f2eded" : $active ? "#f2eded" : "#250759"};
  box-shadow: ${({ $active, $id }) =>
    $id && $active
      ? "8px 8px 0px 0px rgba(229, 69, 203, 1)"
      : $active
      ? "8px 8px 0px 0px rgba(112, 209, 63, 1)"
      : "0px 4px 0px 0px rgba(0, 0, 0, 0.3)"};
`;

export const AccordionTrigger = styled(Accordion.Trigger)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  height: 100%;
  padding: ${({ $active }) => ($active ? "40px 32px 32px 32px" : "24px 32px")};
  border-radius: 16px;
  border: none;
  cursor: pointer;
  background-color: ${({ $active, $id }) =>
    $id ? "#f2eded" : $active ? "#f2eded" : "#250759"};

  @media (max-width: 900px) {
    align-items: flex-start;
    padding: 24px;
    gap: 8px;
  }
`;

export const AccordionTitlesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const AccordionTriggerTitle = styled.h2`
  margin: 0;
  width: ${({ $index }) => ($index === 0 ? "40%" : "30%")};
  font-size: 20px;
  line-height: 20px;
  font-style: ${({ $active }) => ($active ? "italic" : "normal")};
  font-weight: ${({ $active }) => ($active ? 700 : 500)};
  color: ${({ $id, $active }) =>
    $id ? "#1C122E" : $active ? "#1C122E" : "#FCFAFF"};
  text-align: left;
  font-family: "Roboto Flex", sans-serif;

  @media (max-width: 900px) {
    font-weight: ${({ $index }) => ($index === 0 ? "700" : "500")};
    font-size: 18px;
    line-height: 18px;
    width: 100%;
  }
`;

export const AccordionContent = styled(Accordion.Content)`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ $active }) => ($active ? "32px" : "0")};
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
`;

export const HorizontalDivider = styled.div`
  height: 2px;
  background-color: ${({ $id }) => ($id ? "#E545CB" : "#98d977")};
  margin-inline: 32px;
`;

export const ContentOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 32px;

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 20px;
  }
`;

export const ContentInnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
`;

export const RoleDescriptionTitle = styled.h4`
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  font-family: "Roboto Mono", sans-serif;
  color: ${({ $id }) => ($id ? "#53B224" : "#e545cb")};
  margin: 0;
`;

export const RoleDescription = styled.p`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  font-family: "Roboto Flex", sans-serif;
  color: #1c122e;
  margin: 0;
`;

export const LinkContainer = styled.div`
  width: 150px;
  padding-left: 32px;

  @media (max-width: 900px) {
  padding-left: 10px;
`;

export const ChevronContainer = styled.svg`
  width: 32px;
  height: 32px;
  color: ${({ $id }) => ($id ? "red" : "#FCFAFF")};
  fill: ${({ $id }) => ($id ? "red" : "#FCFAFF")};

  @media (max-width: 900px) {
    width: 24px;
    height: 24px;
  }
`;
