import React, { useContext, useLayoutEffect, useState } from "react";
import {
  Container,
  FullHeight,
  SpaceY,
  Title,
} from "../../../../styles/global-style";
import { Input } from "../../../../components/Admin/User/Account/style";
import { ImageCropperThumb } from "../../../../components/ImageCropper/ImageCropperThumb";
import { AuthContext } from "../../../../utils/AuthContext";
import { Theme } from "../../../../utils/Theme";
import Button from "../../../../components/Button";
import { Link } from "react-router-dom";

function Account() {
  const { setColor } = useContext(Theme);
  const { user } = useContext(AuthContext);

  const [profileUrl, setProfileUrl] = useState(
    user.user_metadata.profile_url || ""
  );
  const [inputValues, setInputValues] = useState({
    fullName: user.user_metadata.full_name,
    jobTitle: user.user_metadata.job_title,
  });

  useLayoutEffect(() => {
    setColor("red");
  }, [setColor]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
    }));
  };

  const addProfileImage = (header, content, published, thumbUrl) => {
    setProfileUrl(thumbUrl);
  };

  function editUser() {
    user
      .update({
        data: {
          full_name: inputValues.fullName,
          job_title: inputValues.jobTitle,
          profile_url: profileUrl,
        },
      })
      .then(() => {
        setProfileUrl(profileUrl);
      })
      .catch((error) => {
        console.log("Failed to update user: %o", error);
        throw error;
      });
  }

  return (
    <FullHeight>
      <Container>
        <SpaceY number="1.5rem">
          <Link
            to="/narnia"
            style={{
              color: "black",
              textDecoration: "none",
              alignItems: "center",
              display: "flex",
              marginBottom: "2rem",
              marginTop: "1rem",
            }}
          >
            <i
              className="fas fa-arrow-left"
              style={{ marginRight: ".5rem", fontSize: "18px" }}
            />
            Back
          </Link>
          <Title>Account</Title>
          <div>
            <ImageCropperThumb
              editPost={addProfileImage}
              thumb={profileUrl}
              hideCardPreview
            />
          </div>
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <label>Fullname</label>
            <Input
              type="text"
              name="fullName"
              placeholder="Full Name"
              value={inputValues.fullName || user?.user_metadata.full_name}
              onChange={handleInputChange}
            />
          </div>
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <label>Job Title</label>
            <Input
              type="text"
              name="jobTitle"
              placeholder="Job Title"
              value={inputValues.jobTitle}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <Button text="Save" black callback={editUser} />
          </div>
        </SpaceY>
      </Container>
    </FullHeight>
  );
}

export default Account;
