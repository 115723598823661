import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-block: 5rem;
  padding-inline: 2rem;

  @media (max-width: 900px) {
    padding-top: 2.5rem;
    padding-bottom: 0;
  }
  ${(props) =>
    props.$innerWidth &&
    css`
      padding-top: ${(props) => (props.$innerWidth < 900 ? "0" : "350px")};
    `}
  text-align : left;

  ${(props) =>
    props.$sm &&
    css`
      @media (min-width: 640px) {
        max-width: 640px;
      }
    `}

  ${(props) =>
    props.$md &&
    css`
      @media (min-width: 768px) {
        max-width: 768px;
      }
    `}

  ${(props) =>
    props.$lg &&
    css`
      @media (min-width: 1024px) {
        max-width: 1024px;
      }
    `}

  ${(props) =>
    props.$xl &&
    css`
      @media (min-width: 1280px) {
        max-width: 1280px;
      }
    `}

  ${(props) =>
    props["2xl"] &&
    css`
      @media (min-width: 1536px) {
        max-width: 1536px;
      }
    `}
`;

export const ApplicationSectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  background: #fcf7f7;
  box-shadow: 0px 8px 0px 0px rgba(229, 69, 203, 1);
  margin-bottom: 8px;
  padding-bottom: 20px;

  @media (max-width: 900px) {
    padding-top: 20px;
    padding-bottom: 80px;
  }
`;

export const WelcomeText = styled.h3`
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  font-family: Figtree, sans-serif;
  color: #000000;
  margin-bottom: 72px;
  width: 750px;
  text-align: center;

  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 60px;
    font-size: 18px !important;
    line-height: 21.6px;
  }

  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 21.6px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 760px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const FormInnerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 24px;
  width: 100%;
`;

export const FormInputContainer = styled.div`
  display: flex;
  height: 58px;
  grid-column: span ${({ $colSpan }) => $colSpan};

  @media (max-width: 900px) {
    grid-column: span 6;
  }
`;

export const FormInput = styled.input`
  flex: 1;
  height: 40px;
  padding: 8.5px 24px;
  border: 1px solid #5b28b5;
  background: #fcfaff;
  border-radius: 4px;
  font-size: 18px;
  line-height: 22.5px;
  font-weight: 700;
  font-family: Roboto Flex, monospace;
  color: #250759;

  &::placeholder {
    color: #17171780;
    font-size: 18px;
    line-height: 22.5px;
    font-weight: 500;
    font-style: italic;
    font-family: Roboto Mono, monospace;
  }

  &:hover {
    border-width: 2px;
    border-color: #fa754a;
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 4px 4px 0px 0px #fa754a;
  }

  &:focus {
    outline: 2px solid #fa754a;
    border: none;
    box-shadow: none;
  }
`;

export const FormTextareaContainer = styled.div`
  display: flex;
  height: 176px;
  grid-column: span 6;

  @media (max-width: 900px) {
    height: 294px;
  }
`;

export const FormTextarea = styled.textarea`
  flex: 1;
  height: 120px;
  padding: 24px;
  border: 1px solid #5b28b5;
  background: #fcfaff;
  border-radius: 4px;
  font-size: 18px;
  line-height: 22.5px;
  font-weight: 700;
  font-family: Roboto Flex, monospace;
  color: #250759;
  resize: none;

  &::placeholder {
    color: #17171780;
    font-size: 18px;
    line-height: 22.5px;
    font-weight: 500;
    font-style: italic;
    font-family: Roboto Mono, monospace;
  }

  &:hover {
    border-width: 2px;
    border-color: #fa754a;
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 4px 4px 0px 0px #fa754a;
  }

  &:focus {
    outline: 2px solid #fa754a;
    border: none;
    box-shadow: none;
  }

  @media (max-width: 900px) {
    height: 240px;
  }
`;

export const ButtonContainer = styled.div``;

export const SuccessMessage = styled.div`
  color: #000;
  font-weight: bold;
  padding: 30px 35px;
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-inline: 15px;
  background: #c1ef2c;
`;

export const PrivacyPolicyContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 8px;
  margin-top: 32px;
  margin-bottom: 56px;
`;

export const CheckboxContainer = styled.div`
  width: 24px;
`;

export const Checkbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border: ${({ $isChecked }) => ($isChecked ? "none" : "1px solid #250759")};
  cursor: pointer;
  border-radius: 4px;
  background-color: ${({ $isChecked }) => ($isChecked ? "#53B224" : "#fcfaff")};
  margin: 0;

  &:hover {
    background-color: #fcfaff;
    border: ${({ $isChecked }) =>
      $isChecked ? "2px solid #FA754A" : "2px solid #53b224"};
    box-shadow: ${({ $isChecked }) =>
      $isChecked ? "2px 2px 0px 0px #FA754A" : "4px 4px 0px 0px #53B224"};
  }

  &:hover svg path {
    fill: #fa754a;
  }
`;

export const CheckSvgPath = styled.path`
  fill: #fcfaff;
  width: 100%;
  height: 100%;
`;

export const PrivacyPolicyText = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  font-family: Roboto Flex, sans-serif;
  width: 100%;
  color: #171717;
  margin: 0;
`;
