import { useState } from "react";
import * as S from "./style";
import Link from "../../Link";
import green from "../../../imgs/blog/green-outline.svg";
import violet from "../../../imgs/blog/violet-outline.svg";

export default function BlogItem({ article, content, isPreview = false }) {
  const [outline, setOutline] = useState(violet);

  const blogContent = (
    <S.BlogItemContainer>
      <S.BlogItemInnerContainer>
        <S.BlogItemTextContainer>
          <S.BlogItemTitle>{article.header}</S.BlogItemTitle>
          <S.BlogItemDescription>{content}</S.BlogItemDescription>
        </S.BlogItemTextContainer>
        <Link href={"/blog/" + article.id} readonly={isPreview}>
          READ ARTICLE
        </Link>
      </S.BlogItemInnerContainer>
      <S.BlogItemWrapper>
        <S.BlogItemOutline loading="lazy" src={outline} alt="blogItemOutline" />
        <S.BlogItemImageContainer
          $mask={outline}
          loading="lazy"
          src={article.thumb}
          alt="blogItemImage"
        />
      </S.BlogItemWrapper>
    </S.BlogItemContainer>
  );
  return isPreview ? (
    <S.BlogWrapper $isPreview={isPreview}>{blogContent}</S.BlogWrapper>
  ) : (
    <S.CardLink
      to={"/blog/" + article.id}
      onMouseEnter={() => setOutline(green)}
      onMouseLeave={() => setOutline(violet)}
    >
      {blogContent}
    </S.CardLink>
  );
}
