import * as S from "./style";

export default function SliderItem({
  currentSlide,
  member,
  onClick,
  isHovered,
  ...otherProps
}) {
  return (
    <S.SliderItemContainer {...otherProps}>
      <S.SliderItem $currentSlide={currentSlide} onClick={onClick}>
        <S.SliderItemImage
          loading="lazy"
          src={member.thumb}
          alt="SliderItemImage"
          $currentSlide={currentSlide}
          $onHover={isHovered}
        />
      </S.SliderItem>
    </S.SliderItemContainer>
  );
}
