import styled from "styled-components";

export const HeartElectricContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 1865px;
  width: 100vw;
  margin-left: 50%;
  transform: translate(-50%, -30%);
  overflow: hidden;
  background-image: url(${(props) => props.$image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 40px;

  @media (max-width: 900px) {
    height: 800px;
    margin-top: 50px;
    transform: translate(-50%, -15%);
  }
`;

export const HeroesImage = styled.img`
  z-index: 10;
  width: ${(props) => props.big || "80%"};
  max-width: 1700px;
  margin-top: 56px;
  margin-bottom: 2rem;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const MobileHeroesImage = styled.img`
  padding-top: 60px;
  padding-bottom: 20px;
  width: 100%;

  @media (min-width: 900px) {
    display: none;
  }
`;

export const SurgesContainer = styled.div`
  // position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -907px;
  overflow: hidden;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const SurgeFrameImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
`;

export const SurgeImage = styled.img`
  height: 370px;
  flex-grow: 1;
  // object-fit: cover;
  position: relative;
`;

export const VideoFrame = styled.div`
  position: absolute;
  width: 840px;
  height: 481px;
`;

export const IFrame = styled.iframe`
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 2;
  width: 800px;
  height: 441px;
`;

export const MobileIFrame = styled.iframe`
  display: none;

  @media (max-width: 900px) {
    display: block;
    width: 100%;
    aspect-ratio: 16 / 9;
    margin-top: -121px;
  }
`;
