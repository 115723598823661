import * as S from "./style";

export default function CTA(props) {
  const { children, href, onClick, callback, disabled, red, blue, pink } = props;

  const onClickHandler = () => {
    if (callback) callback();
    if (onClick) onClick();
  };

  return (
    <S.CTA
      href={href}
      onClick={onClickHandler}
      disabled={disabled}
      $red={red}
      $blue={blue}
      $pink={pink}
    >
      {children}
    </S.CTA>
  );
}
