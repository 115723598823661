import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useContext } from "react";
import { Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "./utils/Theme";
import { AuthContext } from "./utils/AuthContext";

// Components
import Menu from "./components/Menu";
import AdminNavigation from "./components/Admin/Navigation";
import Footer from "./components/Footer";

// Pages
import LandingPage from "./pages/LandingPage";
import AboutUs from "./pages/AboutUs";
import HeartElectric from "./pages/HeartElectric";
import Team from "./pages/TeamPage";
import BlogPage from "./pages/BlogPage";
import BlogArticle from "./pages/BlogArticle";
import CareersPage from "./pages/CareersPage";
import SingleCareerPage from "./pages/SingleCareerPage";
import TermsConditions from "./pages/TermsConditions";
import PressKitPage from "./pages/PressKitPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Login from "./pages/Login";

// Admin Pages
import AdminNews from "./pages/Admin/News/index";
import AdminNewsEdit from "./pages/Admin/News/Edit";
import AdminAboutUsEdit from "./pages/Admin/AboutUs/Edit";
import UserAccount from "./pages/Admin/User/Account";
import Authors from "./pages/Admin/Authors";
import AuthorsNew from "./pages/Admin/Authors/New";
import AuthorsEdit from "./pages/Admin/Authors/Edit";
import AdminTeam from "./pages/Admin/Team";
import TeamNew from "./pages/Admin/Team/New";
import TeamEdit from "./pages/Admin/Team/Edit";
import JobEdit from "./pages/Admin/Job/Edit";
import AdminPressKit from "./pages/Admin/PressKit";
import AdminPressKitEdit from "./pages/Admin/PressKit/Edit";
import GameEdit from "./pages/Admin/Game/Edit";

function App() {
  return (
    <HelmetProvider>
      <Provider color="red">
        <Routes>
          <Route element={<Root />}>
            <Route path="/" element={<LandingPage title="Home" />} />
            <Route path="/about" element={<AboutUs title="About Us" />} />
            <Route
              path="/heartelectric"
              element={<HeartElectric title="Heart Electric" />}
            />
            <Route path="/team" element={<Team title="The Team" />} />
            <Route path="/careers" element={<CareersPage title="Careers" />} />
            <Route
              path="/careers/:id"
              element={<SingleCareerPage title="Careers" />}
            />
            <Route path="/blog" element={<BlogPage title="Blog" />} />
            <Route path="/blog/:id" element={<BlogArticle title="Blog" />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsConditions title="Terms and Conditions" />}
            />
            <Route path="/press" element={<PressKitPage title="Press Kit" />} />
            <Route
              path="/privacy"
              element={<PrivacyPolicy title="Privacy Policy" />}
            />
            <Route path="login" element={<Login title="Login" />} />
          </Route>
          <Route path="/narnia" element={<Admin />}>
            <Route index element={<AdminNews />} />
            <Route path="news/edit/:idx" element={<AdminNewsEdit />} />
            <Route path="aboutus/edit" element={<AdminAboutUsEdit />} />
            <Route path="account" element={<UserAccount />} />
            <Route index path="authors" element={<Authors />} />
            <Route path="authors/new" element={<AuthorsNew />} />
            <Route path="authors/edit/:idx" element={<AuthorsEdit />} />
            <Route index path="team" element={<AdminTeam />} />
            <Route path="team/new" element={<TeamNew />} />
            <Route path="team/edit/:idx" element={<TeamEdit />} />
            <Route path="job/edit" element={<JobEdit />} />
            <Route path="game/edit" element={<GameEdit />} />
            <Route path="presskit" element={<AdminPressKit />} />
            <Route path="presskit/edit/:idx" element={<AdminPressKitEdit />} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Provider>
    </HelmetProvider>
  );
}

function Root() {
  const { user } = useContext(AuthContext);
  let location = useLocation();

  const hasBg = location.pathname.match(
    /^\/(blog|careers)\/\d+$|^\/(heartelectric|careers|terms-and-conditions|privacy|press)$/
  );

  if (user) return <Navigate to="/narnia" />;
  else
    return (
      <>
        <Menu />
        <Outlet />
        <Footer hasBg={hasBg} />
      </>
    );
}

function Admin() {
  const { user } = useContext(AuthContext);
  let location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <div
      style={{
        minHeight: "100VH",
        backgroundColor: "rgb(242, 237, 237)",
        paddingBottom: "2rem",
        fontFamily: "Figtree, sans-serif",
      }}
    >
      <AdminNavigation />
      <Outlet />
    </div>
  );
}

export default App;
