import styled from "styled-components";

export const BlogArticleContainer = styled.div`
  background-color: #f2eded;
`;

export const StyledSvg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;
