import styled from "styled-components";

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 500px 500px;
  gap: 24px;
  margin-top: 80px;
  margin-bottom: 200px;

  @media (max-width: 900px) {
    grid-template-columns: 300px;
    justify-content: center;
    margin-bottom: 110px;
  }
`;
