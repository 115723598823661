import * as S from "./style";
import * as GS from "../../styles/global-style";
import withTracker from "../../hoc/withTracker";
import HeadSection from "../../components/CareersPage/HeadSection";
import VacanciesSection from "../../components/CareersPage/VacanciesSection";
import BenefitsSection from "../../components/CareersPage/BenefitsSection";

function CareersPage() {
  return (
    <GS.HeaderFooterSpace>
      <S.CareersPageContainer>
        <HeadSection />
        <VacanciesSection />
        <S.ModoyoMotto>feel the pulse beat the odds</S.ModoyoMotto>
      </S.CareersPageContainer>
      <BenefitsSection />
    </GS.HeaderFooterSpace>
  );
}

export default withTracker(CareersPage);
