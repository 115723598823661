import withTracker from "../../hoc/withTracker";
import * as S from "./style";
import * as GS from "../../styles/global-style";
import ContentSection from "../../components/AboutUs/ContentSection";
import PartnersSection from "../../components/AboutUs/PartnersSection";

function AboutUsPage() {
  return (
    <GS.HeaderFooterSpace $disabledFooterSpace>
      <S.AboutPageContainer>
        <ContentSection />
        <PartnersSection />
      </S.AboutPageContainer>
    </GS.HeaderFooterSpace>
  );
}

export default withTracker(AboutUsPage);
