import { useState, useContext } from "react";
import Header from "../Header";
import { AuthContext } from "../../utils/AuthContext";

import HamburgerIcon from "../HamburgerIcon";

export default function Menu() {
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  const toggleMobileMenu = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <>
      <Header open={open} toggleMobileMenu={toggleMobileMenu} />
      {!user && !open && <HamburgerIcon onClick={toggleMobileMenu} />}
    </>
  );
}
