export const HeaderItems = [
  {
    text: "ABOUT US",
    href: "/about",
  },
  {
    text: "GAME",
    href: "/heartelectric",
  },
  {
    text: "TEAM",
    href: "/team",
  },
  {
    text: "BLOG",
    href: "/blog",
  },
  {
    text: "CAREERS",
    href: "/careers",
  },
];
