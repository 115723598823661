import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as S from "./style";
import * as GS from "../../../styles/global-style";
import VacancyItem from "../VacancyItem";
import LoadSpinner from "../../../components/LoadSpinner";

export default function VacanciesSection() {
  const [jobs, setJobs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState(null);

  const toggleActive = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const { id } = useParams();

  useEffect(() => {
    const fetchJobs = async () => {
      const res = await fetch("/.netlify/functions/getjobs", {
        method: "GET",
      });

      const data = await res.json();
      var _jobs = [];

      for (var x = 0; x < data.data.length; x++) {
        const _body = data.data[x].attributes.body;
        const _arr = _body.split(">");
        var resStr = "";

        for (var i = 0; i < _arr.length; i++) {
          const str = _arr[i].trim();
          if (
            str.charAt(0) !== "<" &&
            _arr[i].split("<")[0].trim() !== "About the role"
          ) {
            resStr += " " + _arr[i].split("<")[0].trim();
          }
        }

        const locationId =
          data.data[x].relationships.location?.data?.id || null;

        let location = "Location not specified";
        if (locationId) {
          try {
            const locationRes = await fetch(
              `/.netlify/functions/getlocation?locationId=${locationId}`,
              {
                method: "GET",
              }
            );

            const locationData = await locationRes.json();
            location = locationData.data?.attributes.country;
          } catch (locationError) {
            console.error("Failed to fetch location details", locationError);
          }
        }

        _jobs.push({
          id: data.data[x].id,
          title: data.data[x].attributes.title,
          body: resStr,
          location,
        });
      }

      if (id) {
        setJobs(_jobs.filter((job) => job.id !== id));
      } else {
        setJobs(_jobs);
      }

      setLoading(false);
    };

    fetchJobs();
  }, [id]);

  if (!jobs || loading) return <LoadSpinner white />;

  return (
    <S.Container $lg>
      {jobs.length ? (
        <>
          {id && (
            <S.CheckOpeningsText $hasJobs={jobs.length}>
              Not quite you? Check other openings
            </S.CheckOpeningsText>
          )}
          <S.VacanciesContainer>
            {!id && (
              <S.VacanciesHeader>
                {["POSITION", "LOCATION", "MODEL"].map((title, index) => (
                  <S.VacanciesHeaderText key={title} $index={index}>
                    {title}
                  </S.VacanciesHeaderText>
                ))}
              </S.VacanciesHeader>
            )}
            <S.VacanciesList>
              <S.AccordionRoot type="single" collapsible>
                {jobs.map((job, index) => (
                  <VacancyItem
                    key={index}
                    index={index}
                    job={job}
                    active={active === index}
                    toggleActive={toggleActive}
                    id={id}
                  />
                ))}
              </S.AccordionRoot>
            </S.VacanciesList>
          </S.VacanciesContainer>
          {!id && (
            <GS.FlexJustifyCenter>
              <S.WelcomeText>
                We warmly welcome applicants from all backgrounds! If this role
                excites you, we’d love for you to apply!
              </S.WelcomeText>
            </GS.FlexJustifyCenter>
          )}
        </>
      ) : (
        <S.CheckOpeningsText>
          No openings found at this time. Check back later!
        </S.CheckOpeningsText>
      )}
    </S.Container>
  );
}
