import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-inline: 2rem;

  ${(props) =>
    props.$innerWidth &&
    css`
      padding-top: ${(props) => (props.$innerWidth < 900 ? "0" : "350px")};
    `}

  ${(props) =>
    props.$sm &&
    css`
      @media (min-width: 640px) {
        max-width: 640px;
      }
    `}

  ${(props) =>
    props.$md &&
    css`
      @media (min-width: 768px) {
        max-width: 768px;
      }
    `}

  ${(props) =>
    props.$lg &&
    css`
      @media (min-width: 1024px) {
        max-width: 1024px;
      }
    `}

  ${(props) =>
    props.$xl &&
    css`
      @media (min-width: 1280px) {
        max-width: 1280px;
      }
    `}

  ${(props) =>
    props.$2xl &&
    css`
      @media (min-width: 1536px) {
        max-width: 1536px;
      }
    `}
`;

export const PartnersSectionContainer = styled.div`
  background: linear-gradient(180deg, #340b7c 0%, #1f1433 100%);
  padding-top: 80px;
  padding-bottom: 260px;

  @media (max-width: 900px) {
    padding-top: 10px;
    padding-bottom: 420px;
  }
`;

export const PartnersSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 84px;
`;

export const PartnersSectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 746px;

  @media (max-width: 900px) {
    align-items: flex-start;
    width: 100%;
  }
`;

export const PartnersSectionTitle = styled.h1`
  font-size: 56px;
  line-height: 70px;
  font-weight: 700;
  font-family: Figtree, sans-serif;
  color: #fcfaff;
  margin: 0;

  @media (max-width: 900px) {
    font-size: 32px !important;
    line-height: 40px;
    text-align: left;
  }
`;

export const PartnersSectionText = styled.p`
  font-size: 18px;
  line-height: 36px;
  font-weight: 400;
  font-family: "Roboto Flex", sans-serif;
  color: #fcfaff;
  text-align: center;
  margin: 0;

  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }
`;

export const PartnersSectionInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 900px) {
    gap: 12px;
  }
`;

export const LogoBackground = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1c122e;
  height: 200px;
  width: 200px;
  border-radius: 50%;

  @media (max-width: 900px) {
    height: 96px;
    width: 96px;
  }
`;

export const PartnerNameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 49px;
`;

export const PartnerName = styled.a`
  font-size: 14px;
  line-height: 24.5px;
  font-weight: 500;
  color: #fcfaff;
  font-family: "Roboto Mono", sans-serif;
  margin: 0;
  text-align: center;
  text-decoration: none;
  width: 85%;

  @media (max-width: 900px) {
    font-size: 12px !important;
    line-height: 15px;
    height: 45px;
    width: 96px;
  }
`;

export const SvgLondonVenture = styled.svg`
  width: 120px;
  height: 64px;

  @media (max-width: 900px) {
    width: 59px;
    height: 32px;
  }
`;

export const SvgAnthosCapital = styled.svg`
  width: 80px;
  height: 80px;

  @media (max-width: 900px) {
    width: 40px;
    height: 40px;
  }
`;

export const SvgAntler = styled.svg`
  width: 80px;
  height: 80px;

  @media (max-width: 900px) {
    width: 40px;
    height: 40px;
  }
`;
