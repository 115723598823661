import styled from "styled-components";

export const TeamContainer = styled.div`
  overflow: hidden;
  min-height: 100vh;
  background: linear-gradient(180deg, #340b7c 0%, #1f1433 100%);

  @media (max-width: 900px) {
    padding-bottom: 350px;
  }
`;

export const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: -450px;
  width: 100%;
  z-index: 0;

  @media (max-width: 900px) {
    left: 0;
  }
`;
