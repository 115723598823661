import React, { useRef, useState } from "react";
import Cropper from "react-cropper";

import { Dialog } from "@headlessui/react";

import Button from "../Button";
import BlogItem from "../BlogPage/BlogItem";

import "cropperjs/dist/cropper.css";
import "../style.css";

export const ImageCropperThumb = (props) => {
  const {
    editPost,
    thumb,
    published,
    hideCardPreview = false,
    authorId = null,
  } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedFilePath, setCroppedFilePath] = useState(null);
  const [croppedFile, setCroppedFile] = useState(null);
  const cropper = useRef(null);
  const fileInputRef = useRef(null);

  const handleClose = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setOpenDialog(false);
  };

  async function fileUploaded(xhr) {
    if (xhr.readyState === 4 && xhr.status === 200) {
      // File uploaded successfully
      let response = JSON.parse(xhr.responseText);
      editPost(null, null, published ? "on" : "off", response.url, authorId);
    }
  }

  async function onFileSubmit(files) {
    const url = `https://api.cloudinary.com/v1_1/dojgj0dvl/upload`;
    files.then((file) => {
      var xhr = new XMLHttpRequest();
      var fd = new FormData();
      xhr.open("POST", url, true);
      xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      xhr.onreadystatechange = fileUploaded.bind(this, xhr);

      fd.append("upload_preset", "ufpksy5b");
      fd.append("tags", "card"); // Optional - add tag for image admin in Cloudinary
      fd.append("multiple", true);
      fd.append("file", file);
      xhr.send(fd);
    });
  }

  function base64StringtoFile(url, filename, mimeType) {
    if (url.startsWith("data:")) {
      var arr = url.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let file = new File([u8arr], filename, { type: mime || mimeType });
      return Promise.resolve(file);
    }
    return fetch(url)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], filename, { type: mimeType }));
  }

  const confirmCroppedImage = async () => {
    onFileSubmit(croppedFile).then(() => {
      handleClose();
    });
  };

  const fileSelectHandler = async (event) => {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      setSelectedFile(newFile);
      setOpenDialog(true);
    }
  };

  const _crop = () => {
    const str = cropper.current.cropper
      .getCroppedCanvas({
        width: 1280,
        height: 720,
      })
      .toDataURL(selectedFile.type, 1);

    setCroppedFilePath(str);
    setCroppedFile(base64StringtoFile(str, selectedFile.name, "image/jpeg"));
  };

  return (
    <>
      <div className="imageContainer">
        <label className="label-image">
          {thumb ? (
            <img src={thumb} alt="Uploaded" className="image" />
          ) : (
            croppedFilePath && (
              <img src={croppedFilePath} alt="Uploaded" className="image" />
            )
          )}
          <div className="card">
            <input
              ref={fileInputRef}
              type="file"
              accept="image/png, image/jpeg, image/gif"
              onChange={fileSelectHandler}
              className="fileInput"
            />
            {thumb ? (
              <span className="change-picture">Change picture</span>
            ) : (
              <span>Upload picture</span>
            )}
          </div>
        </label>
      </div>
      <Dialog
        as="div"
        className="dialog"
        open={openDialog}
        onClose={handleClose}
      >
        <Dialog.Panel>
          <Dialog.Title className="title">
            {thumb ? (
              <div style={{ fontFamily: "Figtree, sans-serif" }}>
                Change picture
              </div>
            ) : (
              <div style={{ fontFamily: "Figtree, sans-serif" }}>
                Upload picture
              </div>
            )}
          </Dialog.Title>
          <div style={{ display: "flex" }}>
            {selectedFile && (
              <Cropper
                ref={cropper}
                style={{ height: 400, marginBottom: 30 }}
                aspectRatio={1}
                src={selectedFile?.preview}
                preview=".img-preview"
                guides={false}
                crop={_crop}
                checkOrientation={false}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                responsive={true}
                autoCrop={1}
              />
            )}
            {!hideCardPreview && croppedFilePath && (
              <BlogItem
                article={{
                  header: "Preview News Card",
                  thumb: croppedFilePath,
                }}
                content={
                  "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old"
                }
                isPreview={true}
              />
            )}
          </div>

          <Button text="Confirm Image" callback={confirmCroppedImage} red />
          <Button text="Cancel" callback={handleClose} />
        </Dialog.Panel>
      </Dialog>
    </>
  );
};
