import styled from "styled-components";

export const TeamPlayContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 879px;
  width: 100vw;
  margin-left: 50%;
  margin-top: -420px;
  transform: translateX(-50%);
  overflow: hidden;
  background: radial-gradient(circle, #fff7e6 0%, #fff7e600 100%),
    url(${(props) => props.$image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 401px) and (max-width: 900px) {
    height: 760px;
    transform: translate(-50%, -48%);
    margin-top: 0;
  }

  @media (max-width: 400px) {
    height: 720px;
    margin-top: 0;
    transform: translate(-50%, -46%);
  }
`;

export const MobileTeamPlayVideo = styled.video`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 50%;
  transform: translateX(-50%);
  height: auto;
  width: 100vw;
  overflow: hidden;
  z-index: 10;

  @media (max-width: 900px) {
    margin-top: -90%;
    height: 363px;
    object-fit: cover;
  }
`;

export const TeamPlayHead = styled.div`
  color: #ab6152;
  font-size: 32px;
  line-height: 27.2px;
  font-family: "Sofia Sans Extra Condensed", sans-serif;
  font-weight: 700;

  @media (max-width: 900px) {
    font-size: 20px !important;
    line-height: 17px;
    color: #d27e00;
    margin-top: -20px;
  }
`;

export const TeamPlayTitle = styled.h1`
  max-width: 1005px;
  color: #e7498c;
  font-size: 104px;
  font-weight: 800;
  text-align: center;
  line-height: 88px;
  font-family: Montserrat, sans-serif;
  margin-top: 20px;

  @media (max-width: 900px) {
    font-size: 44px !important;
    line-height: 37px;
    margin: 10px;
    max-width: 295px;
  }
`;

export const TeamPlayParagraphContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 50px;

  @media (max-width: 900px) {
    flex-direction: column;
    margin-top: 0;
    max-width: 295px;
    gap: 5px;
  }
`;

export const TeamPlayParagraph = styled.p`
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  font-family: Montserrat, sans-serif;
  color: #240016;
  max-width: 490px;

  @media (max-width: 900px) {
    text-align: center;
    font-size: 18px !important;
    line-height: 21px;
  }
`;

export const ButtonContainer = styled.div`
  display: none;

  @media (max-width: 900px) {
    display: block;
    padding-top: 20px;
  }
`;
