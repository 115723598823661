import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 120px;
  padding-inline: 2rem;

  @media (max-width: 900px) {
    padding-top: 50px;
  }

  ${(props) =>
    props.$innerWidth &&
    css`
      padding-top: ${(props) => (props.$innerWidth < 900 ? "0" : "350px")};
    `}
  text-align : left;

  ${(props) =>
    props.$sm &&
    css`
      @media (min-width: 640px) {
        max-width: 640px;
      }
    `}

  ${(props) =>
    props.$md &&
    css`
      @media (min-width: 768px) {
        max-width: 768px;
      }
    `}

  ${(props) =>
    props.$lg &&
    css`
      @media (min-width: 1024px) {
        max-width: 1024px;
      }
    `}

  ${(props) =>
    props.$xl &&
    css`
      @media (min-width: 1280px) {
        max-width: 1280px;
      }
    `}

  ${(props) =>
    props["2xl"] &&
    css`
      @media (min-width: 1536px) {
        max-width: 1536px;
      }
    `}
`;

export const HeroImage = styled.img`
  width: 100%;
  height: 600px;
  object-fit: cover;
  position: relative;
  z-index: 1;

  @media (max-width: 900px) {
    height: 300px;
    object-fit: cover;
  }
`;

export const StyledSvg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const HeadSectionInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  gap: 48px;
  z-index: 1;
`;

export const HeadSectionTitle = styled.h1`
  font-size: 56px;
  line-height: 70px;
  font-weight: 700;
  font-family: Figtree, sans-serif;
  color: #f2eded;
  text-align: center;
  max-width: 500px;

  @media (max-width: 900px) {
    font-size: 32px !important;
    line-height: 40px;
    max-width: 235px;
  }
`;

export const HeadSectionText = styled.span`
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  font-family: "Roboto Mono", sans-serif;
  color: ${({ $green }) => ($green ? "#98D977" : "#f2eded")};
  text-align: center;
  max-width: 748px;

  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 24px;
    max-width: 295px;
  }
`;
