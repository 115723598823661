import {useContext, useState, useLayoutEffect} from 'react';
import {Theme} from "../utils/Theme";

const useTeamResults = () => {
  const {setColor} = useContext(Theme)

  const [data, setData] = useState()

  useLayoutEffect(() => {
    // Set the color
    setColor('green')

    // Set the background gradient
    document.body.style =
      'background: conic-gradient(from 142.55deg at 50% 50%, #A912EA -65.51deg, #E013BA 100.73deg, #A912EA 294.49deg, #E013BA 460.73deg)';

    // Fetch data
    getData()

  }, [setColor])

  function getData() {
    fetch('/.netlify/functions/getteam', {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((_data) => {
        setData(_data.filter((item) => item));
      })
  }

  return [data]
}

export default useTeamResults;
