import * as S from "./style";
import logo from "../../imgs/Logo_White.png";

export default function Logo() {
  return (
    <S.LogoLink to="/">
      <S.LogoImage src={logo} alt="logo" />
    </S.LogoLink>
  );
}
