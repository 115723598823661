import styled from "styled-components";
import { Link } from "react-router-dom";

export const CardLink = styled(Link)`
  display: none;

  @media (max-width: 900px) {
    text-decoration: none;
    display: flex;
    border-radius: 31px 8px 8px 8px;
  }
`;

export const MobileBlogItemContainer = styled.div`
  display: none;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    background-color: #fcfaff;
    border-radius: 34px 10px 8px 8px;
    position: relative;
    // overflow: hidden;
  }
`;

export const MobileBlogItemHeader = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 22px;
  padding: 6px 0;
`;

export const StyledSvg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const MobileBlogItemImageContainer = styled.img`
  position: relative;
  left: 10px;
  display: flex;
  align-self: flex-start;
  width: 77px;
  height: 77px;
  -webkit-mask-image: url(${({ $mask }) => $mask});
  mask-image: url(${({ $mask }) => $mask});
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  mask-size: cover;
  object-fit: cover;
  overflow: hidden;
  z-index: 1;
`;

export const MobileBlogItemOutline = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  border-top-right-radius: 8px;
`;

export const MobileBlogItemTitle = styled.h2`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 18px !important;
  font-weight: 700;
  line-height: 22.5px;
  font-family: Figtree, sans-serif;
  color: #fcfaff;
  margin: 0;
  width: 50%;
  z-index: 1;
  text-align: left;
`;

export const MobileBlogItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  gap: 8px;
`;

export const MobileBlogItemText = styled.p`
  display: -webkit-box;
  font-size: 16px;
  line-height: 20px;
  font-family: "Roboto Flex", sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  z-index: 10;
  margin: 0;
  font-weight: 400;
  color: #171717;
  text-align: left;
`;
