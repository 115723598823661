import { useState, useEffect } from "react";
import { format } from "date-fns";
import * as S from "./style";
import serialize from "../../../utils/serializeItem";
import parse from "html-react-parser";
import MobileContainer from "./MobileContainer";
import LoadSpinner from "../../../components/LoadSpinner";

export default function ContentSection() {
  const [data, setData] = useState([]);
  const [latestUpdate, setLatestUpdate] = useState(null);

  useEffect(() => {
    fetch("/.netlify/functions/get-presskits", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((_data) => {
        setData(_data);

        const latestElement = _data.reduce((latest, current) => {
          return current.updated_at > latest.updated_at ? current : latest;
        });
        setLatestUpdate(
          format(new Date(latestElement.updated_at), "MMMM DD, YYYY")
        );
      });
  }, []);

  if (!data.length) return <LoadSpinner />;

  return (
    <S.Container $xl>
      <S.Title>Press Kit</S.Title>
      <S.LasUpdatedText>Last updated: {latestUpdate}</S.LasUpdatedText>
      <S.ContentSectionContainer>
        <S.NavigationList>
          {data.map((item) => (
            <S.NavigationItem key={item.id}>
              <S.BulletPoint>•</S.BulletPoint>
              <S.NavigationTitle href={`#${item.link}`}>
                {item.title}
              </S.NavigationTitle>
            </S.NavigationItem>
          ))}
        </S.NavigationList>
        <S.DescriptionContainer>
          {data.map((item) => (
            <S.DescriptionItem key={item.id}>
              <S.DescriptionTitle id={item.link}>
                {item.title}
              </S.DescriptionTitle>
              <S.DescriptionText>
                {item &&
                  parse(serialize({ children: JSON.parse(item.content) }))}
              </S.DescriptionText>
            </S.DescriptionItem>
          ))}
        </S.DescriptionContainer>
      </S.ContentSectionContainer>
      <MobileContainer data={data} />
    </S.Container>
  );
}
