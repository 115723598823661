import * as GS from "../../styles/global-style";
import withTracker from "../../hoc/withTracker";
import HeroSection from "../../components/HeartElectric/HeroSection";
import CharactersSection from "../../components/HeartElectric/CharactersSection";
import TeamPlaySection from "../../components/HeartElectric/TeamPlaySection";
import SocialsSection from "../../components/HeartElectric/SocialsSection";

function HeartElectric() {
  return (
    <GS.HeaderFooterSpace>
      <div
        style={{
          width: "100vw",
          transform: "translateX(-50%)",
          marginLeft: "50%",
          backgroundColor: "#FAF8F5",
          overflow: "hidden",
        }}
      >
        <HeroSection />
        <CharactersSection />
        <TeamPlaySection />
        <SocialsSection />
      </div>
    </GS.HeaderFooterSpace>
  );
}

export default withTracker(HeartElectric);
