import * as S from "./style";

export default function Link({ children, href, readonly = false }) {
  return readonly ? (
    <S.LinkContainerReadonly>{children}</S.LinkContainerReadonly>
  ) : (
    <S.LinkContainer to={href} rel="noreferrer">
      {children}
    </S.LinkContainer>
  );
}
