import * as S from "./style";

export default function NavigationItem(props) {
  const { secondary, text, href, active, disabled, onClick } = props;

  return (
    <S.NavigationItem
      to={href}
      $secondary={secondary}
      $active={active}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </S.NavigationItem>
  );
}
