import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const styles = css`
  border-radius: 16px;
  padding: 12px 28px;
  font-family: 'Roboto Mono', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  border: none;
  color: ${(props) => (props.disabled ? "#FCFAFF80" : "#fcfaff")};
  background-color: ${(props) => (props.disabled ? "#7F7E80" : "#5B28B5")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  ${(props) =>
    !props.disabled &&
    `&:hover {
background-color: #fcfaff;
box-shadow: 4px 4px 0px 0px #E545CB;
color: #1c122e;
font-style: italic;
}`}
  ${(props) =>
    !props.disabled &&
    `&:active {
background-color: #E545CB;
box-shadow: none;
color: #fcfaff;
}`}
`;

const StyledLink = styled(({ disabled, ...props }) => (
  <Link {...props} onClick={disabled ? (e) => e.preventDefault() : undefined} />
))`
  ${styles}
`;

const StyledButton = styled.button`
  ${styles}
`;

export const Button = ({ href, onClick, text, disabled }) => {
  return href ? (
    <StyledLink to={href} disabled={disabled}>
      {text}
    </StyledLink>
  ) : (
    <StyledButton onClick={onClick} disabled={disabled}>
      {text}
    </StyledButton>
  );
};
