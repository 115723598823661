import * as S from "./style";

export default function HamburgerIcon({ onClick }) {
  return (
    <S.HamburgerIconContainer onClick={onClick}>
      <S.HamburgerIcon
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.80078 10C4.80078 9.33731 5.33804 8.80005 6.00078 8.80005H26.0008C26.6635 8.80005 27.2008 9.33731 27.2008 10C27.2008 10.6628 26.6635 11.2 26.0008 11.2H6.00078C5.33804 11.2 4.80078 10.6628 4.80078 10Z"
          fill="#FCFAFF"
        />
        <path
          d="M4.80078 22.8C4.80078 22.1373 5.33804 21.6 6.00078 21.6H26.0008C26.6635 21.6 27.2008 22.1373 27.2008 22.8C27.2008 23.4628 26.6635 24 26.0008 24H6.00078C5.33804 24 4.80078 23.4628 4.80078 22.8Z"
          fill="#FCFAFF"
        />
        <path
          d="M4.80078 16.4C4.80078 15.7373 5.33804 15.2 6.00078 15.2H26.0008C26.6635 15.2 27.2008 15.7373 27.2008 16.4C27.2008 17.0628 26.6635 17.6 26.0008 17.6H6.00078C5.33804 17.6 4.80078 17.0628 4.80078 16.4Z"
          fill="#FCFAFF"
        />
      </S.HamburgerIcon>
    </S.HamburgerIconContainer>
  );
}
