import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-block: 5rem;
  padding-inline: 2rem;

  @media (max-width: 900px) {
    padding-top: 2.5rem;
    padding-bottom: 0;
  }
  ${(props) =>
    props.$innerWidth &&
    css`
      padding-top: ${(props) => (props.$innerWidth < 900 ? "0" : "350px")};
    `}
  text-align : left;

  ${(props) =>
    props.$sm &&
    css`
      @media (min-width: 640px) {
        max-width: 640px;
      }
    `}

  ${(props) =>
    props.$md &&
    css`
      @media (min-width: 768px) {
        max-width: 768px;
      }
    `}

  ${(props) =>
    props.$lg &&
    css`
      @media (min-width: 1024px) {
        max-width: 1024px;
      }
    `}

  ${(props) =>
    props.$xl &&
    css`
      @media (min-width: 1280px) {
        max-width: 1280px;
      }
    `}

  ${(props) =>
    props["2xl"] &&
    css`
      @media (min-width: 1536px) {
        max-width: 1536px;
      }
    `}
`;

export const BreadcrumbContainer = styled.div`
  margin-inline: auto;
  ${({ $fixedWidth }) => $fixedWidth && `max-width: 1024px;`}
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
  z-index: 1;
`;

export const BreadcrumbText = styled.div`
  font-size: 56px;
  line-height: 70px;
  font-weight: 700;
  font-family: Figtree, sans-serif;
  color: #1c122e;

  @media (max-width: 900px) {
    font-size: 40px;
    line-height: 50px;
  }
`;
