import React, {useContext, useLayoutEffect, useState} from 'react'
import {Container, FullHeight, Title} from "../../../styles/global-style";

import {Link, useNavigate} from "react-router-dom";
import {Theme} from "../../../utils/Theme";
import {
  Actions,
  Content,
  HeaderAction, Heading, Icon,
  Image,
  ImagePlaceholder, LinkedinUrl,
  Media,
  MediaContainer,
  NewsItem, Paragraph
} from "../../../components/Admin/News/style";
import Button from "../../../components/Button";
import {ButtonContainer, FixedOverlay, FlexContainer, Modal, ModalPanel, ModalTitle} from "../../../styles/modal";

function Team() {
  const {setColor} = useContext(Theme);
  const navigate = useNavigate();

  const [data, setData] = useState();
  const [teamId, setTeamId] = useState(null);
  const [isOpenDeleteArticle, setIsOpenDeleteArticle] = useState(false)


  useLayoutEffect(() => {
    setColor("red");
    getData();
  }, [setColor]);


  function getData() {
    fetch("/.netlify/functions/getteam", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((_data) => {
        setData(_data.filter((item) => item));
      });
  }


  const deleteTeam = (teamId) => {
    setIsOpenDeleteArticle(true)
    setTeamId(teamId)
  }

  const confirmDeleteTeam = (teamId) => {
    fetch("/.netlify/functions/deleteteam", {
      method: "DELETE",
      body: JSON.stringify({id: teamId}),
    }).then(() => {
      getData();
      setIsOpenDeleteArticle(false)
    });
  }

  function extractUsernameFromLinkedInURL(profileUrl) {
    let username = profileUrl.match(/\/in\/([^/]+)/);
    if (username && username.length > 1) {
      return `@${username[1]}`;
    }
    return null;
  }

  return (
    <FullHeight>
      <Container>
        <HeaderAction>
          <Title>Team</Title>
          <Button text="Add team" black callback={() => navigate('/narnia/team/new')}/>
        </HeaderAction>
        <div>
          {data && data.length > 0 ?
            data.map((item, i) => {
                return (
                  <NewsItem
                    key={`team-${i}`}
                    $published
                  >
                    <MediaContainer>
                      <Media>
                        {item.thumb ? <Image size="5rem" src={item.thumb}/> :
                          <ImagePlaceholder size="5rem">No Image</ImagePlaceholder>}
                        <Content>
                          <Heading>
                            <Link to={`/narnia/team/edit/${item.id}`}>
                              {item.full_name || <span>No Title</span>}
                            </Link>
                          </Heading>
                          <Paragraph $noHeight>
                            {item.job_title}
                          </Paragraph>
                          <LinkedinUrl
                            href={item.linkedin_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {extractUsernameFromLinkedInURL(item.linkedin_url)}
                          </LinkedinUrl>
                        </Content>
                        <Actions>
                          <Icon
                            className="fas fa-trash"
                            $delete
                            onClick={() => deleteTeam(item.id)}
                          />
                        </Actions>
                      </Media>
                    </MediaContainer>
                  </NewsItem>
                );
              })
            :
            <div style={{textAlign: 'center'}}>No Data</div>
          }
        </div>
      </Container>

      <Modal open={isOpenDeleteArticle}
             onClose={() => setIsOpenDeleteArticle(false)}>
        <FixedOverlay>
          <FlexContainer>
            <ModalPanel>
              <ModalTitle>
                Delete team
              </ModalTitle>
              <div>
                Are you sure you want to delete this team?
              </div>
              <ButtonContainer>
                <Button text="Delete" callback={() => confirmDeleteTeam(teamId)} black/>
                <Button text="Cancel" callback={() => setIsOpenDeleteArticle(false)}/>
              </ButtonContainer>
            </ModalPanel>
          </FlexContainer>
        </FixedOverlay>
      </Modal>
    </FullHeight>

  )
}


export default Team