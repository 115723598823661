import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const Theme = createContext("red");

export const Provider = (props) => {
  // Initial values are obtained from the props
  const { color: initialColor, children } = props;

  // Use State to keep the values
  const [color, setColor] = useState(initialColor);

  // Make the context object:
  const theme = {
    color,
    setColor,
  };

  // pass the value in provider and return
  return <Theme.Provider value={theme}>{children}</Theme.Provider>;
};

export const { Consumer } = Theme;

Provider.propTypes = {
  color: PropTypes.string,
};

Provider.defaultProps = {
  color: "red",
};
