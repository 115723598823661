import * as S from "./style";
import { partners } from "./PartnersData";

export default function PartnersSection() {
  return (
    <S.PartnersSectionContainer>
      <S.Container>
        <S.PartnersSectionWrapper>
          <S.PartnersSectionHeader>
            <S.PartnersSectionTitle>Partners</S.PartnersSectionTitle>
            <S.PartnersSectionText>
              Modoyo is incredibly thankful for the invaluable experience,
              network and expertise provided by our partners at London Venture
              Partners, Anthos Capital and Antler.
            </S.PartnersSectionText>
          </S.PartnersSectionHeader>
          <S.PartnersSectionInnerContainer>
            {partners.map((partner) => (
              <S.LogoContainer key={partner.name}>
                <S.LogoBackground
                  href={partner.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  {partner.logo}
                </S.LogoBackground>
                <S.PartnerNameContainer>
                  <S.PartnerName
                    href={partner.href}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {partner.name}
                  </S.PartnerName>
                </S.PartnerNameContainer>
              </S.LogoContainer>
            ))}
          </S.PartnersSectionInnerContainer>
        </S.PartnersSectionWrapper>
      </S.Container>
    </S.PartnersSectionContainer>
  );
}
