import * as S from "./style";
import CTA from "../../CTA";
import HeartElectricBackground from "../../../imgs/heartelectric/HeartElectric_Background.webp";
import Heroes from "../../../imgs/heartelectric/Heroes.webp";
import HeroesMobile from "../../../imgs/heartelectric/HeroesMobile.webp";
import SurgeLeft from "../../../imgs/heartelectric/Surge_Left.png";
import SurgeRight from "../../../imgs/heartelectric/Surge_Right.png";
import SurgeFrame from "../../../imgs/heartelectric/Surge_Frame.png";

function HeroSection() {
  return (
    <>
      <S.HeartElectricContainer $image={HeartElectricBackground}>
        <S.HeroesImage loading="lazy" src={Heroes} alt="Heroes" />
        <S.MobileHeroesImage loading="lazy" src={HeroesMobile} alt="HeroesMobile" />
        <CTA
          blue
          callback={() =>
            window.open("https://bit.ly/HEWebsiteSteam", "_blank")
          }
        >
          GET PLAYTEST ACCESS
        </CTA>
      </S.HeartElectricContainer>
      <S.SurgesContainer>
        <S.SurgeImage loading="lazy" src={SurgeLeft} alt="SurgeLeft" />
        <div style={{ width: "800px", height: "481px" }} />
        <S.VideoFrame>
          <S.IFrame
            loading="lazy"
            src="https://www.youtube.com/embed/ArvFgnfzOMI?si=Z8cwsWWhVPJP7oCc"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
          <S.SurgeFrameImage loading="lazy" src={SurgeFrame} alt="SurgeFrame" />
        </S.VideoFrame>
        <S.SurgeImage loading="lazy" src={SurgeRight} alt="SurgeRight" />
      </S.SurgesContainer>
      <S.MobileIFrame
        loading="lazy"
        src="https://www.youtube.com/embed/ArvFgnfzOMI?si=Z8cwsWWhVPJP7oCc"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      />
    </>
  );
}

export default HeroSection;
