import styled from "styled-components";

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;

  @media (max-width: 900px) {
    padding: 0;
  }
`;

export const GridHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  padding-top: 16px;
  border-top: 1px solid #7f7e80;
`;

export const GridHeaderTitle = styled.h1`
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
  font-family: Figtree, sans-serif;
  color: #e545cb;
  text-transform: uppercase;
  transform: translateX(0);

  @media (max-width: 900px) {
    font-size: 18px !important;
    line-height: 16px;
    text-align: left;
  }
`;

export const GridBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GridBodyRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
  padding-block: 16px;
  border-top: 1px solid #7f7e80;

  &:last-child {
    border-bottom: 1px solid #7f7e80;
  }
`;

export const GridBodyItem = styled.div`
  flex: 1;
`;

export const GridBodyText = styled.p`
  flex: 1;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  font-family: "Roboto Flex", sans-serif;
  color: #000000;
  margin: 0;
  white-space: pre-line;

  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }
`;
