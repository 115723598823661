import styled from "styled-components";

export const DescriptionContainer = styled.div`
  width: 100%;
  max-width: 650px;
  margin-top: 50px;
  margin-bottom: 300px;
  z-index: 1;

  @media (max-width: 900px) {
    width: 100%;
    margin-top: 32px;
    margin-bottom: 120px;
  }
`;

export const Name = styled.h1`
  font-size: 32px !important;
  font-weight: 700;
  line-height: 40px;
  font-family: Figtree, sans-serif;
  color: #e087d2;
  text-align: center;
  margin: 0;
`;

export const Role = styled.h2`
  font-size: 18px !important;
  font-weight: 400;
  line-height: 27px;
  font-family: "Roboto Mono", sans-serif;
  color: #fcfaff;
  text-align: center;
  margin-top: 5px;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 300px;

  @media (max-width: 900px) {
    justify-content: center;
    margin-top: 20px;
    height: auto;
  }
`;

export const Text = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 31px;
  font-family: "Roboto Flex", sans-serif;
  color: #fcfaff;
  text-align: center;
  margin: 0;
  width: 70%;
`;

export const SliderNav = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 60px 0 0 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.8;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;
