import styled from "styled-components";
import { Link } from "react-router-dom";

export const LinkContainer = styled(Link)`
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  font-family: "Roboto Mono", sans-serif;
  color: #5b28b5;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 219px;

  &:hover {
    color: #1c122e;
    box-shadow: 4px 4px 0px 0px rgba(229, 69, 203, 1);
    font-style: italic;
  }

  &:active {
    color: #e545cb;
    box-shadow: none;
    font-style: italic;
  }
`;

export const LinkContainerReadonly = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  font-family: "Roboto Mono", sans-serif;
  color: #5b28b5;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
