import styled from "styled-components";

export const CareersPageContainer = styled.div`
  background: linear-gradient(180deg, #340b7c 0%, #1f1433 100%);
  width: 100%;
`;

export const ModoyoMotto = styled.h2`
  color: #301660;
  margin: 0;
  font-size: 6.3vw !important;
  line-height:4.3vw;
  font-family: Figtree, sans-serif;
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
`;
