import withTracker from "../../hoc/withTracker";
import * as S from "./style";
import * as GS from "../../styles/global-style";
import ContentSection from "../../components/TermsConditions/ContentSection";

function TermsConditions() {
  return (
    <GS.HeaderFooterSpace $disabledFooterSpace>
      <S.TermsConditionsContainer>
        <ContentSection />
      </S.TermsConditionsContainer>
    </GS.HeaderFooterSpace>
  );
}

export default withTracker(TermsConditions);
