import { useEffect, useState } from "react";
import * as S from "./style";
import LoadSpinner from "../../../components/LoadSpinner";
import Team from "../../../imgs/about-us/Team.webp";
import LongTeam from "../../../imgs/about-us/LongTeam.webp";
import Gang from "../../../imgs/about-us/Gang.webp";
import serialize from "../../../utils/serializeItem";
import parse from "html-react-parser";

export default function ContentSection() {
  const [data, setData] = useState({});

  useEffect(() => {
    const getAboutUsDetails = async () => {
      fetch("/.netlify/functions/get-aboutus-details", {
        method: "GET",
      })
        .then((res) => res.json())
        .then((_data) => {
          setData(_data);
        });
    };
    getAboutUsDetails();
  }, []);

  if (!data["about-us"]) return <LoadSpinner white={true} />;

  return (
    <S.ContentSectionContainer>
      <S.FirstStyledSvg
        width="1039"
        height="1137"
        viewBox="0 0 1039 1137"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1056.34 278.467C1017.07 214.037 967.646 159.182 909.426 115.298C851.207 71.4139 786.345 39.2988 716.208 20.1494C669.515 7.38313 621.259 1 573.004 1C549.169 1 524.748 2.59578 500.522 5.58787C431.362 14.3647 363.96 36.7056 300.27 71.6134C236.776 106.521 180.314 152.599 132.449 208.851C84.5843 264.703 48.4413 329.731 25.1925 402.14C1.94377 474.947 -4.50348 553.938 5.65564 637.119C15.6194 718.703 40.2357 792.907 78.7231 857.736C117.406 922.963 166.639 978.018 224.858 1021.1C282.687 1064.39 347.745 1096.5 417.882 1116.25C464.965 1129.42 513.612 1136 562.649 1136C586.093 1136 610.123 1134.4 633.958 1131.41C705.854 1122.24 774.427 1099.9 837.336 1064.99C900.83 1030.28 956.901 983.802 1004.38 927.351C1051.85 870.502 1087.6 805.274 1110.66 733.264C1133.9 661.055 1140.55 582.463 1130.39 499.682C1120.23 417.3 1095.42 342.896 1056.54 278.267L1056.34 278.467ZM960.418 691.774C925.837 806.671 801.193 881.074 643.14 881.074C589.414 881.074 535.102 872.497 481.181 855.541C376.073 822.429 286.399 761.39 228.57 683.596C171.132 606.2 152.182 521.624 175.235 445.226C210.01 330.33 334.459 256.126 492.512 256.126C546.043 256.126 600.55 264.703 654.276 281.658C870.548 349.678 1007.89 533.792 960.418 691.974V691.774Z"
          stroke="#5B28B5"
          strokeWidth="2"
        />
      </S.FirstStyledSvg>
      <S.SecondStyledSvg
        width="1138"
        height="1136"
        viewBox="0 0 1138 1136"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M859.033 1056.34C923.463 1017.07 978.318 967.646 1022.2 909.426C1066.09 851.207 1098.2 786.345 1117.35 716.208C1130.12 669.515 1136.5 621.259 1136.5 573.004C1136.5 549.169 1134.9 524.748 1131.91 500.522C1123.14 431.362 1100.79 363.96 1065.89 300.27C1030.98 236.776 984.901 180.314 928.649 132.449C872.797 84.5843 807.769 48.4413 735.36 25.1925C662.553 1.94375 583.562 -4.5035 500.381 5.65562C418.797 15.6194 344.593 40.2356 279.764 78.7231C214.537 117.406 159.482 166.639 116.396 224.858C73.1108 282.687 40.9957 347.745 21.2479 417.882C8.08268 464.965 1.49997 513.611 1.49997 562.649C1.49997 586.093 3.09586 610.123 6.08796 633.958C15.2637 705.854 37.6046 774.427 72.5123 837.336C107.221 900.83 153.698 956.901 210.149 1004.38C266.998 1051.85 332.226 1087.6 404.235 1110.66C476.445 1133.9 555.037 1140.55 637.818 1130.39C720.2 1120.23 794.604 1095.42 859.233 1056.54L859.033 1056.34ZM445.726 960.418C330.829 925.837 256.426 801.193 256.426 643.14C256.426 589.414 265.003 535.102 281.959 481.181C315.071 376.073 376.11 286.399 453.904 228.57C531.3 171.132 615.876 152.181 692.274 175.235C807.17 210.01 881.374 334.459 881.374 492.512C881.374 546.043 872.797 600.55 855.842 654.276C787.822 870.548 603.708 1007.89 445.526 960.418L445.726 960.418Z"
          stroke="#5B28B5"
          strokeWidth="2"
        />
      </S.SecondStyledSvg>
      <S.Container $lg>
        <S.AboutUsContainer>
          <S.Title>{data["about-us"]?.title}</S.Title>
          <S.Text>
            {data["about-us"] &&
              parse(
                serialize({ children: JSON.parse(data["about-us"].newbody) })
              )}
          </S.Text>
        </S.AboutUsContainer>
      </S.Container>
      <S.Container $lg>
        <S.TeamImage loading="lazy" src={Team} alt="Team" />
        <S.OurStoryContainer>
          <S.Title $textRight>{data["our-story"]?.title}</S.Title>
          <S.Text>
            {data["our-story"] &&
              parse(
                serialize({ children: JSON.parse(data["our-story"].newbody) })
              )}
          </S.Text>
        </S.OurStoryContainer>
      </S.Container>
      <S.LongImageContainer>
        <S.LongTeamImage loading="lazy" src={LongTeam} alt="Team" />
      </S.LongImageContainer>
      <S.Container $lg>
        <S.OurValuesContainer>
          <S.Title>{data["our-values"]?.title}</S.Title>
          <S.Text>
            {data["our-values"] &&
              parse(
                serialize({ children: JSON.parse(data["our-values"].newbody) })
              )}
          </S.Text>
        </S.OurValuesContainer>
        <S.GangImageContainer>
          <S.GangImage loading="lazy" src={Gang} alt="Team" />
        </S.GangImageContainer>
      </S.Container>
      <S.Container $lg>
        <S.LeadershipContainer>
          <S.Title $textRight>{data["leadership"]?.title}</S.Title>
          <S.Text>
            {data["leadership"] &&
              parse(
                serialize({ children: JSON.parse(data["leadership"].newbody) })
              )}
          </S.Text>
        </S.LeadershipContainer>
      </S.Container>
    </S.ContentSectionContainer>
  );
}
