import {useState, createContext, useCallback} from "react";
import GoTrue from "gotrue-js";

let auth = new GoTrue({
  APIUrl: "https://modoyo.com/.netlify/identity",
  audience: "",
  setCookie: true,
});

export const AuthContext = createContext();

export const AuthContextProvider = ({children}) => {
  let authUser = auth.currentUser();
  const [user, setUser] = useState(authUser);

  const login = useCallback((username, pass, cb) => {
    auth
      .login(username, pass, true)
      .then((response) => {
        setUser(response);
        cb(false);
      })
      .catch((error) => {
        cb(true);
      });
  }, []);

  const logout = useCallback(() => {
    user
      .logout()
      .then((response) => {
        setUser(null);
      })
      .catch((error) => {
        throw error;
      });
  }, [user]);

  const contextValues = {user, login, logout};

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
};
