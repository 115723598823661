import styled from 'styled-components';
import {Dialog} from "@headlessui/react";


export const FixedOverlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 10;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
`;

export const FlexContainer = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: end;
  justify-content: center;
  padding: 1rem;
  text-align: center;
  @media (min-width: 640px) {
    align-items: center;
    padding: 0;
  }
`;

export const Modal = styled(Dialog)`
  position: relative;
  z-index: 10;
`;

export const ModalPanel = styled(Dialog.Panel)`
  position: relative;
  overflow: hidden;
  border-radius: 0.375rem;
  background-color: #ffffff;
  padding: 2rem;
  text-align: left;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05);
  transition: all 0.3s;
  margin-top: 2rem;
  width: 100%;
  max-width: 32rem;
`;


export const ModalTitle = styled(Dialog.Title)`
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: 2rem;
`;

export const Button = styled.button`
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #ffffff;
  background-color: #ef4444;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s;
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  &:hover {
    background-color: #dc2626;
  }
`;

