import * as S from "./style";
import HeartElectric from "../../../imgs/landing-page/heart-electric.webp";
import CTA from "../../CTA";
import MapVideo from "../../../imgs/heartelectric/Map-video.webm";

export default function HeroSection() {
  return (
      <S.HeroWrapper>
        <S.MobileTeamPlayVideo
          loading="lazy"
          src={MapVideo}
          alt="MapVideo"
          autoPlay
          playsInline
          muted
          loop
        />
        <S.HeroWrapperInner>
          <S.HeroesImage loading="lazy" src={HeartElectric} alt="Heroes" />
          <CTA href="/heartelectric" pink>CHECK OUT THE GAME</CTA>
        </S.HeroWrapperInner>
      </S.HeroWrapper>
  );
}
