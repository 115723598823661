import styled from "styled-components";

export const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  z-index: 1;
  max-width: 80rem;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 24px;
  }
`;

export const HeadTitle = styled.h1`
  font-size: 56px;
  font-weight: 700;
  line-height: 70px;
  font-family: Figtree, sans-serif;
  color: #fcfaff;
  width: 300px;

  @media (max-width: 900px) {
    font-size: 40px !important;
    line-height: 50px;
  }
`;

export const HeadParagraphContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 50%;

  @media (max-width: 900px) {
    width: 70%;
    text-align: center;
  }
`;

export const HeadParagraph = styled.span`
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  font-family: "Roboto Mono", sans-serif;
  color: ${(props) => (props.$green ? "#98D977" : "#fcfaff")};

  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
