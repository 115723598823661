import React, {
  useLayoutEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import { Theme } from "../../../utils/Theme";
import { useParams, useNavigate, Link } from "react-router-dom";
import LoadSpinner from "../../../components/LoadSpinner";
import { SectionEditor } from "../../../components/Admin/AboutUs/SectionEditor";
import * as GS from "../../../styles/global-style";

export default function Edit() {
  const { setColor } = useContext(Theme);
  const navigate = useNavigate();
  const { idx } = useParams();

  const [data, setData] = useState();

  const getData = useCallback(() => {
    fetch(`/.netlify/functions/get-aboutus-details`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      });
  }, []);

  useLayoutEffect(() => {
    setColor("red");
    getData();
  }, [setColor, idx, navigate, getData]);

  async function fetchRequestData(_data) {
    return await fetch("/.netlify/functions/edit-aboutus-details", {
      method: "POST",
      body: JSON.stringify(_data),
    }).then(() => {
      getData();
    });
  }

  function editAboutUs(title, content) {
    fetchRequestData({
      header1: title ? title : null,
      content1: content ? mysqlEscape(JSON.stringify(content)) : null,
    });
  }

  function editOurStory(title, content) {
    fetchRequestData({
      header2: title ? title : null,
      content2: content ? mysqlEscape(JSON.stringify(content)) : null,
    });
  }

  function editOurValues(title, content) {
    fetchRequestData({
      header3: title ? title : null,
      content3: content ? mysqlEscape(JSON.stringify(content)) : null,
    });
  }

  function editLeadership(title, content) {
    fetchRequestData({
      header4: title ? title : null,
      content4: content ? mysqlEscape(JSON.stringify(content)) : null,
    });
  }

  function mysqlEscape(stringToEscape) {
    if (stringToEscape === "") {
      return stringToEscape;
    }
    return stringToEscape.replace(/'/g, "'").replace(/"/g, '"');
  }

  return !data ? (
    <LoadSpinner />
  ) : (
    <GS.Container>
      <div style={{ marginBottom: "1rem", color: "black" }}>
        <Link
          to="/narnia/news"
          style={{
            color: "black",
            textDecoration: "none",
            alignItems: "center",
            display: "flex",
            marginBottom: "2rem",
            marginTop: "1rem",
          }}
        >
          <i
            className="fas fa-arrow-left"
            style={{ marginRight: ".5rem", fontSize: "18px" }}
          />
          Back
        </Link>
        <GS.Title>About Us Details</GS.Title>
      </div>
      <GS.SpaceY number="1.5rem">
        <SectionEditor
          title={data["about-us"].title}
          content={data["about-us"].description}
          editSection={editAboutUs}
        />
        <SectionEditor
          title={data["our-story"].title}
          content={data["our-story"].description}
          editSection={editOurStory}
        />
        <SectionEditor
          title={data["our-values"].title}
          content={data["our-values"].description}
          editSection={editOurValues}
        />
        <SectionEditor
          title={data["leadership"].title}
          content={data["leadership"].description}
          editSection={editLeadership}
        />
      </GS.SpaceY>
    </GS.Container>
  );
}
