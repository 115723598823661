import * as S from "./style";

export default function GridContainer({ firstTitle, secondTitle, children }) {
  const groupedChildren = [];
  for (let i = 0; i < children.length; i += 2) {
    groupedChildren.push(children.slice(i, i + 2));
  }

  return (
    <S.GridContainer>
      {firstTitle && secondTitle && (
        <S.GridHeader>
          <S.GridHeaderTitle>{firstTitle}</S.GridHeaderTitle>
          <S.GridHeaderTitle>{secondTitle}</S.GridHeaderTitle>
        </S.GridHeader>
      )}
      <S.GridBody>
        {groupedChildren.map((pair, index) => (
          <S.GridBodyRow key={index}>
            {pair.map((child, childIndex) => (
              <S.GridBodyItem key={childIndex}>
                <S.GridBodyText>{child}</S.GridBodyText>
              </S.GridBodyItem>
            ))}
          </S.GridBodyRow>
        ))}
      </S.GridBody>
    </S.GridContainer>
  );
}
