import styled from "styled-components";

export const HeroContainer = styled.div`
  height: 1000px;
  background-image: url(${(props) => props.$image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;

  @media (max-width: 900px) {
    height: 795px;
  }
`;

export const MobileTeamPlayVideo = styled.video`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 50%;
  transform: translateX(-50%);
  height: auto;
  width: 100%;
  overflow: hidden;
  z-index: 10;

  @media (max-width: 900px) {
    height: 463px;
    object-fit: cover;
  }
`;

export const HeroWrapper = styled.div`
  position: relative;
`;

export const HeroWrapperInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 900px) {
    width: 100%;
  }
  `;
export const HeroesImage = styled.img`
  width: ${(props) => props.big || "100%"};
`;

export const MobileHeroesImage = styled.img`
  display: none;

  @media (max-width: 530px) {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    margin-top: -20px;
  }

  @media (min-width: 531px) and (max-width: 900px) {
    display: block;
    width: 60%;
    margin-bottom: 20px;
  }
`;
