const CharactersData = [
  {
    name: "AVA",
    description:
      "Tech and fashion empress Ava does everything in style. Her unique gadgets always come on brand and with extra features. Although some find her intimidating, she is more than willing to share her power with a team she trusts.",
  },
  {
    name: "RICKSHAW",
    description:
      "Rickshaw supports the team using his inventions, the main one being his trusty Bot. Rickshaw claims his bot is the ultimate weapon, but it’s pretty clear he is hiding in the old clunker to avoid contact with people he finds annoying.",
  },
  {
    name: "KOJI",
    description:
      "Koji’s inner fire burns just as bright as the propellant from his rocket powered arm. With his flames and explosives, he brings a significant amount of damage and area denial to any fight.",
  },
  {
    name: "JAZZ",
    description:
      "Jazz loves nature and all creatures, spending most of her time exploring the wooded outskirts of Silicon Hope. Her unique camouflage skin allows her to get close to both wary animals and opponents in battle.",
  },
];

export default CharactersData
