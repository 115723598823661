import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../utils/AuthContext";
import Button from "../../components/Button";
import validator from "validator";
import * as GS from "../../styles/global-style";

export default function Login() {
  const { user, login } = useContext(AuthContext);
  const navigate = useNavigate();

  const [inputValid, setInputValid] = useState(false);
  const [error, setError] = useState(null);

  const username = useRef(null);
  const password = useRef(null);

  useEffect(() => {
    if (user) navigate("/narnia");
  }, [user, navigate]);

  const validate = useCallback(() => {
    setError(null);
    if (username.current && password.current) {
      setInputValid(
        validator.isEmail(username.current.value.trim()) &&
          password.current.value.trim().length > 0
      );
    }
  }, []);

  return (
    <GS.HeaderFooterSpace  $disabledHeaderSpace>
    <center style={{  paddingTop: 100, minHeight: 500 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: 600,
          maxWidth: "95%",
        }}
      >
        {!user && (
          <form
            onSubmit={(e) => {
              e.preventDefault();

              if (!user && inputValid) {
                login(
                  username.current.value.trim(),
                  password.current.value.trim(),
                  (err) => {
                    setError(err);
                    if (!err) navigate("/narnia");
                  }
                );
              }
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <input
                ref={username}
                className="input"
                placeholder="User"
                onChange={() => {
                  validate();
                }}
                style={{
                  flexGrow: 8,
                  minWidth: 200,
                }}
                autoFocus
              />
              <input
                ref={password}
                className="input"
                placeholder="Pass"
                onChange={() => {
                  validate();
                }}
                style={{
                  flexGrow: 8,
                  minWidth: 200,
                }}
                type="password"
              />
              <button type="submit" style={{ display: "none" }} />
              <Button
                text={"Log in"}
                large
                black
                disabled={!inputValid}
                callback={() => {
                  login(
                    username.current.value.trim(),
                    password.current.value.trim(),
                    (err) => {
                      setError(err);
                      if (!err) navigate("/narnia");
                    }
                  );
                }}
              />
            </div>
          </form>
        )}
        {error && (
          <div style={{ color: "red", fontWeight: "bold", fontSize: 17 }}>
            Invalid credentials
          </div>
        )}
      </div>
    </center>
    </GS.HeaderFooterSpace>
  );
}
