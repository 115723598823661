import React, {
  useLayoutEffect,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { Link } from "react-router-dom";
import { Theme } from "../../../utils/Theme";
import Button from "../../..//components/Button";
import LoadSpinner from "../../../components/LoadSpinner";
import { Container, Title } from "../../../styles/global-style";
import {
  Actions,
  Content,
  HeaderAction,
  Heading,
  Icon,
  Image,
  ImagePlaceholder,
  Media,
  MediaContainer,
  NewsItem,
  Paragraph,
} from "../../../components/Admin/News/style";
import { Node } from "slate";
import {
  ButtonContainer,
  FixedOverlay,
  FlexContainer,
  Modal,
  ModalPanel,
  ModalTitle,
} from "../../../styles/modal";
import { compareByDate } from "../../../utils/common";

export default function News() {
  const { setColor } = useContext(Theme);

  const [data, setData] = useState();
  const [postId, setPostId] = useState(null);
  const [newPostClicked, setNewPostClicked] = useState(false);
  const [isOpenDeleteArticle, setIsOpenDeleteArticle] = useState(false);

  const getData = useCallback(async () => {
    fetch("/.netlify/functions/getposts", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      });
  }, []);

  useLayoutEffect(() => {
    setColor("red");
    getData();
  }, [setColor, getData]);

  useEffect(() => {
    if (newPostClicked) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [newPostClicked, getData]);

  const addPost = () => {
    setNewPostClicked(false);
    fetch("/.netlify/functions/addpost", {
      method: "POST",
      body: JSON.stringify({}),
    }).then(() => {
      getData();
      setNewPostClicked(true);
    });
  };

  const deletePost = (postId) => {
    setIsOpenDeleteArticle(true);
    setPostId(postId);
  };

  const confirmDeletePost = (postId) => {
    fetch("/.netlify/functions/deletepost", {
      method: "DELETE",
      body: JSON.stringify({ id: postId }),
    }).then(() => {
      getData();
      setIsOpenDeleteArticle(false);
    });
  };

  const serialize = (nodes) => {
    return nodes?.map((n) => Node.string(n)).join("\n");
  };

  return !data ? (
    <LoadSpinner />
  ) : (
    <>
      <Container>
        <HeaderAction>
          <Title>News</Title>
          <Button text="Add post" black callback={addPost} />
        </HeaderAction>
        <div>
          {data &&
            data.sort(compareByDate).map((item, i) => {
              const content = JSON.parse(item.newbody);

              return (
                <NewsItem
                  key={`post-${item.id}`}
                  $published={item.published === 1}
                >
                  <MediaContainer>
                    <Media>
                      {item.thumb ? (
                        <Image src={item.thumb} />
                      ) : (
                        <ImagePlaceholder>No Image</ImagePlaceholder>
                      )}
                      <Content>
                        <Heading>
                          <Link to={`/narnia/news/edit/${item.id}`}>
                            {item.header || <span>No Title</span>}
                          </Link>
                        </Heading>
                        <Paragraph>
                          {serialize(content) ? (
                            serialize(content)
                          ) : (
                            <span>No Content</span>
                          )}
                        </Paragraph>
                      </Content>
                      <Actions>
                        {item.published === 1 ? (
                          <Icon className="fas fa-globe-africa" />
                        ) : (
                          <Icon
                            className="fas fa-trash"
                            $delete
                            onClick={() => deletePost(item.id)}
                          />
                        )}
                      </Actions>
                    </Media>
                  </MediaContainer>
                </NewsItem>
              );
            })}
        </div>
      </Container>

      <Modal
        open={isOpenDeleteArticle}
        onClose={() => setIsOpenDeleteArticle(false)}
      >
        <FixedOverlay>
          <FlexContainer>
            <ModalPanel>
              <ModalTitle>Delete article</ModalTitle>
              <div>Are you sure you want to delete this news article?</div>
              <ButtonContainer>
                <Button
                  text="Delete"
                  callback={() => confirmDeletePost(postId)}
                  black
                />
                <Button
                  text="Cancel"
                  callback={() => setIsOpenDeleteArticle(false)}
                />
              </ButtonContainer>
            </ModalPanel>
          </FlexContainer>
        </FixedOverlay>
      </Modal>
    </>
  );
}
