import * as React from "react";
const NextArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <mask
      id="a"
      width={40}
      height={40}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path
        fill="#FCFAFF"
        d="M35 20a15 15 0 1 1-30 0 15 15 0 0 1 30 0Z"
        opacity={0.2}
      />
      <path
        fill="#FCFAFF"
        d="M20 3.75A16.25 16.25 0 1 0 36.25 20 16.267 16.267 0 0 0 20 3.75Zm0 30A13.75 13.75 0 1 1 33.75 20 13.766 13.766 0 0 1 20 33.75Zm7.134-14.634a1.25 1.25 0 0 1 0 1.768l-5 5a1.251 1.251 0 0 1-1.768-1.768l2.867-2.866H13.75a1.25 1.25 0 0 1 0-2.5h9.483l-2.867-2.866a1.251 1.251 0 0 1 1.768-1.768l5 5Z"
      />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#FCFAFF"
        d="M40 0H14.494a17.449 17.449 0 0 0-1.427 6.933c0 9.668 7.838 17.506 17.507 17.506 3.47 0 6.705-1.01 9.426-2.752V0Z"
      />
    </g>
    <mask
      id="b"
      width={40}
      height={40}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path
        fill="#FCFAFF"
        d="M35 20a15 15 0 1 1-30 0 15 15 0 0 1 30 0Z"
        opacity={0.2}
      />
      <path
        fill="#FCFAFF"
        d="M20 3.75A16.25 16.25 0 1 0 36.25 20 16.267 16.267 0 0 0 20 3.75Zm0 30A13.75 13.75 0 1 1 33.75 20 13.766 13.766 0 0 1 20 33.75Zm7.134-14.634a1.25 1.25 0 0 1 0 1.768l-5 5a1.251 1.251 0 0 1-1.768-1.768l2.867-2.866H13.75a1.25 1.25 0 0 1 0-2.5h9.483l-2.867-2.866a1.251 1.251 0 0 1 1.768-1.768l5 5Z"
      />
    </mask>
    <g mask="url(#b)">
      <path
        fill="#FCFAFF"
        d="M40 21.687a17.425 17.425 0 0 1-9.426 2.752c-9.669 0-17.507-7.838-17.507-17.506 0-2.463.509-4.807 1.427-6.933H0v40h40V21.687Z"
      />
    </g>
  </svg>
);
export default NextArrow;
