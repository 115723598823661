import * as S from "./style";

export default function HeadSection() {
  return (
    <S.HeadContainer>
      <S.HeadTitle>Our Team</S.HeadTitle>
      <S.HeadParagraphContainer>
        <S.HeadParagraph>
          Meet the team behind the game!
          <S.HeadParagraph $green> Each of us brings unique experiences</S.HeadParagraph>
          <S.HeadParagraph>and a genuine passion for our craft,
            working together to create something special for our players.</S.HeadParagraph>
        </S.HeadParagraph>
      </S.HeadParagraphContainer>
    </S.HeadContainer>
  );
}
