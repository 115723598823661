const calculateReadingTime = (content) => {
  const wordCount = content.trim().split(/\s+/).length;
  const averageReadingSpeed = 200;
  const readingTimeMinutes = Math.ceil(wordCount / averageReadingSpeed);

  if (readingTimeMinutes < 1) {
    const readingTimeSeconds = Math.ceil(
      (wordCount / averageReadingSpeed) * 60
    );
    return `${readingTimeSeconds} sec read`;
  } else if (readingTimeMinutes < 60) {
    return `${readingTimeMinutes} min read`;
  } else {
    const readingTimeHours = Math.floor(readingTimeMinutes / 60);
    return `${readingTimeHours} hour read`;
  }
};

export default calculateReadingTime;
