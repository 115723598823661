import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-inline: 2rem;

  @media (max-width: 900px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  ${(props) =>
    props.$innerWidth &&
    css`
      padding-top: ${(props) => (props.$innerWidth < 900 ? "0" : "350px")};
    `}

  ${(props) =>
    props.$sm &&
    css`
      @media (min-width: 640px) {
        max-width: 640px;
      }
    `}

  ${(props) =>
    props.$md &&
    css`
      @media (min-width: 768px) {
        max-width: 768px;
      }
    `}

  ${(props) =>
    props.$lg &&
    css`
      @media (min-width: 1024px) {
        max-width: 1024px;
      }
    `}

  ${(props) =>
    props.$xl &&
    css`
      @media (min-width: 1280px) {
        max-width: 1280px;
      }
    `}

  ${(props) =>
    props.$2xl &&
    css`
      @media (min-width: 1536px) {
        max-width: 1536px;
      }
    `}
`;

export const ContentSectionContainer = styled.div`
  background: linear-gradient(180deg, #340b7c 0%, #1f1433 100%);
  width: 100%;
  padding-top: 114px;
  padding-bottom: 150px;
  position: relative;
  z-index: 1;
  overflow: hidden;

  @media (max-width: 900px) {
    padding-top: 120px;
    padding-bottom: 0px;
  }

  &::before {
    content: "";
    position: absolute;
    height: 962px;
    bottom: 0;
    left: 0;
    right: 0;
    background: radial-gradient(
      127.49% 100% at 50% 100%,
      #b2249b 0%,
      rgba(229, 69, 203, 0.5) 50%,
      rgba(224, 135, 210, 0) 100%
    );
    z-index: 0;
    pointer-events: none;
  }
`;

export const AboutUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  width: 620px;
  margin-bottom: -30px;

  @media (max-width: 900px) {
    width: 100%;
    height: auto;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
`;

export const TeamImage = styled.img`
  position: absolute;
  left: -43%;
  bottom: 0;
  width: 771px;
  height: 508px;
  margin: 0 auto;

  @media (max-width: 900px) {
    width: 100%;
    height: 300px;
    left: 0;
    top: 0;
    object-fit: contain;
  }
`;

export const LongTeamImage = styled.img`
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  height: 553px;
  object-fit: cover;

  @media (max-width: 900px) {
    position: relative;
    width: 100%;
    height: 240px;
    object-fit: cover;
  }
`;

export const GangImage = styled.img`
  position: absolute;
  right: -43%;
  top: 0;
  width: 771px;
  height: 433px;
  margin: 0 auto;

  @media (max-width: 900px) {
    width: 100%;
    height: 300px;
    right: 0;
    bottom: 0;
    object-fit: contain;
  }
`;

export const GangImageContainer = styled.div`
  @media (max-width: 900px) {
    position: absolute;
    width: 100%;
    height: 300px;
    right: 0;
    bottom: 0;
    object-fit: contain;
  }
`;

export const Title = styled.h1`
  font-size: 56px;
  line-height: 70px;
  font-weight: 700;
  font-family: Figtree, sans-serif;
  color: #fcfaff;
  margin: 0;
  text-align: ${({ $textRight }) => ($textRight ? "right" : "left")};
  min-height: 70px;

  @media (max-width: 900px) {
    font-size: 40px !important;
    line-height: 50px;
    text-align: left;
  }
`;

export const Text = styled.span`
  font-size: 20px;
  line-height: 35px;
  font-weight: 400;
  font-family: "Roboto Flex", sans-serif;
  color: #fcfaff;

  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 31.5px;
    text-align: left;
  }
`;

export const OurStoryContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  gap: 24px;
  width: 620px;
  height: 450px;

  @media (max-width: 900px) {
    width: 100%;
    height: auto;
    margin-top: 300px;
  }
`;

export const LongImageContainer = styled.div`
  position: relative;
  height: 653px;

  @media (max-width: 900px) {
    height: 320px;
  }
`;

export const OurValuesContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 24px;
  width: 620px;
  height: 420px;
  margin-top: -50px;

  @media (max-width: 900px) {
    width: 100%;
    height: auto;
    margin-top: 0;
    margin-bottom: 300px;
  }
`;

export const LeadershipContainer = styled.div`
  margin-left: auto;
  display: grid;
  gap: 24px;
  width: 620px;

  @media (max-width: 900px) {
    width: 100%;
    padding-left: 0px;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 50px;
  }
`;

export const FirstStyledSvg = styled.svg`
  position: absolute;
  top: 90px;
  right: 0;
  z-index: 0;
`;

export const SecondStyledSvg = styled.svg`
  position: absolute;
  bottom: 72px;
  left: 72px;
  z-index: 0;
`;
